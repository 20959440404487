import React, {createContext, useState} from "react";
import moment from "moment";
import {generateLocalStorageKey, getRoundValue, reverseQuantityByUnitType} from "../helpers/Utils";
import POSInventoryServices from "../services/POSInventoryServices";
import Notification from "../components/common/Notification";
import POSVendorServices from "../services/POSVendorServices";

export const POSInventoryContext = createContext("POSInventoryContext");

const POSInventoryContextProvider = ({children}) => {

    const initInventoryData = {
        actionType: "INVENTORY_PURCHASED",
        inventoryDetailsList: [],
        paidAmount: 0,
        vendor: null,
        totalProductPrice: 0,
        discountType: null,
        discountPercent: 0,
        tradeDiscount: 0,
        voucherNumber: ""
    };

    const localStoredInventory = JSON.parse(localStorage.getItem(generateLocalStorageKey("inventory")));
    const _purchaseParkList = JSON.parse(localStorage.getItem(generateLocalStorageKey("purchase_parks")));

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [totalElements, setTotalElements] = useState(0);
    const [inventoryList, setInventoryList] = useState([]);
    const [inventoryReport, setInventoryReport] = useState({
        totalPurchasePrice: 0,
        totalPaidAmount: 0,
        totalDueAmount: 0
    });
    const [editedInventory, setEditedInventory] = useState({
        inventoryDetailsList: []
    });

    const [purchaseParkList, setPurchaseParkList] = useState(_purchaseParkList ?? []);

    const [inventory, setInventory] = useState(
        localStoredInventory
            ? localStoredInventory
            : initInventoryData
    );

    const refreshInventory = () => {
        const _data = localStorage.getItem(generateLocalStorageKey("inventory"));
        setInventory(_data ? JSON.parse(_data) : initInventoryData);
    }

    const getInventories = async (params) => {
        try {
            setLoading(true);
            setErrorMsg("");

            const res = await POSInventoryServices.getInventories(params);

            setInventoryReport({
                totalPurchasePrice: res.headers.total,
                totalPaidAmount: res.headers.paid,
                totalDueAmount: res.headers.due
            });
            setInventoryList(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const getInventoryById = async (id, shopId) => {

        try {
            setLoading(true);

            const res = await POSInventoryServices.getInventoryById(id);
            const _data = res.data;

            _data.actionType = _data.actionType.name;

            setLoading(false);

            if (_data.vendor) {
                const vendor = await POSVendorServices.getVendorByShopIdByLocalId(shopId, _data.vendor.localId);
                _data.vendor = vendor.data;
            }

            _data.inventoryDetailsList.map(details => {
                details.showQuantity = reverseQuantityByUnitType(details.unitType, details.quantity);
                if (details.freeProductQuantity) {
                    details.freeProductQuantity = reverseQuantityByUnitType(details.unitType, details.freeProductQuantity);
                }
                return details;
            });

            setEditedInventory(_data);

            return true;
        } catch (error) {

            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    //Inventory
    const createInventory = async (shopId, data) => {
        try {
            setLoading(true);
            await POSInventoryServices.createInventory(shopId, data);
            localStorage.removeItem(generateLocalStorageKey("inventory"));
            setInventory(initInventoryData);

            Notification("success", "Purchased", "Purchase create successfully");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const addInventory = data => {

        const index = inventory.inventoryDetailsList.findIndex(v => v.product.id === data.product.id);

        if (index < 0) {
            inventory.inventoryDetailsList.push(data);

            let sum = 0;

            inventory.inventoryDetailsList.forEach(value => {

                if (value.quantity > 0) {
                    sum += value.totalProductPrice;
                }

            });

            // inventory.totalProductPrice = getRoundValue(sum);
            inventory.totalProductPrice = sum;

            const _inventory = {
                ...inventory,
                inventoryDetailsList: [...inventory.inventoryDetailsList],
            }
            localStorage.setItem(generateLocalStorageKey("inventory"), JSON.stringify(_inventory));
            setInventory(_inventory);
        } else {
            inventory.inventoryDetailsList[index] = data;

            let sum = 0;
            inventory.inventoryDetailsList.forEach(value => sum += value.totalProductPrice);
            const _inventory = {
                ...inventory,
                inventoryDetailsList: [...inventory.inventoryDetailsList],
                // totalProductPrice: getRoundValue(sum)
                totalProductPrice: sum
            };

            localStorage.setItem(generateLocalStorageKey("inventory"), JSON.stringify(_inventory));
            setInventory(_inventory);
        }
    }

    const updateInventory = (data, index) => {

        inventory.inventoryDetailsList[index] = data;

        let sum = 0;
        inventory.inventoryDetailsList.forEach(value => sum += value.totalProductPrice);
        const _inventory = {
            ...inventory,
            inventoryDetailsList: [...inventory.inventoryDetailsList],
            // totalProductPrice: getRoundValue(sum)
            totalProductPrice: sum
        };

        localStorage.setItem(generateLocalStorageKey("inventory"), JSON.stringify(_inventory));
        setInventory(_inventory);
    }

    const updateInventoryByKey = (key, value, extra) => {

        let _inventory = {...inventory, [key]: value, ...extra}

        if (key === "discountOperation") {
            inventory.tradeDiscount = 0;
            inventory.discountPercent = 0;
            _inventory = {...inventory}
        }

        if (_inventory.tradeDiscount > 0) {
            _inventory.discountType = "FLAT"
        }

        if (_inventory.discountPercent > 0) {
            _inventory.discountType = "PERCENTAGE"
        }

        if (key === "paidAmount") {
            _inventory.paidAmount = getRoundValue(value)
            // _inventory.paidAmount = value
        }

        localStorage.setItem(generateLocalStorageKey("inventory"), JSON.stringify(_inventory));
        setInventory({..._inventory});
    }

    const deletePurchase = index => {
        inventory.inventoryDetailsList.splice(index, 1);
        let sum = 0;
        inventory.inventoryDetailsList.forEach(value => sum += value.totalProductPrice);
        // inventory.totalProductPrice = getRoundValue(sum);
        inventory.totalProductPrice = sum;

        const _inventory = {...inventory, inventoryDetailsList: [...inventory.inventoryDetailsList]}

        if (_inventory.inventoryDetailsList.length > 0) {
            localStorage.setItem(generateLocalStorageKey("inventory"), JSON.stringify(_inventory));
            setInventory(_inventory);
        } else {
            localStorage.removeItem(generateLocalStorageKey("inventory"));
            setInventory({...initInventoryData})
        }
    }

    // Inventory History
    const editInventoryHistory = async (id, shopId, data, params) => {

        try {

            setLoading(true);
            await POSInventoryServices.editInventory(id, shopId, data, params);
            Notification("success", "Inventory Updated", "Inventory updated successfully");
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            Notification("error", "ERROR", `${_error}`)
            return false;
        }

    }

    const addPurchaseRecord = (data) => {

        const index = editedInventory.inventoryDetailsList.findIndex(v => v.product.id === data.product.id);

        if (index < 0) {
            editedInventory.inventoryDetailsList.push(data);
        } else {
            editedInventory.inventoryDetailsList[index] = data;
        }

        let sum = 0;
        editedInventory.inventoryDetailsList.forEach(value => sum += value.totalProductPrice);
        // editedInventory.totalProductPrice = getRoundValue(sum);
        editedInventory.totalProductPrice = sum;

        const _editedInventory = {...editedInventory, inventoryDetailsList: [...editedInventory.inventoryDetailsList]};

        setEditedInventory(_editedInventory);
    }

    const deletePurchaseRecord = (index) => {

        editedInventory.inventoryDetailsList.splice(index, 1);

        let sum = 0;
        editedInventory.inventoryDetailsList.forEach(value => sum += value.totalProductPrice);
        // editedInventory.totalProductPrice = getRoundValue(sum);
        editedInventory.totalProductPrice = sum;

        const _editedInventory = {...editedInventory, inventoryDetailsList: [...editedInventory.inventoryDetailsList]};

        setEditedInventory(_editedInventory);
    }

    const updateInventoryHistoryByKey = (key, value, extra) => {

        let _editedInventory = {...editedInventory, [key]: value, ...extra}
        if (key === "discountOperation") {
            editedInventory.tradeDiscount = 0;
            editedInventory.discountPercent = 0;
            _editedInventory = {...editedInventory}
        }

        if (_editedInventory.tradeDiscount > 0) {
            _editedInventory.discountType = "FLAT"
        }

        if (_editedInventory.discountPercent > 0) {
            _editedInventory.discountType = "PERCENTAGE"
        }

        if (key === "paidAmount") {
            _editedInventory.paidAmount = getRoundValue(value)
            // _editedInventory.paidAmount = value
        }

        setEditedInventory({..._editedInventory});
    }

    const removeVendorByKey = (key, value) => {
        if (key === "vendor") {
            inventory[key] = null;
        } else {
            inventory[key] = value;
        }
        localStorage.setItem(generateLocalStorageKey("inventory"), JSON.stringify(inventory));
        setInventory({...inventory});
    }

    const removeEditedInventoryVendor = (key) => {

        editedInventory[key] = null;
        setEditedInventory({...editedInventory});
    }

    const addRequisitionToInventory = (data) => {

        let totalCost = 0;

        const detailsList = data.requisitionDetailsList.map(value => {

            value.productPrice = value.product.productPrice;
            value.showQuantity = reverseQuantityByUnitType(value.unitType, value.quantity);

            value.totalProductPrice = value.quantity * value.productPrice;

            value.freeProductQuantity = 0;

            value.noteList = [];

            totalCost += value.totalProductPrice;

            return value;
        });

        const _data = {
            actionType: "INVENTORY_PURCHASED",
            inventoryDetailsList: detailsList,
            paidAmount: 0,
            vendor: data.vendor ? data.vendor : null,
            // totalProductPrice: getRoundValue(totalCost),
            totalProductPrice: totalCost,
            tradeDiscount: 0,
            voucherNumber: "",
            requisitionNumber: data.requisitionNumber
        }

        setInventory(_data);

        localStorage.setItem(generateLocalStorageKey("inventory"), JSON.stringify(_data));
    }

    const parkPurchase = () => {

        if (inventory.inventoryDetailsList.length < 1) return;

        const _inventory = inventory;
        const parkTime = moment();
        const _data = [...purchaseParkList, {..._inventory, parkTime}];

        setPurchaseParkList(_data);
        localStorage.setItem(generateLocalStorageKey("purchase_parks"), JSON.stringify(_data));
        setInventory(initInventoryData);
        localStorage.removeItem(generateLocalStorageKey("inventory"));
    }

    const parkToPurchase = index => {

        let parkAbleData = null;
        if (inventory.inventoryDetailsList.length > 0) {
            parkAbleData = inventory;
        }

        const _data = purchaseParkList[index];
        const _list = purchaseParkList.filter((value, index1) => index1 !== index);
        const parkTime = moment();

        setPurchaseParkList(parkAbleData ? [..._list, {...parkAbleData, parkTime}] : _list);
        localStorage.setItem(generateLocalStorageKey("purchase_parks"), JSON.stringify(_list));
        setInventory(_data);
        localStorage.setItem(generateLocalStorageKey("inventory"), JSON.stringify(_data));
    }

    const removePark = removeIndex => {
        const parkList = purchaseParkList.filter((v, index) => index !== removeIndex);
        localStorage.setItem(generateLocalStorageKey("purchase_parks"), JSON.stringify(parkList));
        setPurchaseParkList(parkList);
    }

    return (
        <POSInventoryContext.Provider
            value={{
                loading,
                errorMsg,
                inventory,
                totalElements,
                inventoryList,
                editedInventory,
                inventoryReport,
                purchaseParkList,
                getInventories,
                createInventory,
                addInventory,
                addPurchaseRecord,
                updateInventory,
                deletePurchase,
                deletePurchaseRecord,
                updateInventoryByKey,
                getInventoryById,
                updateInventoryHistoryByKey,
                editInventoryHistory,
                removeVendorByKey,
                removeEditedInventoryVendor,
                addRequisitionToInventory,
                refreshInventory,
                parkPurchase,
                parkToPurchase,
                removePark
            }}
        >
            {children}
        </POSInventoryContext.Provider>
    )
}

export default POSInventoryContextProvider;
