import React, {createContext, useState} from "react";
import Notification from "../components/common/Notification";
import POSExpiredItemsServices from "../services/POSExpiredItemsServices";

export const POSExpiredItemsContext = createContext("POSExpiredItemsContextProvider");

const POSExpiredItemsContextProvider = ({children}) => {
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [totalElements, setTotalElements] = useState(0);
    const [expiredItems, setExpiredItems] = useState([]);

    const getExpiredItemsByShopId = async (params) => {
        try {
            setLoading(true);
            setErrorMsg("");
            const res = await POSExpiredItemsServices.getExpiredItemsByShopId(
                params
            );
            setExpiredItems(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    return (
        <POSExpiredItemsContext.Provider
            value={{
                loading,
                errorMsg,
                totalElements,
                expiredItems,
                getExpiredItemsByShopId,
            }}
        >
            {children}
        </POSExpiredItemsContext.Provider>
    );
};

export default POSExpiredItemsContextProvider;
