import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import {useReactToPrint} from "react-to-print";
import SaleInvoice80MM from "./SaleInvoice80MM";
import {EIGHTY_MM_PAPER, FIFTY_EIGHT_MM_PAPER} from "../../../../helpers/Constants";
import SaleInvoice58MM from "./SaleInvoice58MM";
import SaleInvoiceA4 from "./SaleInvoiceA4";

const SaleInvoicePrint = forwardRef(({data, pdfConfig}, ref) => {

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useImperativeHandle(ref, () => ({

        refHandlePrint() {
            handlePrint();
        }

    }));

    return (
        <>
            <div style={{display: "none"}}>
                {/*<div style={{display: "none", width: "300px"}}>*/}
                {
                    pdfConfig?.paperType === FIFTY_EIGHT_MM_PAPER ? <SaleInvoice58MM
                        componentRef={componentRef}
                        data={data}
                        pdfConfig={pdfConfig}
                    /> : pdfConfig?.paperType === EIGHTY_MM_PAPER ? <SaleInvoice80MM
                        componentRef={componentRef}
                        data={data}
                        pdfConfig={pdfConfig}
                    /> : <SaleInvoiceA4
                        componentRef={componentRef}
                        data={data}
                        pdfConfig={pdfConfig}
                    />
                }
            </div>
        </>
    );
});

export default SaleInvoicePrint;