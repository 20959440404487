import React, {createContext, useState} from 'react';
import ReportsServices from "../services/ReportsServices";
import Notification from "../components/common/Notification";

export const ReportContext = createContext("ReportContext");
const ReportContextProvider = ({children}) => {
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [totalElements, setTotalElements] = useState(0);

    // Stock
    const [stockDetails, setStockDetails] = useState([]);
    const [totalStockQTY, setTotalStockkQTY] = useState(0);
    const [totalStockAmount, setTotalStockAmount] = useState(0);

    // Journals
    const [reportsJournals, setReportsJournals] = useState([]);
    const [totalDebit, setTotalDebit] = useState(0);
    const [totalCredit, setTotalCredit] = useState(0);

    // Customers Payment Status
    const [customerPaymentStatus, setCustomerPaymentStatus] = useState([]);

    const [totalCustomerDue, setTotalCustomerDue] = useState(0);
    const [totalCustomerAdvance, setTotalCustomerAdvance] = useState(0);

    const [totalVendorDue, setTotalVendorDue] = useState(0);
    const [totalVendorAdvance, setTotalVendorAdvance] = useState(0);

    // Vendors Payment Status
    const [vendorsPaymentStatus, setVendorsPaymentStatus] = useState([]);

    // Sale History
    const [saleHistories, setsSaleHistories] = useState([]);

    // Purchase History
    const [purchaseHistories, setPurchaseHistories] = useState([]);

    const [paid, setPaid] = useState(0);


    const getSaleHistoryShopId = async (id, params) => {

        try {
            setLoading(true);
            setErrorMsg("");

            const res = await ReportsServices.getAllSaleHistoryByShopId(id, params);
            setsSaleHistories(res.data);
            setTotalElements(res.data.totalElements);

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getPurchaseHistoryShopId = async (id, params) => {

        try {
            setLoading(true);
            setErrorMsg("");

            const res = await ReportsServices.getAllPurchaseHistoryByShopId(id, params);
            setPurchaseHistories(res.data);
            setTotalElements(res.data.totalElements);

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getVendorsPaymentStatusShopId = async (id, params) => {

        try {
            setLoading(true);
            setErrorMsg("");

            const res = await ReportsServices.getAllVendorsPaymentStatusByShopId(id, params);
            setVendorsPaymentStatus(res.data.content);
            setTotalElements(res.data.totalElements);

            setTotalVendorAdvance(res.headers.adv);
            setTotalVendorDue(res.headers.due);

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getCustomersPaymentStatusShopId = async (id, params) => {
        try {
            setLoading(true);
            setErrorMsg("");

            const res = await ReportsServices.getAllCustomersPaymentStatusByShopId(id, params);
            setCustomerPaymentStatus(res.data.content);
            setTotalElements(res.data.totalElements);

            setTotalCustomerDue(res.headers.due);
            setTotalCustomerAdvance(res.headers.adv)

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }



    const getStockDetailsListByShopId = async (id, params) => {

        try {
            let totalQTY = 0;
            let totalAmount = 0;

            setLoading(true);
            setErrorMsg("");

            const res = await ReportsServices.getAllReportsByShopId(id, params);
            setStockDetails(res.data.content);
            setPaid(res.headers.paid);
            setTotalElements(res.data.totalElements);

            res.data.content.forEach(item => {
                totalQTY += item.quantity;
                totalAmount += (item.productPrice * item.quantity);
            });

            setTotalStockkQTY(totalQTY);
            setTotalStockAmount(totalAmount);

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getReportJournalsListByShopId = async (id, params) => {

        let totalDebit = 0;
        let totalCredit = 0;

        try {
            setLoading(true);
            setErrorMsg("");

            const res = await ReportsServices.getAllJournalByShopId(id, params);

            setReportsJournals(res.data.content);
            setTotalElements(res.data.totalElements);

            res.data.content.forEach(journal => {
                journal.journalEntries.forEach(journalEntry => {
                    if (journalEntry.transactionType === "DEBIT") {
                        totalDebit += journalEntry.amount
                    } else {
                        totalCredit += journalEntry.amount
                    }
                })
            })

            setTotalDebit(totalDebit);
            setTotalCredit(totalCredit);

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    return (
        <ReportContext.Provider
            value={{
                loading,
                errorMsg,
                totalElements,
                stockDetails,
                customerPaymentStatus,
                vendorsPaymentStatus,
                saleHistories,
                purchaseHistories,
                getSaleHistoryShopId,
                getPurchaseHistoryShopId,
                getVendorsPaymentStatusShopId,
                getStockDetailsListByShopId,
                getReportJournalsListByShopId,
                getCustomersPaymentStatusShopId,
                reportsJournals,
                totalDebit,
                totalCredit,
                totalStockQTY,
                totalStockAmount,
                paid,
                totalCustomerDue,
                totalCustomerAdvance,
                totalVendorDue,
                totalVendorAdvance

            }}
        >
            {children}
        </ReportContext.Provider>
    )
}

export default ReportContextProvider;