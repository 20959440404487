import React, {createContext, useState} from 'react';
import BannerService from "../../services/auth/BannerService";
import {getErrorMessage} from "../../helpers/Utils";
import {Toast} from "../../components/common/Toast";

export const BannerContext = createContext("BannerContext");

const BannerContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [banners, setBanners] = useState([]);
    const [totalElements, setTotalElements] = useState(0);

    const [banner, setBanner] = useState(null);
    const [bannerLoading, setBannerLoading] = useState(false);

    const [bannerCreateLoading, setBannerCreateLoading] = useState(false);
    const [bannerUpdateLoading, setBannerUpdateLoading] = useState(false);

    const getAllBanners = async params => {

        try {
            setLoading(true);
            const res = await BannerService.getAllBanner(params);
            setBanners(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "ERROR", message);
            return false;
        }

    }

    const getBannerById = async id => {

        try {
            setBannerLoading(true);
            const res = await BannerService.getBannerById(id);
            setBanner(res.data);
            setBannerLoading(false);
            return true;
        } catch (error) {
            setBannerLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "ERROR", message);
            return false;
        }

    }

    const addBanner = async data => {
        try {
            setBannerCreateLoading(true);
            await BannerService.addBanner(data)
            Toast("success", "CREATED", "Banner has been created successfully");
            setBannerCreateLoading(false)
            return true;
        } catch (error) {
            setBannerCreateLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "ERROR", message);
            return false;
        }

    }

    const updateBanner = async (data, id) => {
        try {
            setBannerUpdateLoading(true);
            await BannerService.updateBanner(data, id)
            setBannerUpdateLoading(false);
            Toast("success", "UPDATED", "Banner has been updated successfully");
            return true;
        } catch (error) {
            setBannerUpdateLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "ERROR", message);
            return false;
        }

    }

    const deleteBanner = async (id) => {
        try {
            await BannerService.deleteBanner(id);
            return true;
        } catch (error) {
            const message = getErrorMessage(error);
            Toast("error", "ERROR", message);
            return false;
        }
    }

    return (
        <BannerContext.Provider
            value={{
                loading,
                banners,
                totalElements,
                banner,
                bannerLoading,
                bannerCreateLoading,
                bannerUpdateLoading,
                getAllBanners,
                getBannerById,
                addBanner,
                updateBanner,
                deleteBanner,
            }}
        >
            {children}
        </BannerContext.Provider>
    );
}

export default BannerContextProvider;
