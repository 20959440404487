import React, {createContext, useState} from "react";
import Notification from "../components/common/Notification";
import POSTransactionAccountServices from "../services/POSTransactionAccountService";

export const POSTransactionAccountContext = createContext("POSTransactionAccountContext");

const POSTransactionAccountContextProvider = ({children}) => {
    const [loading, setLoading] = useState(false);
    const [
        loadingCreateTransactionAccount,
        setLoadingCreateTransactionAccount,
    ] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [totalElements, setTotalElements] = useState(0);
    const [transactionAccounts, setTransactionAccounts] = useState([]);
    const [transactionAccount, setTransactionAccount] = useState(null);

    const getTransactionAccounts = async (params) => {
        try {
            setLoading(true);
            setErrorMsg("");

            const res = await POSTransactionAccountServices.getTransactionAccounts(
                params
            );

            setTransactionAccounts(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const getTransactionAccountById = async (id) => {
        try {
            setLoading(true);
            setErrorMsg("");

            const res = await POSTransactionAccountServices.getTransactionAccountById(
                id
            );

            setTransactionAccount(res.data);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const createTransactionAccount = async (params) => {
        try {
            setLoadingCreateTransactionAccount(true);
            setErrorMsg("");

            await POSTransactionAccountServices.createTransactionAccount(params);

            Notification("success", "Added", "Transaction account added successfully!");

            setLoadingCreateTransactionAccount(false);
            return true;
        } catch (error) {
            setLoadingCreateTransactionAccount(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const updateTransactionAccount = async (id, data) => {
        try {
            setLoading(true);
            setErrorMsg("");

            await POSTransactionAccountServices.updateTransactionAccount(id, data);

            Notification("success", "Updated", "Transaction account updated successfully!");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    return (
        <POSTransactionAccountContext.Provider
            value={{
                loading,
                errorMsg,
                totalElements,
                transactionAccounts,
                transactionAccount,
                loadingCreateTransactionAccount,
                getTransactionAccounts,
                getTransactionAccountById,
                createTransactionAccount,
                updateTransactionAccount,
            }}
        >
            {children}
        </POSTransactionAccountContext.Provider>
    );
};

export default POSTransactionAccountContextProvider;
