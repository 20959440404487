import React, {createContext, useState} from 'react';
import PermissionService from "../services/PermissionService";

export const PermissionContext = createContext("PermissionContext");

const PermissionContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [permissions, setPermissions] = useState(null);

    const getAllPermissions = async () => {
        try {
            setLoading(true);
            const res = await PermissionService.getAllPermissions();
            setPermissions(res.data);
            setLoading(false);
            return res.data;
        } catch (e) {
            setLoading(false);
            setErrorMsg("Can't Load Data. Please Check The Console.")
            return false;
        }
    }

    const getPermissionByUserType = async (userType) => {

        try {
            setLoading(true);
            const res = await PermissionService.getPermissionsByUserType(userType);
            setPermissions(res);
            setLoading(false);
            return res;
        } catch (e) {
            setLoading(false);
            setErrorMsg("Can't Load Data. Please Check The Console.")
            return false;
        }
    }

    return (
        <PermissionContext.Provider
            value={{
                loading,
                errorMsg,
                permissions,
                getAllPermissions,
                getPermissionByUserType
            }}
        >
            {children}
        </PermissionContext.Provider>
    );
};

export default PermissionContextProvider;
