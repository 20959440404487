import * as URL from "../../helpers/Constants";
import axios from "axios";

export default class POSOfflineConfigService {
    static getAllPOSOfflineConfigs = params => {
        return axios.get(URL.GET_POS_OFFLINE_CONFIG_LIST, {
            ...POSOfflineConfigService.getAuthHeader(),
            params
        });
    }

    static getPOSOfflineConfigById = id => {
        return axios.get(`${URL.GET_POS_OFFLINE_CONFIG_BY_ID}/${id}`, POSOfflineConfigService.getAuthHeader());
    }

    static generatePOSOConfigToken = shopId => {
        return axios.get(`${URL.GENERATE_POS_OFFLINE_CONFIG_TOKEN_BY_SHOP_ID}/${shopId}`, POSOfflineConfigService.getAuthHeader());
    }

    static createPOSOfflineConfig = data => {
        return axios.post(URL.CREATE_POS_OFFLINE_CONFIG, data, POSOfflineConfigService.getAuthHeader());
    }

    static updatePOSOfflineConfig = (data, id) => {
        return axios.put(`${URL.UPDATE_POS_OFFLINE_CONFIG}/${id}`, data, POSOfflineConfigService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem("ems_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }
}
