import React, {createContext, useState} from "react";
import Notification from "../components/common/Notification";
import POSTransactionServices from "../services/POSTransactionServices";

export const POSTransactionContext = createContext("POSTransactionContext");

const POSTransactionContextProvider = ({children}) => {
    const [loading, setLoading] = useState(false);
    const [loadingCancelTransaction, setLoadingCancelTransaction] = useState(
        false
    );
    const [loadingCreateTransaction, setLoadingCreateTransaction] = useState(
        false
    );
    const [errorMsg, setErrorMsg] = useState("");

    const [totalElements, setTotalElements] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const [transaction, setTransaction] = useState(null);

    const getTransactions = async (accId, params) => {
        try {
            setLoading(true);
            setErrorMsg("");

            const res = await POSTransactionServices.getTransactions(accId, params);

            setTransactions(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const getTransactionById = async (id) => {
        try {
            setLoading(true);
            setErrorMsg("");

            const res = await POSTransactionServices.getTransactionById(id);

            setTransaction(res.data);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const createTransaction = async (data) => {
        try {
            setLoadingCreateTransaction(true);
            setErrorMsg("");

            await POSTransactionServices.createTransaction(data);

            Notification("success", "Success", "Transaction successfully!");

            setLoadingCreateTransaction(false);
            return true;
        } catch (error) {
            setLoadingCreateTransaction(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    // const cancelTransaction = async (accId, search, id) => {
    const cancelTransaction = async id => {
        try {
            setLoadingCancelTransaction(true);
            setErrorMsg("");

            await POSTransactionServices.cancelTransaction(id);

            Notification("success", "Canceled", "Transaction hase been canceled!");

            setLoadingCancelTransaction(false);
            return true;
        } catch (error) {
            setLoadingCancelTransaction(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    return (
        <POSTransactionContext.Provider
            value={{
                loading,
                errorMsg,
                totalElements,
                transactions,
                transaction,
                loadingCreateTransaction,
                getTransactions,
                getTransactionById,
                createTransaction,
                cancelTransaction,
                loadingCancelTransaction,
            }}
        >
            {children}
        </POSTransactionContext.Provider>
    );
};

export default POSTransactionContextProvider;
