import React, {createContext, useState} from "react";
import Notification from "../components/common/Notification";
import POSExpenseServices from "../services/POSExpenseServices";

export const POSExpenseContext = createContext("POSExpenseContext");

const POSExpenseContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [leadingCreateExpense, setLoadingCreateExpense] = useState(false)
    const [errorMsg, setErrorMsg] = useState("");

    const [totalElements, setTotalElements] = useState(0);
    const [expenses, setExpenses] = useState(null);
    const [expense, setExpense] = useState(null);
    const [expenseReport, setExpenseReport] = useState({
        totalCount: 0,
        totalExpense: 0
    });


    const getAllExpensesByShopId = async (params) => {

        try {
            setLoading(true);
            setErrorMsg("");

            const res = await POSExpenseServices.getAllExpensesByShopId(params);

            setExpenseReport({
                totalCount: res.headers.count,
                totalExpense: res.headers.total
            });

            setExpenses(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getExpenseByShopIdById = async (shopId, id) => {
        try {
            setLoading(true);
            setErrorMsg("");

            const res = await POSExpenseServices.getExpenseByShopIdById(shopId, id);
            setExpense(res.data);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const createExpenseByShopId = async (id, data) => {

        try {
            setLoading(true);
            setLoadingCreateExpense(true);
            setErrorMsg("");

            await POSExpenseServices.createExpenseByShopId(id, data);

            Notification("success", "Added", "Expense has been added successfully!");

            setLoading(false);
            setLoadingCreateExpense(false)
            return true;
        } catch (error) {
            setLoading(false);
            setLoadingCreateExpense(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const updateExpenseByIdByShopId = async (id, shopId, data) => {

        try {
            setLoading(true);
            setErrorMsg("");

            await POSExpenseServices.updateExpenseByIdByShopId(id, shopId, data);
            Notification("success", "Updated", "Expense updated successfully!");

            setLoading(false);
            return true;
        } catch (error) {

            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }


    return (
        <POSExpenseContext.Provider
            value={{
                loading,
                leadingCreateExpense,
                errorMsg,
                totalElements,
                expenses,
                expense,
                expenseReport,
                getAllExpensesByShopId,
                getExpenseByShopIdById,
                createExpenseByShopId,
                updateExpenseByIdByShopId
            }}
        >
            {children}
        </POSExpenseContext.Provider>
    )
}

export default POSExpenseContextProvider;