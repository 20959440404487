import React, {createContext, useState} from "react";
import Notification from "../components/common/Notification";
import POSVendorServices from "../services/POSVendorServices";

export const POSVendorContext = createContext("POSVendorContext");

const POSVendorContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [totalElements, setTotalElements] = useState(0);
    const [vendors, setVendors] = useState(null);
    const [vendor, setVendor] = useState(null);
    const [vendorDueReport, setVendorDueReport] = useState({
        totalDueVendor: 0,
        totalDue: 0
    });


    const getAllVendorsByShopId = async (id, params) => {

        try {
            setLoading(true);
            setErrorMsg("");

            const res = await POSVendorServices.getAllVendorsByShopId(id, params);

            setVendorDueReport({
                totalDueVendor: res.headers.count,
                totalDue: res.headers.due
            });

            setVendors(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getAllLiteVendorsByShopId = async ({page, size, id}) => {

        try {
            setLoading(true);
            setErrorMsg("");
            const res = await POSVendorServices.getAllLiteVendorsByShopId(page, size, id);
            setVendors(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getVendorByShopIdById = async (shopId, id) => {
        try {
            setLoading(true);
            setErrorMsg("");
            const res = await POSVendorServices.getVendorByShopIdById(shopId, id);
            // if (!res.data.phoneNumber.startsWith("+88")) {
            //     res.data.phoneNumber = `+88${res.data.phoneNumber}`
            // }
            setVendor(res.data);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getVendorByShopIdByLocalId = async (shopId, localId) => {
        try {
            setLoading(true);

            const res = await POSVendorServices.getVendorByShopIdByLocalId(shopId, localId);

            setVendor(res.data);
            setLoading(false);

            return true;
        } catch (error) {

            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const _getVendorByShopIdByLocalId = async (shopId, localId) => {
        try {
            const res = await POSVendorServices.getVendorByShopIdByLocalId(shopId, localId);
            return res.data;
        } catch (error) {

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            Notification("error", "ERROR", `${_error}`);
            return null;
        }
    }

    const createVendorByShopId = async (id, data) => {

        try {
            setLoading(true);
            setErrorMsg("");
            await POSVendorServices.createVendorByShopId(id, data);
            Notification("success", "Success", "Vendor successfully added!");
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const updateVendorByIdByShopId = async (id, shopId, data) => {

        try {
            setLoading(true);
            setErrorMsg("");
            await POSVendorServices.updateVendorByIdByShopId(id, shopId, data);
            Notification("success", "Updated", "Vendor successfully updated");
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const deleteVendorById = async (id) => {

        try {
            setLoading(true);
            setErrorMsg("");
            await POSVendorServices.deleteVendorById(id);
            setVendors(vendors.filter(vendor => vendor.id !== id));
            Notification("success", "Deleted", "Vendor has been deleted!");
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }


    return (
        <POSVendorContext.Provider
            value={{
                loading,
                errorMsg,
                totalElements,
                vendors,
                vendor,
                vendorDueReport,
                getAllVendorsByShopId,
                getAllLiteVendorsByShopId,
                getVendorByShopIdById,
                getVendorByShopIdByLocalId,
                createVendorByShopId,
                updateVendorByIdByShopId,
                deleteVendorById,
                _getVendorByShopIdByLocalId,
            }}
        >
            {children}
        </POSVendorContext.Provider>
    )
}

export default POSVendorContextProvider;