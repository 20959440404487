import React from 'react';
import './ErrorPayment.scss'
import {Button, Result} from "antd";
import {DASHBOARD_PATH} from "../../../../routes/Slugs";
import {Link} from "react-router-dom";
const ErrorPayment = () => {
    return (
        <div className="payment-error-page">
            <Result
                status="error"
                title="Payment Failed"
                subTitle="There was an error processing your payment."
                extra={[
                    <Link to={DASHBOARD_PATH}>
                        <Button type="primary" key="home">
                            Go Home
                        </Button>
                    </Link>

                ]}
            />
        </div>
    );
};

export default ErrorPayment;