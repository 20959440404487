import {BrowserRouter, Route, Switch} from "react-router-dom";
import {AuthContext} from "./context/auth/AuthContextProvider";
import {lazy, Suspense, useContext} from "react";
import LoadingSuspense from "./components/common/LoadingSuspense";
import PrivateRoute from "./components/common/PrivateRoute";
import {
    ADMIN_LOGIN_PATH,
    ANDROID_PRIVACY_PATH,
    CANCEL_PAYMENT_STATUS_PATH,
    ERROR_PAYMENT_STATUS_PATH,
    IOS_PRIVACY_PATH,
    LOGIN_PATH,
    PAGE_403_PATH,
    PAGE_404_PATH,
    PAGE_500_PATH,
    POS_SALE_HISTORY_PRINT_A4_PATH,
    POS_SALE_HISTORY_PRINT_PATH,
    POS_SHOP_VIEW_PATH,
    ROOT_PATH,
    SUCCESS_PAYMENT_STATUS_PATH,
    TERMS_AND_CONDITION_APP_PATH
} from "./routes/Slugs";
import PosSaleHistoryPrint from "./components/pages/sale_history/PosSaleHistoryPrint";
import {I18nProvider} from "./i18n";
import {GlobalContext} from "./context/GlobalContextProvider";
import SuccessPayment from "./components/pages/subscription/status_payment/SuccessPayment";
import ErrorPayment from "./components/pages/subscription/status_payment/ErrorPayment";
import CancelPayment from "./components/pages/subscription/status_payment/CancelPayment";
import PosSaleHistoryPrintA4 from "./components/pages/sale_history/PosSaleHistoryPrintA4";

const Shop = lazy(() => import("./components/pages/pos_shop/POSShop"));
const DefaultLayout = lazy(() => import("./components/layout/DefaultLayout"));
const Login = lazy(() => import("./components/pages/pos_login/POSLogin"));
const AdminLogin = lazy(() => import("./components/pages/auth/login/Login"));
const Page404 = lazy(() => import("./components/pages/error_pages/Page404"));
const Page403 = lazy(() => import("./components/pages/error_pages/Page403"));
const Page500 = lazy(() => import("./components/pages/error_pages/Page500"));

const AndroidPrivacy = lazy(() => import("./components/pages/privay-policy/AndroidPrivacy"));
const IOSPrivacy = lazy(() => import("./components/pages/privay-policy/IOSPrivacy"));
const TermsAndConditions = lazy(() => import("./components/pages/terms_conditions/TermsAndConditions"));

function App() {

    const authContext = useContext(AuthContext);
    const {locale} = useContext(GlobalContext);

    return (
        <I18nProvider locale={locale}>
            <div>
                <Suspense fallback={<LoadingSuspense height={"100vh"}/>}>
                    <BrowserRouter>
                        <Switch>
                            <Route exact path={ANDROID_PRIVACY_PATH} component={AndroidPrivacy}/>
                            <Route exact path={IOS_PRIVACY_PATH} component={IOSPrivacy}/>
                            <Route exact path={TERMS_AND_CONDITION_APP_PATH} component={TermsAndConditions}/>
                            <Route exact path={ADMIN_LOGIN_PATH} component={AdminLogin}/>
                            <Route exact path={LOGIN_PATH} component={Login}/>
                            <Route exact path={`${POS_SALE_HISTORY_PRINT_PATH}/:id/:shopId`}
                                   component={PosSaleHistoryPrint}/>
                            <Route exact path={`${POS_SALE_HISTORY_PRINT_A4_PATH}/:id/:shopId`}
                                   component={PosSaleHistoryPrintA4}/>
                            {/*payment status*/}
                            <Route exact path={SUCCESS_PAYMENT_STATUS_PATH}
                                   component={SuccessPayment}/>
                            <Route exact path={ERROR_PAYMENT_STATUS_PATH}
                                   component={ErrorPayment}/>

                            <Route exact path={CANCEL_PAYMENT_STATUS_PATH}
                                   component={CancelPayment}/>

                            <PrivateRoute isLogin={authContext.isLogin} exact path={PAGE_404_PATH} component={Page404}/>
                            <PrivateRoute isLogin={authContext.isLogin} exact path={PAGE_403_PATH} component={Page403}/>
                            <PrivateRoute isLogin={authContext.isLogin} exact path={PAGE_500_PATH} component={Page500}/>
                            <PrivateRoute isLogin={authContext.isLogin} path={POS_SHOP_VIEW_PATH} component={Shop}/>
                            <PrivateRoute isLogin={authContext.isLogin} path={ROOT_PATH} component={DefaultLayout}/>


                        </Switch>
                        {/*<Acl/>*/}
                    </BrowserRouter>

                </Suspense>
            </div>
        </I18nProvider>
    );
}

export default App;
