import * as URL from "../helpers/Constants";
import axios from "axios";

export default class AuthService {
    static login(data, isSalesPerson) {

        let url = URL.SIGN_IN;

        if (isSalesPerson) {
            url = URL.SALES_PERSON_SIGN_IN;
        }

        return axios.post(url, data);
    }

    static signUp(data) {
        return axios.post(URL.SIGN_IN, data);
    }
}
