import React, {createContext, useState} from "react";
import Notification from "../components/common/Notification";
import POSSalesPersonServices from "../services/POSSalesPersonServices";

export const POSSalesPersonContext = createContext("POSSalesPersonContext");

const POSSalesPersonContextProvider = ({children}) => {
    const [loading, setLoading] = useState(true);
    const [loadingCreateSalesPerson, setLoadingCreateSalesPerson] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [pagination, setPagination] = useState({
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "30", "50", "100"],
    });

    const [loadingRoles, setLoadingRoles] = useState(false);
    const [roles, setRoles] = useState([]);

    const [totalElements, setTotalElements] = useState(0);
    const [salesPersons, setSalesPersons] = useState([]);
    const [salesPerson, setSalesPerson] = useState(null);

    const getAllSalesPersonsByShopId = async (params) => {
        try {
            setLoading(true);
            setErrorMsg("");

            const res = await POSSalesPersonServices.getAllSalesPersonsByShopId(
                params
            );

            setPagination({
                ...pagination,
                total: res.data.totalElements,
                current: params.page,
                pageSize: params.size,
            });

            setSalesPersons(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const getSalesPersonById = async (id) => {
        try {
            setLoading(true);
            setErrorMsg("");

            const res = await POSSalesPersonServices.getSalesPersonById(id);

            setSalesPerson(res.data);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const getSalesPersonRole = async () => {
        try {
            setLoadingRoles(true);

            const res = await POSSalesPersonServices.getSalesPersonRole();

            setRoles(res.data);
            setLoadingRoles(false);
        } catch (error) {
            setLoadingRoles(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const createSalesPersonByShopId = async (params) => {
        try {
            setLoadingCreateSalesPerson(true);
            setErrorMsg("");
            await POSSalesPersonServices.createSalesPersonByShopId(
                params
            );
            Notification("success", "Success", "SalesPerson created successfully!");
            setLoadingCreateSalesPerson(false);
            return true;
        } catch (error) {
            setLoadingCreateSalesPerson(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const updateSalesPersonBySalesPersonId = async (id, data) => {
        try {
            setLoadingCreateSalesPerson(true);
            setErrorMsg("");
            await POSSalesPersonServices.updateSalesPersonBySalesPersonId(
                id,
                data
            );
            Notification("success", "Success", "SalesPerson updated successfully!");
            setLoadingCreateSalesPerson(false);
            return true;
        } catch (error) {
            setLoadingCreateSalesPerson(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const deleteSalesPersonById = async (id) => {
        try {
            setLoading(true);
            setErrorMsg("");

            await POSSalesPersonServices.deleteSalesPersonById(id);
            setSalesPersons(salesPersons.filter((salesPerson) => salesPerson.id !== id));

            Notification("success", "DELETED", "SalesPerson has been deleted successfully");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    return (
        <POSSalesPersonContext.Provider
            value={{
                loading,
                errorMsg,
                pagination,
                setPagination,
                totalElements,
                salesPersons,
                salesPerson,
                loadingRoles,
                loadingCreateSalesPerson,
                roles,
                getAllSalesPersonsByShopId,
                createSalesPersonByShopId,
                updateSalesPersonBySalesPersonId,
                deleteSalesPersonById,
                getSalesPersonById,
                getSalesPersonRole,
            }}
        >
            {children}
        </POSSalesPersonContext.Provider>
    );
};

export default POSSalesPersonContextProvider;
