import React, {createContext, useState} from 'react';
import ShopOwnerService from "../services/ShopOwnerService";
import {getErrorMessage} from "../helpers/Utils";
import {Toast} from "../components/common/Toast";

export const ShopOwnerContext = createContext("ShopOwnerContext");

const ShopOwnerContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [shopOwners, setShopOwners] = useState(null);
    const [shopOwner, setShopOwner] = useState(null);
    const [profileLoading, setProfileLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [shops, setShops] = useState(null);

    async function getAllShopOwners({page, size, name}) {

        try {
            const res = await ShopOwnerService.getAllShopOwners(page, size, name);
            setShopOwners(res.data.content)
            return res.data.content;
        } catch (error) {
            return false;
        }

    }

    async function getProductsForShopOwner(page, size) {

        try {
            setLoading(true);
            const res = await ShopOwnerService.getProductsForShopOwner(page, size);
            setProducts(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Featured products, please check the console");
            return error.status;
        }

    }


    async function addShopOwner(data) {

        try {
            const res = await ShopOwnerService.addShopOwner(data)
            setShopOwners(shopOwners ? [...shopOwners, res.data] : [res.data]);
            return true;
        } catch (err) {
            const message = getErrorMessage(err);
            Toast("error", "Error", message);
            return false;
        }

    }

    async function getShopOwnerProfile() {
        try {
            setProfileLoading(true);
            const res = await ShopOwnerService.getProfile();
            setShopOwner(res.data);
            setProfileLoading(false);
            return true;
        } catch (error) {
            setProfileLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }
    }

    async function updateShopOwner(data, id) {
        try {
            const res = await ShopOwnerService.updateShopOwner(data, id)
            let updatedShopOwners = shopOwners;
            updatedShopOwners = shopOwners.map(e => {
                if (e.id === id) e = res.data;
                return e;
            })

            setShopOwners(updatedShopOwners);
            Toast("success", "Success", "ShopOwner updated successfully!");
            return true;
        } catch (error) {
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }

    }

    async function deleteShopOwner(id) {

        if (!window.confirm("Are you sure you want to delete this ShopOwner ?")) return;

        try {
            await ShopOwnerService.deleteShopOwner(id);
            let updatedShopOwners = shopOwners;
            updatedShopOwners = shopOwners.filter(e => e.id !== id)
            setShopOwners(updatedShopOwners);
            return true;
        } catch {
            return false;
        }

    }

    const changeShopOwnerStatus = async (email, status) => {

        if (!window.confirm(`Are you sure you want to change this ShopOwner status to ${status} ?`)) return;

        try {
            await ShopOwnerService.changeShopOwnerStatus(email, status);
            let updatedShopOwners = shopOwners;
            updatedShopOwners = shopOwners.map(e => {
                if (e.email === email) e.status = status;
                return e;
            })
            setShopOwners(updatedShopOwners);
            return true;
        } catch {
            return false;
        }

    }


    const getAllShopsForShopOwner = async ({page, size}) => {

        try {
            setLoading(true);
            setErrorMsg("");
            const res = await ShopOwnerService.getAllShopsForShopOwner(page, size);
            setShops(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can't load data, please check the console.");
            return false;
        }
    }


    return (
        <ShopOwnerContext.Provider
            value={{
                loading,
                errorMsg,
                shopOwners,
                shopOwner,
                getAllShopOwners,
                getProductsForShopOwner,
                products,
                shops,
                totalElements,
                profileLoading,
                updateShopOwner,
                deleteShopOwner,
                addShopOwner,
                getShopOwnerProfile,
                changeShopOwnerStatus,
                getAllShopsForShopOwner
            }}
        >
            {children}
        </ShopOwnerContext.Provider>
    );
}

export default ShopOwnerContextProvider;
