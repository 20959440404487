import * as URL from "../helpers/Constants";
import axios from "axios";
import {getShopId} from "../helpers/Utils";

export default class POSTransactionServices {
    static getSalesWhichCashNotReceived(params) {
        return axios.get(
            `${URL.POS_SALES_NOT_CASH_RECEIVED_BY_ID_BY_SHOP_ID}/${getShopId()}`, {
                ...POSTransactionServices.getAuthHeader(),
                params,
            }
        );
    }


    static postSaleWhichCashHasReceived(id, amount, isCashierChecked) {
        return axios.post(
            `${URL.POS_SALES_CASH_RECEIVED_BY_ID_BY_SHOP_ID}/${id}/${getShopId()}`,
            null,
            {
                params: {amount, isCashierChecked},
                ...POSTransactionServices.getAuthHeader()
            }
        );
    }

    static posCashReceivedRefresh(shopId) {
        return axios.post(`${URL.POS_CASH_RECEIVED_REFRESH}/${shopId}`, null, POSTransactionServices.getAuthHeader()
        );
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        return {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        };
    }
}
