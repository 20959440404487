import axios from "axios";
import * as URL from "../helpers/Constants";

export default class POSCategoryServices {
    static getAllCategoryByShopId(id, params) {
        return axios.get(`${URL.GET_ALL_CATEGORY_BY_SHOP_ID}/${id}`, {
            ...POSCategoryServices.getAuthHeader(),
            params
        })
    }

    static createCategoryByShopId(id, data) {

        return axios.post(`${URL.CREATE_CATEGORY_BY_SHOP_ID}/${id}`, data, POSCategoryServices.getAuthHeader())
    }

    static getCategoryByShopIdById(shopId, id) {
        return axios.get(`${URL.POS_GET_CATEGORY_BY_SHOP_ID_BY_ID}/${id}/${shopId}`, POSCategoryServices.getAuthHeader())
    }

    static updateCategoryByIdByShopId(id, shopId, data) {
        return axios.put(`${URL.POS_UPDATE_CATEGORY_BY_ID_SHOP_ID}/${id}/${shopId}`, data, POSCategoryServices.getAuthHeader())
    }

    static deleteCategoryById(id, shopId) {
        return axios.delete(`${URL.POS_DELETE_CATEGORY_BY_ID}/${id}/${shopId}`, POSCategoryServices.getAuthHeader())
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        return {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        };
    }
}