import axios from "axios";
import * as URL from "../helpers/Constants";

export default class ReportsServices {
    static getAllReportsByShopId(id, params) {
        return axios.get(`${URL.GET_ALL_STOCK_DETAILS_BY_SHOP_ID}/${id}`, {
            ...ReportsServices.getAuthHeader(),
            params
        })
    }

    static getAllJournalByShopId(id, params) {
        return axios.get(`${URL.GET_ALL_JOURNAL_BY_SHOP_ID}/${id}`, {
            ...ReportsServices.getAuthHeader(),
            params
        })
    }

    static getAllCustomersPaymentStatusByShopId(id, params) {
        return axios.get(`${URL.GET_ALL_CUSTOMERS_PAYMENT_STATUS_BY_SHOP_ID}/${id}`, {
            ...ReportsServices.getAuthHeader(),
            params
        })
    }
    static getAllVendorsPaymentStatusByShopId(id, params) {
        return axios.get(`${URL.GET_ALL_VENDORS_PAYMENT_STATUS_BY_SHOP_ID}/${id}`, {
            ...ReportsServices.getAuthHeader(),
            params
        })
    }

    static getAllSaleHistoryByShopId(id, params) {
        const newParam = {
            from:  params.saleFrom,
            to: params.saleTo
        }
        return axios.get(`${URL.GET_ALL_SALE_HISTORY_BY_SHOP_ID}/${id}`, {
            ...ReportsServices.getAuthHeader(),
            params: newParam
        })
    }

    static getAllPurchaseHistoryByShopId(id, params) {
        const newParam = {
            from:  params.purchaseFrom,
            to: params.purchaseTo
        }
        return axios.get(`${URL.GET_ALL_PURCHASE_HISTORY_BY_SHOP_ID}/${id}`, {
            ...ReportsServices.getAuthHeader(),
            params: newParam
        })
    }
    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        return {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        };
    }
}