import React from 'react';
import PDFTableContent from "./PDFTableContent";

const SaleInvoiceA4 = ({componentRef, data, pdfConfig, title}) => {

    const getPageMargins = () => {
        return `@page {
                    margin: 1px 0 !important;
                 }
                 @media print {
                     .page-break {
                        page-break-before: always;
                    }
                }`;
    };

    return (
        <div ref={componentRef}>

            <style>{getPageMargins()}</style>

            <PDFTableContent
                data={data}
                pdfConfig={pdfConfig}
                title={title}
            />

        </div>
    );
};

export default SaleInvoiceA4;