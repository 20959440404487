import React, {createContext, useState} from "react";
import Notification from "../components/common/Notification";
import {generateLocalId} from "../helpers/Utils";
import ReconciliationService from "../services/POSReconciliationService";

export const POSReconciliationContext = createContext("POSReconciliationContext");

const POSReconciliationContextProvider = ({children}) => {
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [totalElements, setTotalElements] = useState(0);
    const [reconciliations, setReconciliations] = useState([]);

    //sync
    const [reconciliationSyncLoading, setReconciliationSyncLoading] = useState(false);
    const [createdSyncCountLoading, setCreatedSyncCountLoading] = useState(false);
    const [updatedSyncCountLoading, setUpdatedSyncCountLoading] = useState(false);

    const [createdSynCount, setCreatedSyncCount] = useState(0);
    const [updatedSynCount, setUpdatedSyncCount] = useState(0);

    const getAllReconciliations = async (shopId, params) => {
        try {
            setLoading(true);
            setErrorMsg("");
            const res = await ReconciliationService.getAllReconciliationsByShopId(shopId, params);
            setReconciliations(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const addReconciliation = async (shopId, data, loggedInUserId) => {
        try {
            setLoading(true);
            data.localId = generateLocalId(shopId, loggedInUserId);
            await ReconciliationService.createReconciliation(shopId, data);
            Notification("success", "CREATED", "Successfully reconciliation created");
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const cancelReconciliation = async (id, shopId) => {
        try {

            setLoading(true);
            await ReconciliationService.cancelReconciliation(id, shopId);
            Notification("success", "CANCELLED", "Successfully reconciliation cancelled");
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    //sync
    const getCreatedSyncCount = async (shopId, params) => {
        try {
            setCreatedSyncCountLoading(true);
            const res = await ReconciliationService.getCreatedReconciliationSyncCount(shopId, params);
            setCreatedSyncCount(res.data);
            setCreatedSyncCountLoading(false);
            return true;
        } catch (error) {
            setCreatedSyncCountLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getUpdatedSyncCount = async (shopId, params) => {
        try {
            setUpdatedSyncCountLoading(true);
            const res = await ReconciliationService.getUpdatedReconciliationSyncCount(shopId, params);
            setUpdatedSyncCount(res.data);
            setUpdatedSyncCountLoading(false);
            return true;
        } catch (error) {
            setUpdatedSyncCountLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const createReconciliationSync = async (shopId, params) => {
        try {
            setReconciliationSyncLoading(true);
            await ReconciliationService.createReconciliationSync(shopId, params);
            // setReconciliationSyncLoading(false);
            return true;
        } catch (error) {
            setReconciliationSyncLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const updateReconciliationSync = async (shopId, params) => {
        try {
            setReconciliationSyncLoading(true);
            await ReconciliationService.updateReconciliationSync(shopId, params);
            Notification("success", "SUCCESS", "Reconciliation Synced Successfully");
            setReconciliationSyncLoading(false);
            return true;
        } catch (error) {
            setReconciliationSyncLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    return (
        <POSReconciliationContext.Provider
            value={{
                loading,
                errorMsg,
                totalElements,
                reconciliations,
                createdSyncCountLoading,
                updatedSyncCountLoading,
                reconciliationSyncLoading,
                createdSynCount,
                updatedSynCount,
                getAllReconciliations,
                cancelReconciliation,
                addReconciliation,
                getCreatedSyncCount,
                getUpdatedSyncCount,
                createReconciliationSync,
                updateReconciliationSync,
            }}
        >
            {children}
        </POSReconciliationContext.Provider>
    );
};

export default POSReconciliationContextProvider;
