import React, {createContext, useState} from 'react';
import Notification from "../components/common/Notification";
import PosSubscriptionService from "../services/PosSubscriptionService";
import {Toast} from "../components/common/Toast";
import {getErrorMessage} from "../helpers/Utils";

export const SubscriptionContext = createContext("SubscriptionContext");
const SubscriptionContextProvider = ({children}) => {
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [totalElements, setTotalElements] = useState(0);

    // Transaction charge
    const [transactionCharges, setTransactionCharges] = useState([]);

    const [totalAmounts, setTotalAmounts] = useState(0);

    const [bkashUrl, setBkashUrl] = useState([]);

    const [paymentHistories, setOnlinePaymentHistory] = useState([]);

    const [monthlySubscriptions, setMonthlySubscriptions] = useState([]);

    const [currentTimeIndex] = useState([]);

    const getMonthlySubscription = async (shopId, year) => {

        try {
            setLoading(true);
            setErrorMsg("");
            const res = await PosSubscriptionService.getAllMonthlySubscriptionByShopId(shopId, year);
            setMonthlySubscriptions(res.data);

            res.data?.map((item, index) => {
                const targetDate = new Date(item?.month + item?.year);
                const currentTimestamp = targetDate.getTime();

                // Get date one month ahead
                const oneMonthAheadDate = new Date();
                // oneMonthAheadDate.setMonth(oneMonthAheadDate.getMonth() + 1);
                oneMonthAheadDate.setMonth(oneMonthAheadDate.getMonth());
                const oneMonthAheadTimestamp = oneMonthAheadDate.getTime();

                if (currentTimestamp < oneMonthAheadTimestamp && !item.paymentStatus) {
                    currentTimeIndex.push(index)
                }

                return true;
            })

            const totalAmount = res.data.reduce(
                (total, charge) => total + charge.fee,
                0
            );

            setTotalAmounts(totalAmount);

            setTotalElements(res.data.length);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getPaymentHistory = async (shopId) => {

        try {
            setLoading(true);
            setErrorMsg("");

            const res = await PosSubscriptionService.getPaymentHistory(shopId);
            setOnlinePaymentHistory(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const createBkashPayment = async (shopId, data) => {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await PosSubscriptionService.createBkashPayment(shopId, data)
            if (res.data && res.data.bkashURL) {
                // Redirect to the Bkash URL
                window.location.href = res.data.bkashURL;
            }
            console.log('res', res.data)
            setBkashUrl(res.data);
            setLoading(false);
            return true;
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            setErrorMsg("Can not create payments.");
            Toast("error", "Error", message);
            return false;
        }
    }


    const getSubscriptionCharge = async (id, params) => {

        try {
            setLoading(true);
            setErrorMsg("");

            const res = await PosSubscriptionService.getAllInstallationChargeByShopId(id, params);

            setTransactionCharges(res.data.content);

            const totalAmount = res.data.content.reduce(
                (total, charge) => total + charge.amount,
                0
            );

            setTotalAmounts(totalAmount);

            setTotalElements(res.data.totalElements);

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    return (
        <SubscriptionContext.Provider
            value={{
                loading,
                errorMsg,
                totalElements,
                transactionCharges,
                getSubscriptionCharge,
                bkashUrl,
                createBkashPayment,
                paymentHistories,
                getPaymentHistory,
                totalAmounts,
                monthlySubscriptions,
                getMonthlySubscription,
                currentTimeIndex
            }}
        >
            {children}
        </SubscriptionContext.Provider>
    )
}

export default SubscriptionContextProvider;