import * as URL from "../helpers/Constants";
import axios from "axios";

export default class POSConfigService {
    static getPrintingConfigByShopId(id) {
        return axios.get(`${URL.GET_PRINTING_CONFIG_BY_ID}/${id}`, POSConfigService.getAuthHeader());
    }

    static updatePrintingConfig(id, data) {
        return axios.post(`${URL.UPDATE_PRINTING_CONFIG_BY_ID}/${id}`, data, POSConfigService.getAuthHeader());
    }

    static getShopSettingByShopId(id) {
        return axios.get(`${URL.GET_SHOP_SETTING_BY_ID}/${id}`, POSConfigService.getAuthHeader());
    }

    static getOrderConfigById(id) {
        return axios.get(`${URL.GET_ORDER_CONFIG_BY_ID}/${id}`, POSConfigService.getAuthHeader());
    }

    static updateShopSetting(id, data) {
        return axios.post(`${URL.UPDATE_SHOP_SETTING_BY_ID}/${id}`, data, POSConfigService.getAuthHeader());
    }

    static updateOrderSetting(id, data) {
        return axios.post(`${URL.UPDATE_ORDER_SETTING_BY_ID}/${id}`, data, POSConfigService.getAuthHeader());
    }


    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        return {headers: {"Authorization": `Bearer ${accessToken}`}};
    }
}
