import React, {createContext, useState} from 'react';
import Notification from "../../components/common/Notification";
import ProductTypeService from "../../services/auth/ProductTypeService";

export const ProductTypeContext = createContext("ProductTypeContext");
const ProductTypeContextProvider = ({children}) => {
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [totalElements, setTotalElements] = useState(0);
    const [productTypes, setProductTypes] = useState(null);
    const [productType, setProductType] = useState(null);

    const getAllProductType = async (params) => {

        try {
            setLoading(true);
            setErrorMsg("");

            const res = await ProductTypeService.getAllProductType(params);

            setProductTypes(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const createProductType = async (data) => {

        try {
            setLoading(true);
            setErrorMsg("");

            await ProductTypeService.createProductType(data);

            Notification("success", "Created", "Product type created successfully!");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getProductTypeById = async (id) => {
        try {
            setLoading(true);
            setErrorMsg("");
            const res = await ProductTypeService.getProductTypeById( id);
            setProductType(res.data);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const updateProductTypeById = async (id, data) => {

        try {
            setLoading(true);
            setErrorMsg("");

            await ProductTypeService.UpdateProductTypeById(id, data);

            Notification("success", "Updated", "Product type updated successfully!");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }


    const deleteProductType = async (id, shopId) => {

        try {
            setLoading(true);
            setErrorMsg("");

            await ProductTypeService.deleteProductTypeById(id);

            getAllProductType();

            Notification("success", "Deleted", "Category has been deleted successfully!");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }



    return (
        <ProductTypeContext.Provider
            value={{
                loading,
                errorMsg,
                totalElements,
                productTypes,
                productType,
                deleteProductType,
                getAllProductType,
                createProductType,
                getProductTypeById,
                updateProductTypeById,
            }}
        >
            {children}
        </ProductTypeContext.Provider>
    )
}

export default ProductTypeContextProvider;