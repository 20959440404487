import * as URL from "../../helpers/Constants";
import axios from "axios";

export default class DashboardService {
    static getAllActiveShops(page, size) {
        return axios.get(URL.GET_ALL_ACTIVE_SHOPS, {...DashboardService.getAuthHeader(), params: {page, size}});
    }

    static getActiveShopCount() {
        return axios.get(URL.GET_ACTIVE_SHOP_COUNT, DashboardService.getAuthHeader());
    }


    static getAllCustomerCount = () => {
        return axios.get(URL.GET_ALL_CUSTOMERS_COUNT, DashboardService.getAuthHeader());
    }

    static getAllPendingOrdersCount = () => {
        return axios.get(URL.GET_ALL_PENDING_ORDER_COUNT, DashboardService.getAuthHeader());
    }

    static getAllActiveDeliveryPersonCount = () => {
        return axios.get(URL.GET_ALL_DELIVERY_PERSONS_COUNT, DashboardService.getAuthHeader());
    }


    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }
}
