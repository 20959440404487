import {notification} from "antd";

const Notification = (type, message, description) => {

    notification[type]({
        message,
        description,
        placement: "bottomRight"
    });
};

export default Notification;