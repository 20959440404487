import * as URL from "../helpers/Constants";
import axios from "axios";


export default class PermissionService {
    static getAllPermissions() {
        return axios.get(URL.GET_ALL_PERMISSIONS, PermissionService.getAuthHeader());
    }

    static getPermissionsByUserType(userType) {
        return axios.get(URL.GET_PERMISSIONS_BY_USER_TYPE, {...PermissionService.getAuthHeader(), params: {userType}});
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }
}
