import React, {createContext, useState} from "react";
import {getErrorMessage} from "../helpers/Utils";
import {Toast} from "../components/common/Toast";
import POSStoreConfigService from "../services/POSStoreConfigService";

export const POSStoreConfigContext = createContext("POSStoreConfigContext");

const POSStoreConfigProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [storeConfig, setStoreConfig] = useState(null);

    const getStoreConfigById = async () => {
        try {
            setLoading(true);
            const res = await POSStoreConfigService.getPosStoreConfigById();
            setStoreConfig(res.data);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }
    }

    return (
        <POSStoreConfigContext.Provider
            value={{
                loading,
                storeConfig,
                getStoreConfigById,
            }}
        >
            {children}
        </POSStoreConfigContext.Provider>
    )
}

export default POSStoreConfigProvider;