import * as URL from "../../helpers/Constants";
import axios from "axios";

export default class SalesPersonService {

    static getAllSalesPersons(page, size, id, name, email) {
        return axios.get(URL.GET_ALL_SALES_PERSON, {
            ...SalesPersonService.getAuthHeader(),
            params: {page, size, id, name, email}
        });
    }

    static getSalesPersonById(id) {
        return axios.get(`${URL.GET_SALES_PERSON_BY_ID}/${id}`, SalesPersonService.getAuthHeader());
    }

    static getProfile() {
        return axios.get(URL.GET_SALES_PERSON_PROFILE, SalesPersonService.getAuthHeader());
    }

    static addSalesPersonFromAdmin(data) {
        return axios.post(URL.POST_SALES_PERSON_FROM_ADMIN, data, SalesPersonService.getAuthHeader());
    }

    static addSalesPersonFromShopOwner(data) {
        return axios.post(URL.POST_SALES_PERSON_FROM_SHOP_OWNER, data, SalesPersonService.getAuthHeader());
    }

    static updateSalesPersonFromShopOwner(data, id) {
        return axios.put(`${URL.UPDATE_SALES_PERSON_FROM_SHOP_OWNER}/${id}`, data, SalesPersonService.getAuthHeader());
    }

    static updateSalesPersonFromAdmin(data, id) {
        return axios.put(`${URL.UPDATE_SALES_PERSON_FROM_ADMIN}/${id}`, data, SalesPersonService.getAuthHeader());
    }

    static deleteSalesPerson(id) {
        return axios.delete(`${URL.DELETE_SALES_PERSON}/${id}`, SalesPersonService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static changeSalesPersonStatus(email, status_payment) {
    //     return axios.post(`${URL.UPDATE_SALES_PERSON_STATUS}/${email}?status_payment=${status_payment}`)
    // }
}