import React, {createContext, useState} from "react";
import POSReportService from "../services/POSReportService";
import {getErrorMessage} from "../helpers/Utils";
import {Toast} from "../components/common/Toast";

export const POSReportContext = createContext("POSReportContext");

const POSReportContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [report, setReport] = useState(null);
    const [accountBalanceReport, setAccountBalanceReport] = useState(null);
    const [pmsDashBoardTopDataLoading, setPMSDashboardTopDataLoading] = useState(false);
    const [pmsDashBoardTopData, setPMSDashboardTopData] = useState(null);

    const getPOSReportByShopId = async (shopId, params) => {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await POSReportService.getPOSReportByShopId(shopId, params);

            setReport(res.data);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }

    }

    const getPOSAccountBalanceReport = async shopId => {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await POSReportService.getPOSAccountBalanceReport(shopId);

            setAccountBalanceReport(res.data);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }
    }

    const generatePOSExcelReportByShopId = async (shopId, params) => {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await POSReportService.generatePOSExcelReportByShopId(shopId, params);

            if (res) {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', res.headers['content-disposition'].split("filename=")[1]);
                document.body.appendChild(link);
                link.click();
            }
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }
    }

    const generateBarcodeById = async (id, shopId) => {

        let url = "";
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await POSReportService.generateBarcodeReportByShopId(id, shopId);
            if (res) {
                url = window.URL.createObjectURL(new Blob([res.data]));
            }
            setLoading(false);
            return url;
        } catch (error) {
            setLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }
    }

    const getPMSDashboardTopData = async pharmacyId => {
        try {
            setPMSDashboardTopDataLoading(true);
            const res = await POSReportService.getPMSDashboardTopData(pharmacyId);
            setPMSDashboardTopData(res.data);
            setPMSDashboardTopDataLoading(false);
        } catch (error) {
            setPMSDashboardTopDataLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }
    }

    return (
        <POSReportContext.Provider
            value={{
                loading,
                errorMsg,
                report,
                accountBalanceReport,
                getPOSReportByShopId,
                generatePOSExcelReportByShopId,
                getPOSAccountBalanceReport,
                getPMSDashboardTopData,
                pmsDashBoardTopDataLoading,
                pmsDashBoardTopData,
                generateBarcodeById
            }}
        >
            {children}
        </POSReportContext.Provider>
    )
}

export default POSReportContextProvider;

