import * as URL from "../../helpers/Constants";
import axios from "axios";

export default class BannerService {


    static getAllBanner(params) {
        return axios.get(URL.GET_ALL_BANNER, {
            params, ...BannerService.getAuthHeader()
        });
    }

    static getBannerById(id) {
        return axios.get(`${URL.GET_BANNER_BY_ID}/${id}`, BannerService.getAuthHeader());
    }

    static addBanner(data) {
        return axios.post(URL.ADD_BANNER, data, BannerService.getAuthHeader());
    }

    static updateBanner(data, id) {
        return axios.put(`${URL.UPDATE_BANNER}/${id}`, data, BannerService.getAuthHeader());
    }

    static deleteBanner(id) {
        return axios.delete(`${URL.DELETE_BANNER}/${id}`, BannerService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        return {headers: {"Authorization": `Bearer ${accessToken}`}}
    }
}