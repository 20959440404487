import React from 'react';
import ReactDOM from 'react-dom';

// SCSS && CSS
import 'antd/dist/antd.css';
import './assets/scss/style.scss';

import App from './App';
import reportWebVitals from './reportWebVitals';
import AuthContextProvider from "./context/auth/AuthContextProvider";
import UserContextProvider from "./context/auth/UserContextProvider";
import RoleContextProvider from "./context/RoleContextProvider";
import PermissionContextProvider from "./context/PermissionContextProvider";
import POSShopContextProvider from "./context/POSShopContext";
import CompanyContextProvider from "./context/CompanyContextProvider";
import ShopOwnerContextProvider from "./context/ShopOwnerContextProvider";
import GlobalContextProvider from "./context/GlobalContextProvider";
import POSProductContextProvider from "./context/POSProductContextProvider";
import POSInventoryContextProvider from "./context/POSInventoryContextProvider";
import POSVendorContextProvider from "./context/POSVendorContextProvider";
import POSVendorPaymentContextProvider from "./context/POSVendorPaymentContextProvider";
import POSTransactionAccountContextProvider from "./context/POSTransactionAccountContextProvider";
import POSTransactionContextProvider from "./context/POSTransactionContextProvider";
import POSExpenseContextProvider from "./context/POSExpenseContextProvider";
import POSCustomerContextProvider from "./context/POSCustomerContextProvider";
import POSSaleContextProvider from "./context/POSSaleContextProvider";
import POSCustomerPaymentContextProvider from "./context/POSCustomerPaymentContextProvider";
import POSSalesPersonContextProvider from "./context/POSSalesPersonContextProvider";
import POSReconciliationContextProvider from "./context/POSReconciliationContextProvider";
import POSExpiredItemsContextProvider from "./context/POSExpiredItemsContextProvider";
import POSRequisitionsContextProvider from "./context/POSRequisitionsContextProvider";
import POSCashReceivedContextProvider from "./context/POSCashReceivedContextProvider";
import SalesPersonContextProvider from "./context/auth/SalesPersonContextProvider";
import ShopContextProvider from "./context/auth/ShopContextProvider";
import SubscriptionPlanContextProvider from "./context/auth/SubscriptionPlanContextProvider";
import GlobalConfigContextProvider from "./context/auth/GlobalConfigContextProvider";
import DashboardContextProvider from "./context/auth/DashboardContextProvider";
import POSOfflineConfigContextProvider from "./context/auth/POSOfflineConfigContextProvider";
import POSConfigurationContextProvider from "./context/POSConfigurationContextProvider";
import POSStoreConfigContextProvider from "./context/POSStoreConfigContextProvider";
import BannerContextProvider from "./context/auth/BannerContextProvider";
import PosCategoryContextProvider from "./context/POSCategoryContextProvider";
import POSCompanyContextProvider from "./context/POSCompanyContextProvider";
import POSReportContextProvider from "./context/POSReportContextProvider";
import ReportContextProvider from "./context/ReportContextProvider";
import ProductTypeContextProvider from "./context/auth/ProductTypeContextProvider";
import AdminCompanyContextProvider from "./context/auth/AdminCompanyContextProvider";
import SubscriptionContextProvider from "./context/SubscriptionContextProvider";
import OnlinePaymentHistoryContextProvider from "./context/auth/TransactionsContextProvider";
import PosOrderContextProvider from "./context/auth/PosOrderContextProvider";
// import BarcodeDetectContextProvider from "./context/BarcodeDetectContextProvider";

export const contextProviders = [
    GlobalContextProvider,
    AuthContextProvider,
    UserContextProvider,
    RoleContextProvider,
    PermissionContextProvider,
    POSShopContextProvider,
    CompanyContextProvider,
    ShopOwnerContextProvider,
    POSReportContextProvider,
    POSProductContextProvider,
    POSInventoryContextProvider,
    POSVendorContextProvider,
    POSVendorPaymentContextProvider,
    POSTransactionAccountContextProvider,
    POSTransactionContextProvider,
    POSExpenseContextProvider,
    POSCustomerContextProvider,
    POSCustomerPaymentContextProvider,
    POSSalesPersonContextProvider,
    POSReconciliationContextProvider,
    POSExpiredItemsContextProvider,
    POSRequisitionsContextProvider,
    POSCashReceivedContextProvider,
    POSSaleContextProvider,
    SalesPersonContextProvider,
    ShopContextProvider,
    SubscriptionPlanContextProvider,
    GlobalConfigContextProvider,
    DashboardContextProvider,
    POSOfflineConfigContextProvider,
    POSConfigurationContextProvider,
    POSStoreConfigContextProvider,
    BannerContextProvider,
    PosCategoryContextProvider,
    POSCompanyContextProvider,
    ReportContextProvider,
    ProductTypeContextProvider,
    AdminCompanyContextProvider,
    SubscriptionContextProvider,
    OnlinePaymentHistoryContextProvider,
    PosOrderContextProvider
];

const getAppWithContextProviders = () => {
    let result = <App/>;
    contextProviders.forEach(
        (Provider) => (result = <Provider>{result}</Provider>)
    );

    return result;
};

ReactDOM.render(getAppWithContextProviders(), document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
