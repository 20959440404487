import React, {createContext, useState} from 'react';
import CompanyService from "../services/CompanyService";
import {getErrorMessage} from "../helpers/Utils";
import {Toast} from "../components/common/Toast";

export const CompanyContext = createContext("CompanyContext");


const CompanyContextProvider = ({children}) => {

    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    async function getAllCompanies({page, size, id, companyName}) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await CompanyService.getAllCompanies(page, size, id, companyName);
            setTotalElements(res.data.totalElements);
            setCompanies(res.data.content);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            const message = getErrorMessage(error);
            setErrorMsg("Can not load Companies. Please check the console.");
            Toast("error", "Error", message);
            return false;
        }

    }


    async function getCompanyById(id) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await CompanyService.getCompanyById(id);
            setCompany(res.data);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            const message = getErrorMessage(error);
            setErrorMsg("Can not load Company. Please check the console.");
            Toast("error", "Error", message);
            return false;
        }

    }

    async function addCompany(data) {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await CompanyService.addCompany(data)
            setCompanies(companies ? [...companies, res.data] : [res.data]);
            setLoading(false);
            Toast("success", "Success", `Company Id - ${res.data.id} saved successfully!`);
            return true;
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            setErrorMsg("Can not add Companies. Please check the console.");
            Toast("error", "Error", message);
            return false;
        }
    }

    async function updateCompany(DATA, id) {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await CompanyService.updateCompany(DATA, id);
            setCompany(res.data);
            let updatedCompanies = companies;
            updatedCompanies = companies.map(e => {
                if (e.id === id) e = res.data;
                return e;
            })
            Toast("success", "Success", `Company Id - ${res.data.id} updated successfully!`);

            setCompanies(updatedCompanies);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            const message = getErrorMessage(error);
            setErrorMsg("Can not update Company. Please check the console.");
            Toast("error", "Error", message);
            return false;
        }

    }

    async function deleteCompany(id) {

        if (!window.confirm("Are you sure you want to delete this Company ?")) return;

        try {
            setLoading(true);
            setErrorMsg('');
            await CompanyService.deleteCompany(id);
            let updatedCompanies = companies;
            updatedCompanies = companies.filter(e => e.id !== id);
            setCompanies(updatedCompanies);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            const message = getErrorMessage(error);
            setErrorMsg("Can not delete Companies. Please check the console.");
            Toast("error", "Error", message);
            return false;
        }
    }


    return (
        <CompanyContext.Provider
            value={{
                companies,
                company,
                loading,
                errorMsg,
                totalElements,
                getAllCompanies,
                updateCompany,
                getCompanyById,
                deleteCompany,
                addCompany
            }}
        >
            {children}
        </CompanyContext.Provider>
    );
}

export default CompanyContextProvider;
