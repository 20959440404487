import React from 'react';
import {Redirect, Route, useHistory} from 'react-router-dom';
import {ADMIN_LOGIN_PATH, LOGIN_PATH} from '../../routes/Slugs';
import {getFullPreviousRoute} from "../../helpers/Utils";

const PrivateRoute = ({isLogin, component: Component, ...rest}) => {

    const history = useHistory();
    const currentDomain = window.location.hostname;

    return (
        <Route
            {...rest}
            render={() => isLogin ? <Component/> :
                currentDomain.includes('admin.app.unnoty.com') || currentDomain.includes("admin.unnoty.com") ?
                    <Redirect to={`${ADMIN_LOGIN_PATH}?previous=${getFullPreviousRoute(history)}`}/> :
                    currentDomain.includes('login.app.unnoty.com') ?
                        <Redirect to={`${LOGIN_PATH}?previous=${getFullPreviousRoute(history)}`}/> :
                        <Redirect to={`${LOGIN_PATH}?previous=${getFullPreviousRoute(history)}`}/>
            }
        />
    );
}

export default PrivateRoute;
