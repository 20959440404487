import axios from "axios";
import * as URL from "../helpers/Constants";

export default class POSSaleService {

    static getSaleReceiptService(saleId, shopId) {
        // return axios.get(`${URL.SALE_RECEIPT_URL}/${saleId}/${shopConfig}/${shopId}`, POSSaleService.getAuthHeader());
        return axios.get(`${URL.SALE_RECEIPT_URL}/${saleId}/${shopId}`);
    }

    static getSaleHistoryListByShopId(id, params) {
        return axios.get(`${URL.POS_GET_ALL_SALE_BY_SHOP_ID}/${id}`, {
            params, ...POSSaleService.getAuthHeader()
        });
    }

    static getSaleById(shopId, id) {
        return axios.get(`${URL.POS_GET_SALE_BY_ID}/${shopId}/${id}`, POSSaleService.getAuthHeader());
    }

    static createSale(shopId, data) {
        return axios.post(`${URL.POS_CREATE_SALES_BY_SHOP_ID}/${shopId}`, data, POSSaleService.getAuthHeader());
    }

    static editSale(id, shopId, data, params) {
        return axios.put(`${URL.POS_UPDATE_SALES_BY_ID_BY_SHOP_ID}/${id}/${shopId}`, data, {
            ...POSSaleService.getAuthHeader(), params
        });
    }

    static cancelSale(id, shopId, data) {
        return axios.put(`${URL.POS_UPDATE_SALES_BY_ID_BY_SHOP_ID}/${id}/${shopId}`, data, {
            ...POSSaleService.getAuthHeader(), params: {isCanceled: true}
        });
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        return {headers: {"Authorization": `Bearer ${accessToken}`}};
    }

}
