import React, {createContext, useState} from 'react';
import ShopService from "../../services/auth/ShopService";
import Notification from "../../components/common/Notification";

export const ShopContext = createContext("ShopContext");

const ShopContextProvider = ({children}) => {

    const [shops, setShops] = useState([]);
    const [shop, setShop] = useState(null);
    const [shopSubscriptionPlan, setShopSubscriptionPlan] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);
    const [shopRoles, setShopRoles] = useState(null);
    const [statistics, setStatistics] = useState(null);

    async function getAllShops({page, size, id, name, shopOwnerName, shopOwnerPhone}) {

        try {
            setLoading(true);
            const res = await ShopService.getAllShops(page, size, id, name, shopOwnerName, shopOwnerPhone);
            setShops(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            return false;
        }

    }

    async function getShopById(id) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await ShopService.getShopById(id);
            setShop(res.data);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Shops. Please check the console.");
            console.log("error", error)
            return false;
        }

    }

    async function getSubscriptionPlanForShop() {

        try {
            setLoading(true);

            setErrorMsg('');
            const res = await ShopService.getSubscriptionPlanForShop();
            setShopSubscriptionPlan(res.data);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load Shops. Please check the console.");
            console.log("error", error)
            return false;
        }

    }

    async function addShop(data, shopOwnerId) {

        try {
            setLoading(true);
            setErrorMsg("")
            const res = await ShopService.addShop(data, shopOwnerId)
            setShop(res.data);
            Notification("success", "SUCCESS", `Shop Id - ${res.data.id} create successfully!`);
            setLoading(false)
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    async function updateShop(data, id) {
        try {
            setLoading(true);
            await ShopService.updateShop(data, id)
            setLoading(false);
            Notification("success", "SUCCESS", "POSShop updated properly.");
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const getShopStatistics = async (id) => {
        try {
            setLoading(true);
            const res = await ShopService.getShopStatistics(id);
            setStatistics(res.data);
            setLoading(false);
            return res.data
        } catch (e) {
            setLoading(false);
            setErrorMsg("Can't load data, Please Check the console.");
            return null;
        }
    }

    async function deleteShop(id) {

        try {
            setLoading(true);
            await ShopService.deleteShop(id);
            let updatedShops = shops;
            updatedShops = shops.filter(e => e.id !== id)
            setShops(updatedShops);
            setLoading(false);
            return true;
        } catch {
            setLoading(false);
            return false;
        }

    }

    const changeShopStatus = async (email, status) => {


        try {
            await ShopService.changeShopStatus(email, status);
            let updatedShops = shops;
            updatedShops = shops.map(e => {
                if (e.email === email) e.status = status;
                return e;
            })
            setShops(updatedShops);
            return true;
        } catch {
            return false;
        }

    }

    const getShopRolesById = async (id) => {
        try {
            setLoading(true);
            const res = await ShopService.getShopRolesById(id);
            setShopRoles(res.data);
            setLoading(false);
            return res.data
        } catch (e) {
            setLoading(false);
            setErrorMsg("Can't load data, Please Check the console.");
            return null;
        }
    }

    const checkShopAsOnline = async (id, status) => {
        try {
            await ShopService.checkShopAsOnline(id, status);
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    return (
        <ShopContext.Provider
            value={{
                shops,
                shop,
                totalElements,
                getSubscriptionPlanForShop,
                shopSubscriptionPlan,
                loading,
                getShopById,
                errorMsg,
                getAllShops,
                updateShop,
                deleteShop,
                addShop,
                changeShopStatus,
                shopRoles,
                getShopRolesById,
                checkShopAsOnline,
                getShopStatistics,
                statistics
            }}
        >
            {children}
        </ShopContext.Provider>
    );
}

export default ShopContextProvider;
