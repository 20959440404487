export const ROOT_PATH = "/";
export const DASHBOARD_PATH = `${ROOT_PATH}dashboard`;

export const ADMIN_LOGIN_PATH = `${ROOT_PATH}admin-login`;
export const LOGIN_PATH = `${ROOT_PATH}login`;
export const LOGOUT_PATH = `${ROOT_PATH}logout`;

export const ANDROID_PRIVACY_PATH = `${ROOT_PATH}privacy-policy-android`;
export const IOS_PRIVACY_PATH = `${ROOT_PATH}privacy-policy-ios`;

//user
export const USER_PATH = `${ROOT_PATH}user`;
export const USER_INFO_PATH = `${ROOT_PATH}user-info`;
export const ADD_USER_PATH = `${ROOT_PATH}add-user`;
export const USER_ROLE_UPDATE_PATH = `${ROOT_PATH}update-role`;
export const EDIT_USER_PATH = `${ROOT_PATH}edit-user`;

//user Profile
export const USER_PROFILE_PATH = `${ROOT_PATH}user/profile`;

//role
export const ROLE_EDIT_PATH = `${ROOT_PATH}edit-role`;

export const ROLE_PATH = `${ROOT_PATH}role`;
export const ADD_ROLE_PATH = `${ROOT_PATH}add-role`;
export const ADD_ROLE_FOR_SHOP_PATH = `${ROOT_PATH}add-role-for-shop`;
export const EDIT_ROLE_FOR_SHOP_PATH = `${ROOT_PATH}edit-role-for-shop`;

//pos_shop owner
export const SHOP_OWNER = `${ROOT_PATH}shop-owner`;
export const ADD_SHOP_OWNER = `${ROOT_PATH}add-shop-owner`;
export const EDIT_SHOP_OWNER = `${ROOT_PATH}edit-shop-owner`;

//errors
export const PAGE_403_PATH = `${ROOT_PATH}403`;
export const PAGE_404_PATH = `${ROOT_PATH}404`;
export const PAGE_500_PATH = `${ROOT_PATH}500`;

//pos_shop
export const SHOP_VIEW_PATH = `${ROOT_PATH}shop`;
export const ADD_SHOP_PATH = `${ROOT_PATH}add-shop`;
export const EDIT_SHOP_PATH = `${ROOT_PATH}edit-shop`;
export const SHOP_INFO_PATH = `${ROOT_PATH}shop-info`;
export const SHOP_STATISTICS_PATH = `${ROOT_PATH}shop-statistics`;


//pos_shop owner
export const SHOP_OWNER_VIEW_PATH = `${ROOT_PATH}shop-owners`;

//pos_shop agent
export const SALES_PERSON_VIEW_PATH = `${ROOT_PATH}sales-person`;
export const SALES_PERSON_IMAGE_VIEW_PATH = `${ROOT_PATH}sales-person-image`;
export const EDIT_SALES_PERSON_PATH = `${ROOT_PATH}edit-sales-person`;
export const ADD_SALES_PERSON_PATH = `${ROOT_PATH}add-sales-person`;
export const SALES_PERSON_INFO_PATH = `${ROOT_PATH}sales-person-info`;

//banner
export const BANNER_LIST_VIEW_PATH = `${ROOT_PATH}banner-list`;
export const ADD_BANNER_PATH = `${ROOT_PATH}add-banner`;
export const EDIT_BANNER_PATH = `${ROOT_PATH}edit-banner`;

//Product Type
export const PRODUCT_TYPE_LIST_VIEW_PATH = `${ROOT_PATH}product-type`;
export const PRODUCT_TYPE_CREATE_PATH = `${ROOT_PATH}product-type-create`;
export const PRODUCT_TYPE_EDIT_PATH = `${ROOT_PATH}product-type-update`;

//----------------------- ADMIN COMPANY -------------------------------
export const ADMIN_COMPANY_VIEW_PATH = `${ROOT_PATH}company`;
export const ADMIN_COMPANY_CREATE_PATH = `${ROOT_PATH}company-create`;
export const ADMIN_COMPANY_EDIT_PATH = `${ROOT_PATH}company-update`;

//global config
export const GLOBAL_CONFIG_VIEW_PATH = `${ROOT_PATH}global-config`;
export const GLOBAL_CONFIG_INFO_PATH = `${ROOT_PATH}global-config-info`;
export const ADD_GLOBAL_CONFIG_PATH = `${ROOT_PATH}add-global-config`;
export const EDIT_GLOBAL_CONFIG_PATH = `${ROOT_PATH}edit-global-config`;

//-------------------Upload Product Files------------
export const UPLOAD_PRODUCT_FILE_VIEW_PATH = `${ROOT_PATH}upload-product`;

// ---------------------- pos ----------------------------------------

export const POS_LOGIN_PATH = `${ROOT_PATH}pos-login`;
export const POS_LOGOUT_PATH = `${ROOT_PATH}pos-logout`;
export const POS_SHOP_VIEW_PATH = `${ROOT_PATH}pos-shop`;

//----------------------- pos CUSTOMER -------------------------------
export const POS_CUSTOMER_VIEW_PATH = `${ROOT_PATH}pos-customers`;
export const POS_CUSTOMER_CREATE_PATH = `${ROOT_PATH}pos-customer-create`;
export const POS_CUSTOMER_EDIT_PATH = `${ROOT_PATH}pos-customer-update`;
export const POS_CUSTOMER_INFO_PATH = `${ROOT_PATH}pos-customer-info`;

//----------------------- pos EXPENSE -------------------------------
export const POS_EXPENSE_VIEW_PATH = `${ROOT_PATH}pos-expenses`;
export const POS_EXPENSE_CREATE_PATH = `${ROOT_PATH}pos-expense-create`;
export const POS_EXPENSE_EDIT_PATH = `${ROOT_PATH}pos-expense-update`;
export const POS_EXPENSE_INFO_PATH = `${ROOT_PATH}pos-expense-info`;

//----------------------- pos INVENTORY -------------------------------
export const POS_INVENTORY_VIEW_PATH = `${ROOT_PATH}pos-inventories`;
export const POS_INVENTORY_DETAILS_VIEW_PATH = `${ROOT_PATH}pos-inventory-details`;
export const POS_PURCHASE_EDIT_PATH = `${ROOT_PATH}pos-purchase-update`;

//----------------------- pos PRODUCT -------------------------------
export const POS_PRODUCT_INFO_PATH = `${ROOT_PATH}pos-product-info`;
export const POS_PURCHASE_PRODUCT_CREATE_PATH = `${ROOT_PATH}pos-purchase-product-create`;
export const POS_PRODUCT_ADD_PATH = `${ROOT_PATH}pos-product-add`;
export const POS_PRODUCT_EDIT_PATH = `${ROOT_PATH}pos-product-edit`;
export const POS_PRODUCT_LIST_PATH = `${ROOT_PATH}pos-product`;

// BARCODE
export const POS_PRODUCT_BARCODE_PATH = `${ROOT_PATH}product-barcode-generate`;
export const POS_PURCHASE_PATH = `${ROOT_PATH}pos-purchase`;

//----------------------- pos requisition-new -------------------------------
export const POS_REQUISITION_PATH = `${ROOT_PATH}pos-requisition`;
export const POS_REQUISITION_RECORD_PATH = `${ROOT_PATH}pos-requisition-record`;
export const POS_REQUISITION_RECORD_EDIT_PATH = `${ROOT_PATH}pos-requisition-record-edit`

//----------------------- pos SALE -------------------------------
export const POS_SALE_HISTORY_VIEW_PATH = `${ROOT_PATH}pos-sales`;
export const POS_SALE_HISTORY_PRINT_PATH = `${ROOT_PATH}pos-sales-print`;
export const POS_SALE_CHALAN_PRINT_PATH = `${ROOT_PATH}pos-sales-chalan-print`;
export const POS_SALE_HISTORY_PRINT_A4_PATH = `${ROOT_PATH}pos-sales-print-a4`;
export const POS_SALE_HISTORY_DETAILS_VIEW_PATH = `${ROOT_PATH}pos-sale-details`;
export const POS_SALE_RECORD_EDIT_PATH = `${ROOT_PATH}pos-sale-update`;
export const POS_SALE_PATH = `${ROOT_PATH}pos-sale`

//----------------------- pos VENDOR -------------------------------
export const POS_VENDOR_VIEW_PATH = `${ROOT_PATH}pos-vendors`;
export const POS_VENDOR_CREATE_PATH = `${ROOT_PATH}pos-vendor-create`;
export const POS_VENDOR_EDIT_PATH = `${ROOT_PATH}pos-vendor-update`;
export const POS_VENDOR_INFO_PATH = `${ROOT_PATH}pos-vendor-info`;

//----------------------- pos CASH RECEIVED -------------------------------
export const POS_CASH_RECEIVED_VIEW_PATH = `${ROOT_PATH}pos-cash-received`;

//----------------------- pos CUSTOMER -------------------------------
export const POS_SALES_PERSON_VIEW_PATH = `${ROOT_PATH}pos-sales-persons`;
export const POS_SALES_PERSON_CREATE_PATH = `${ROOT_PATH}pos-sales-person-create`;
export const POS_SALES_PERSON_EDIT_PATH = `${ROOT_PATH}pos-sales-person-update`;
export const POS_SALES_PERSON_INFO_PATH = `${ROOT_PATH}pos-sales-person-info`;

// --------------------- pos Item Expired ------------------------
export const POS_EXPIRED_ITEMS_VIEW_PATH = `${ROOT_PATH}pos-expired-items`;

// ---------------------- pos Transaction Account ---------------------
export const POS_TRANSACTION_ACCOUNTS_VIEW_PATH = `${ROOT_PATH}pos-transaction-accounts`;
export const POS_TRANSACTION_ACCOUNT_CREATE_PATH = `${ROOT_PATH}pos-transaction-account-create`;
export const POS_TRANSACTION_ACCOUNT_EDIT_PATH = `${ROOT_PATH}pos-transaction-account-update`;

// ---------------------- pos Transactions ---------------------
export const POS_TRANSACTIONS_VIEW_PATH = `${ROOT_PATH}pos-transactions`;
export const POS_TRANSACTION_CREATE_PATH = `${ROOT_PATH}pos-transaction-create`;
export const POS_TRANSACTION_INFO_PATH = `${ROOT_PATH}pos-transaction-info`;

// =================== pos Customer Payments==================
export const POS_CUSTOMER_PAYMENTS_VIEW_PATH = `${POS_CUSTOMER_VIEW_PATH}/payments`;
export const POS_CUSTOMER_PAYMENTS_CREATE_PATH = `${POS_CUSTOMER_VIEW_PATH}/payments/create`;

// =================== pos Vendor Payments==================
export const POS_VENDOR_PAYMENTS_VIEW_PATH = `${POS_VENDOR_VIEW_PATH}/payments`;
export const POS_VENDOR_PAYMENTS_CREATE_PATH = `${POS_VENDOR_VIEW_PATH}/payments/create`;

// =================== reconciliation ==================
export const RECONCILIATION_LIST_VIEW_PATH = `${ROOT_PATH}reconciliation-list`;
export const ADD_RECONCILIATION_PATH = `${ROOT_PATH}reconciliation-add`;

// =================== terms & conditions ==================
export const TERMS_AND_CONDITION_PATH = `${ROOT_PATH}terms-and-conditions`;
export const TERMS_AND_CONDITION_APP_PATH = `${ROOT_PATH}ems-terms-and-conditions`;

// =================== pos configurations ==================
export const POS_CONFIGURATIONS_PATH = `${ROOT_PATH}invoice-config`;
export const POS_SALE_CONFIG_PATH = `${ROOT_PATH}sale-config`;
export const POS_ORDER_CONFIG_PATH = `${ROOT_PATH}order-config`;

// =================== pos offline configuration ==================
export const POS_OFFLINE_CONFIG_LIST_VIEW_PATH = `${ROOT_PATH}pos-offline-config-list`;
export const POS_OFFLINE_CONFIG_CREATE_PATH = `${ROOT_PATH}pos-offline-config-create`;
export const POS_OFFLINE_CONFIG_UPDATE_PATH = `${ROOT_PATH}pos-offline-config-update`;
export const POS_OFFLINE_CONFIG_VIEW_PATH = `${ROOT_PATH}pos-offline-config`;

// =================== pos online store ==================
export const POS_ORDER = `${ROOT_PATH}pos-order`;
export const POS_CAMPAIGN = `${ROOT_PATH}pos-campaign`;
export const POS_STORE_CONFIG = `${ROOT_PATH}pos-store-config`;
export const POS_ORDER_CONFIG = `${ROOT_PATH}pos-order-config`;
export const POS_CAMPAIGN_CONFIG = `${ROOT_PATH}pos-campaign-config`;

//----------------------- pos CATEGORY -------------------------------
export const POS_CATEGORY_VIEW_PATH = `${ROOT_PATH}pos-categories`;
export const POS_CATEGORY_CREATE_PATH = `${ROOT_PATH}pos-category-create`;
export const POS_CATEGORY_EDIT_PATH = `${ROOT_PATH}pos-category-update`;

//----------------------- POS COMPANY -------------------------------
export const POS_COMPANY_VIEW_PATH = `${ROOT_PATH}pos-company`;
export const POS_COMPANY_CREATE_PATH = `${ROOT_PATH}pos-company-create`;
export const POS_COMPANY_EDIT_PATH = `${ROOT_PATH}pos-company-update`;

//----------------------- POS REPORTS -------------------------------
//----------------------- STOCK DETAILS -------------------------------
export const POS_STOCK_DETAILS_VIEW_PATH = `${ROOT_PATH}pos-stock-details`;
export const POS_STOCK_DETAILS_PRINT_PATH = `${ROOT_PATH}pos-stock-details-print`;
//----------------------- ACCOUNTING LEDGER -------------------------------
export const POS_ACCOUNTING_LEDGER_PATH = `${ROOT_PATH}pos-accounting-ledger`;
export const POS_ACCOUNTING_LEDGER_PRINT_PATH = `${ROOT_PATH}pos-accounting-ledger-print`;
//----------------------- CUSTOMERS PAYMENT STATUS -------------------------------
export const POS_CUSTOMERS_PAYMENT_STATUS_PATH = `${ROOT_PATH}customers-account`;
export const POS_CUSTOMERS_PAYMENT_STATUS_PRINT_PATH = `${ROOT_PATH}customers-payment-status-print`;
export const POS_CUSTOMER_PAYMENT_PRINT_PATH = `${ROOT_PATH}customers-payment-print`;
export const POS_CUSTOMER_SALE_PRINT_PATH = `${ROOT_PATH}customers-sale-print`;
//----------------------- VENDORS PAYMENT STATUS -------------------------------
export const POS_VENDORS_PAYMENT_STATUS_PATH = `${ROOT_PATH}vendors-account`;
export const POS_VENDORS_PAYMENT_STATUS_PRINT_PATH = `${ROOT_PATH}vendors-payment-status-print`;
//----------------------- SALES PURCHASE COMPARE -------------------------------
export const POS_SALE_PURCHASE_COMPARE_PATH = `${ROOT_PATH}sale-purchase-compare`;
export const POS_SALE_PURCHASE_COMPARE_PRINT_PATH = `${ROOT_PATH}sale-purchase-compare-print`;

// ---------------------- Subscription ---------------------
export const POS_INSTALLATION_CHARGE_PATH = `${ROOT_PATH}installation-charge`;
export const MONTHLY_SUBSCRIPTION_PATH = `${ROOT_PATH}monthly-subscription`;
export const PAYMENT_HISTORY = `${ROOT_PATH}payment-history`;


// ---------------------- Payment Status ---------------------
export const SUCCESS_PAYMENT_STATUS_PATH = `${ROOT_PATH}success`;
export const ERROR_PAYMENT_STATUS_PATH = `${ROOT_PATH}failed`;
export const CANCEL_PAYMENT_STATUS_PATH = `${ROOT_PATH}cancel`;

// ---------------------- Transactions ---------------------
export const ONLINE_PAYMENT_HISTORY = `${ROOT_PATH}online-payment-history`;

export const SALE_RECEIPT_PATH = `${ROOT_PATH}sale-receipt`;

// EXCEL REPORT
export const POS_EXCEL_REPORT_PATH = `${ROOT_PATH}excel-report`;

export const ORDER_MANAGEMENT_PATH = `${ROOT_PATH}order-management`;
export const ORDER_VIEW_DETAILS_PATH = `${ROOT_PATH}order-view-details`;




