import {Locales} from "../Locales";

export const en_US = {
    [Locales.english]: {

        // common
        "common.total_items": "Total items",
        "common.details": "Details",
        "common.price": "Price",
        "common.unit_type": "Unit Type",
        "common.unit_required": "Unit type is required",
        "common.select_unit_type": "Please select a unit type",
        "common.action": "Action",
        "common.reset": "Reset",
        "common.the_field_required": "This field is required!",
        "common.mrp": "MRP",
        "common.title": "Title",
        "common.update": "Update",
        "common.add": "Add",
        "common.press_enter_edit": "Press Enter to Edit",
        "common.press_esc_delete": "Press Esc to Delete",
        "common.press_esc_cancel": "Press Esc to Cancel",
        "common.press_p_print": "Press P to Print",
        "common.press_t_payments": "Press t to payments",
        "common.vew_details": "Press Insert to View Details",
        "common.press_payments": "Press P to payments",
        "common.use_mouse_for_event": "Use mouse for any event",
        "common.off": "Off",
        "common.total_price": "Total Price",
        "common.discount": "Discount",
        "common.quantity": "Quantity",
        "common.billing": "Billing",
        "common.total_cost": "Total Cost",
        "common.total_selected_items": "Total Selected Items",
        "common.name": "Name",
        "common.items": "Items",
        "common.name_required": "Name is required!",
        "common.phone": "Phone",
        "common.change": "Change",
        "common.choose": "Choose",
        "common.remove": "Remove",
        "common.search_sale_history": "Search Sale History",
        "common.search_date_range": "Search Date Range",
        "common.create_by": "Created By",
        "common.search_by_full_name": "Search with full name",
        "common.total_purchase_price": "Total Purchase Price",
        "common.date": "Date",
        "common.email": "Email",
        "common.search": "Search",
        "common.balance": "Balance",
        "common.submit": "Submit",
        "common.address": "Address",
        "common.phone_number": "Phone Number",
        "common.disabled": "Disabled",
        "common.enter_number": "Please enter your number!",
        "common.phone_number_must_be": "Phone number must be 10 characters only",
        "common.created_at": "Created At",
        "common.created_by": "Created By",
        "common.updated_at": "Updated At",
        "common.updated_by": "Updated By",
        "common.print": "Print",
        "common.payments": "Payments",
        "common.advance": "Advance",
        "common.due": "Due",
        "common.paid": "Paid",
        "common.amount": "Amount",
        "common.cancel": "Cancel",
        "common.advanced": "Advanced",
        "common.free": "Free",
        "common.quantity_required": "Quantity is required",
        "common.additional_info": "Additional Info",
        "common.online": "Online",
        "common.medicine": "Medicine",
        "common.add_additional_info": "Add Additional Info",
        "common.additional_info_list": "Additional Info List",
        "common.number": "Number",
        "common.park_time": "Park Time",
        "common.description": "Description",
        "common.description_required": "Description is required",
        "common.press_insert_view_ai": "Press Insert to View Additional Info",
        "common.edit": "Edit",
        "common.download_pdf": "Download PDF",
        "common.start_time": "Start Time",
        "common.end_time": "End Time",
        "common.total_due": "Total Due",
        "common.total_paid": "Total Paid",
        "common.image": "Image",
        "common.sl": "SL.",
        "common.price_pcs": "Price(Pcs)",
        "common.qty": "Qty",
        "common.total": "Total",
        "common.less": "Less",
        "common.net_amount_tk": "Net Amount(TK)",
        "common.total_paid_tk": "Total Paid(TK))",
        "common.gender": "Gender",
        "common.male": "MALE",
        "common.female": "FEMALE",
        "common.others": "OTHERS",
        "common.enter_positive_number": "Please enter a positive number",
        "common.input_not_valid_email": "The input is not valid E-mail!",
        "common.emi_info": "EMI Info",
        "common.info": "Info",

        // nav header
        "nav_header.english": "English",
        "nav_header.bengali": "Bengali",
        "nav_header.free_live_chat": "Free Live Chat",
        "nav_header.get_mobile_app": "Get Mobile App",
        "nav_header.term_and_conditions": "Terms and conditions",
        "nav_header.profile": "Profile",
        "nav_header.sale": "Sale",
        "nav_header.logout": "Logout",

        // aside left
        "aside_left.dashboard": "Dashboard",
        "aside_left.user_management": "User Management",
        "aside_left.user": "User",
        "aside_left.shop_owner": "SHOP OWNER",
        "aside_left.sales_person": "Sales Person",
        "aside_left.role": "Role",
        "aside_left.banner": "Banner",
        "aside_left.product": "Product",
        "aside_left.company": "Company",
        "aside_left.shop": "Shop",
        "aside_left.product_list": "Product List",
        "aside_left.company_list": "Company List",
        "aside_left.category_list": "Category List",
        "aside_left.requisition": "Short List",
        "aside_left.requisition_record": "Short List Record",
        "aside_left.expired_product": "Expired Product",
        "aside_left.vendor": "Vendor",
        "aside_left.vendor_list": "Vendor List",
        "aside_left.purchase_product": "Purchase Products",
        "aside_left.purchase_record": "Purchase Record",
        "aside_left.customers": "Customers",
        "aside_left.customer_list": "Customer List",
        "aside_left.sale_products": "Sale Products",
        "aside_left.sale_record": "Sale Record",
        "aside_left.cash": "Cash",
        "aside_left.received_cash": "Received Cash",
        "aside_left.accounting": "Accounting",
        "aside_left.transaction": "Transaction",
        "aside_left.expenses": "Expenses",
        "aside_left.reports": "Reports",
        "aside_left.stock_details": "Stock Details",
        "aside_left.customers_account": "Customers Account",
        "aside_left.vendors_account": "Vendors Account",
        "aside_left.sales_purchase_compare": "Sale Purchase Compare",
        "aside_left.accounting_ledger": "Accounting Ledger",
        "aside_left.excel": "Excel",
        "aside_left.reconciliation": "Reconciliation",
        "aside_left.change_shop": "Change Shop",
        "aside_left.global_config": "GLOBAL CONFIG",
        "aside_left.configurations": "Settings ",
        "aside_left.online_shop": "Online Shop",
        "aside_left.order_coming_soon": "Order (Coming Soon)",
        "aside_left.campaign_coming_soon": "Campaign (Coming Soon)",
        "aside_left.store_config": "Store Config",

        // dashboard
        "dashboard.inventory": "Inventory",
        "dashboard.dashboard_subtitle": "Welcome to Dashboard",
        "dashboard.dashboard_title": "Dashboard",
        "dashboard.select_date_range": "Select date range",
        "dashboard.cashbook_date_range": "Cashbook by date range",
        "dashboard.buy_sale_date_range": "Buy and sale by date range",
        "dashboard.current_cash_balance": "Current Cash Balance",
        "dashboard.cash_balance": "Cash Balance",
        "dashboard.bank_balance": "Bank Balance",
        "dashboard.mfc_balance": "MFS Balance",
        "dashboard.today_business_summary": "Today Business Summary",
        "dashboard.sale_price": "Sale Price",
        "dashboard.purchase_price": "Purchase Price",
        "dashboard.bank_balance_card": "Bank Balance",
        "dashboard.inventory_purchase_price": "Inventory Purchase Price",
        "dashboard.information_according_date_range": "Information according to date range",
        "dashboard.gross_profit": "Gross Profit",
        "dashboard.operational_cost": "Operational Cost",
        "dashboard.net_profit": "Net Profit",
        "dashboard.mobile_bank_balance": "Mobile Bank Balance",
        "dashboard.closing_balance_up_to": "Closing Balance up to",
        "dashboard.total_sale": "Total Sale",
        "dashboard.due_sales_only": "Due Sales Only",
        "dashboard.due_inventory_only": "Due Inventory Only",
        "dashboard.emi": "EMI",
        "dashboard.total_emi": "Total EMI",
        "dashboard.total_emi_amount": "Total EMI Amount",

        // Product
        "product.product_list": "Product list",
        "product.add_new_product": "Add New Product",
        "product.export_product": "Export Product",
        "product.search_product_list": "Search Product List",
        "product.type": "Product Type",
        "product.search_by_product_type": "Search by product type",
        "product.select_product_type": "Select a product type",
        "product.product_price": "Product Price",
        "product.product_name": "Product Name",
        "product.search_by_product_name": "Search by product name",
        "product.search_by_barcode": "Search by Barcode",
        "product.barcode": "Barcode",
        "product.pos_product_add": "POS Product Add",
        "product.part_number": "Part Number",
        "product.display_name": "Display Name",
        "product.alternative_number": "Alternative Number",
        "product.product_edit": "POS Product Edit",
        "product.product_name_required": "Product name is required",
        "product.sale_price_is_required": "Sale price is required",
        "product.this_is_not_number": "This is not a number",
        "product.product_price_is_required": "Product price is required",
        "product.country_of_origin": "Country Of Origin",
        "product.product_images": "Product Images",
        "product.description": "Product Description",
        "product.select_product": "Please select product",
        "product.add_inventory": "Add Product to Inventory",
        "product.add_to_inventory": "Add To Inventory",
        "product.product_added": "product added",
        "product.new": "new",

        // Company
        "company.company_name": "Company Name",
        "company.company_name_required": "Company name is required",
        "company.select_company_name": "Select a company name",
        "company.list": "Company List",
        "company.add": "Add company",
        "company.search": "Search Company",
        "company.edit": "Edit Company",
        "company.title": "Company Title",
        "company.input_company": "Please input company!",
        "company.delete_warning": "Are you sure you want to delete this company",
        "company.search_by_company_name": "Search by company name",

        // Category
        "category.category": "Category",
        "category.category_required": "Category is required",
        "category.select_category": "Select a category",
        "category.list": "Category List",
        "category.add": "Category Add",
        "category.search": "Search Category",
        "category.edit": "Edit Category",
        "category.title": "Category Title",
        "category.input_category": "Please Input Category!",
        "category.delete_warning": "Are you sure you want to delete this category",

        // Requisitions
        "requisitions.product_requisitions": "Product Short List",
        "requisitions.all_items": "All Items",
        "requisitions.selected_items": "Selected Items",
        "requisitions.min_qty": "Selected Items",
        "requisitions.create_requisition": "Create Short",
        "requisitions.search_by_req_number": "Search by requisition number",
        "requisitions.requisition_number": "Short Number",


        // Requisitions Record
        "requisitions.record": "Short Record",
        "requisitions.total_bill": "Total bill",
        "requisitions.requisition_info": "Short info",
        "requisitions.total_requisition": "Total Short",
        "requisitions.att_to_purchase_cart": "Add To Purchase Cart",
        "requisitions.att_to_sale_cart": "Add To Sale Cart",
        "requisitions.att_to_requisition_cart": "Add To Short Cart",

        // Vendor
        "vendor.info": "Vendor Info",
        "vendor.choose_vendor": "Choose Vendor",
        "vendor.vendor_name": "Vendor Name",
        "vendor.input_name": "Please input vendor name!",
        "vendor.search_by_vendor_name": "Search by vendor name",
        "vendor.list": "Vendor List",
        "vendor.add": "ADD VENDOR",
        "vendor.total": "Total Vendors",
        "vendor.search": "Search Vendor",
        "vendor.search_by_name": "Search by name",
        "vendor.search_by_phone": "Search by phone",
        "vendor.search_with_due": "Search with due vendors",
        "vendor.delete": "Are you sure you want to delete this vendor",
        "vendor.create_vendor": "Create Vendor",
        "vendor.edit": "Edit Vendor",
        "vendor.enter_vendor_address": "Enter vendor address",
        "vendor.note": "Note",
        "vendor.start_writing": "Start writing...",
        "vendor.details": "Vendor details",
        "vendor.local_id": "Local Id",
        "vendor.init_balance": "Init Balance",
        "vendor.payments": "Vendor Payments",
        "vendor.payment_type": "Payment Type",
        "vendor.input_payment_method": "Please input payment method!",
        "vendor.select_payment_type": "Please select payment type",
        "vendor.add_payment": "ADD Payment",
        "vendor.transaction_type": "Transaction Type",
        "vendor.payment_method": "Payment Method",
        "vendor.cancel_payment_warning": "Are you sure you want to cancel this payment",
        "vendor.input_payable_amount": "Please input payable amount!",

        // Expired
        "expired.items": "Expired items",
        "expired.search_expired_items": "Search Expired Items",
        "expired.expired_in_days": "Expired In(days)",
        "expired.expiry_date": "Expiry Date",
        "expired.available_quantity": "Available Quantity",
        "expired.total_expired_items": "Total Expired Items",

        // Purchase Product
        "purchase.purchase": "Purchase",
        "purchase.park": "Park",
        "purchase.park_list": "Park List",
        "purchase.park_to_purchase": "Park To Purchase",

        // Purchase Record
        "purchase.purchase_record": "Purchases Record",
        "purchase.purchase_record_list": "Purchase record list",
        "purchase.search_inventories": "Search Inventories",
        "purchase.Inventories": "Inventories",
        "purchase.inventory_number": "Inventory Number",
        "purchase.search_by_inventory_number": "Search by inventory number",
        "purchase.inventory_type": "Inventory Type",
        "purchase.select_inventory_type": "Select a inventory type",
        "purchase.inventory_purchased": "INVENTORY PURCHASED",
        "purchase.inventory_missing": "INVENTORY MISSING",
        "purchase.inventory_borrowed_product": "INVENTORY BORROWED PRODUCT",
        "purchase.inventory_destroyed": "INVENTORY DESTROYED",
        "purchase.customer_returned": "CUSTOMER RETURNED",
        "purchase.returned_vendor": "RETURNED TO VENDOR",
        "purchase.bill_info": "Bill Info",
        "purchase.inventory_info": "Inventory Info",
        "purchase.final_price": "Final Price",
        "purchase.cancel_inventory_warning": "Are you sure you want to cancel this inventory",
        "purchase.inventory_details": "Inventory Details",
        "purchase.buy_quantity": "Buy Quantity",
        "purchase.free_product": "Free Product",
        "purchase.current_purchase_price": "Current Purchase Price",
        "purchase.voucher_number": "Voucher Number",
        "purchase.negative_sale": "For Negative Sale",
        "purchase.total_product_price": "Total Product Price",
        "purchase.paid_amount": "Paid Amount",
        "purchase.discount_type": "Discount Type",
        "purchase.discount_percent": "Discount Percent",
        "purchase.discount_amount": "Discount Amount",
        "purchase.netting_amount": "Netting Amount",
        "purchase.adjust_advance_amount": "Adjust Advance Amount",
        "purchase.due_amount": "Due Amount",
        "purchase.edit_purchase": "Purchase Edit",
        "purchase.inventory_num": "Inventory Num",
        "purchase.update_inventory": "Update Inventory",
        "purchase.previous_netting_amount": "Previous netting discount",
        "purchase.current_balance": "Current balance",
        "purchase.current_balance_due": "Current balance (Due)",
        "purchase.current_balance_advance": "Current balance (Advance)",
        "purchase.invoice_number": "Invoice number",
        "purchase.purchase_receipt": "Purchases Receipt",
        "purchase.purchase_no": "Purchases No.",
        "purchase.invoice_no": "Invoice No.",
        "purchase.date_and_time": "Date & Time",
        "purchase.served_by": "Served By",

        // Customer
        "customer.list": "Customer List",
        "customer.licence_number": "License Number",
        "customer.security_money": "Security Money",
        "customer.add": "ADD CUSTOMER",
        "customer.add_customer": "Add Customer",
        "customer.search": "Search Customer",
        "customer.name": "Customer name",
        "customer.phone": "Customer phone",
        "customer.customer_dues": "Customer with dues",
        "customer.customer_details": "Customer Details",
        "customer.customer_type": "Customer Type",
        "customer.edit": "Edit Customer",
        "customer.edit_customer": "Please input customer name!",
        "customer.select_customer_type": "Please select a customer type",
        "customer.customer": "CUSTOMER",
        "customer.input_phone_number": "Please input your phone number!",
        "customer.shop_name": "Shop Name",
        "customer.shop_address": "Shop Address",
        "customer.input_discount_type": "Please input your discount type!",
        "customer.flat": "FLAT",
        "customer.percentage": "PERCENTAGE",
        "customer.customer_email": "Customer E-mail",
        "customer.guardian_name": "Guarantor Name",
        "customer.husband_or_father_name": "Husband or father name",
        "customer.installment": "Instalment",
        "customer.nid": "NID",
        "customer.occupation": "Occupation",
        "customer.media_link": "Media Link",
        "customer.customer_picture": "Customer Picture",
        "customer.payments": "Customer Payments",
        "customer.payment_details": "Payment Details",
        "customer.discount_will_shop_get": "Get Discount",
        "customer.sucurity_money": "Security Money",
        "customer.business_details": "Business Details",


        // Sale Product
        "sale.total_sale_amount": "Total Sale Amount",
        "sale.product_required": "Product is required",

        // Sale Record
        "sale.sale_history": "Sale History",
        "sale.list": "Sale history list",
        "sale.sale_number": "Sale Number",
        "sale.search_by_sale_number": "Search by sale number",
        "sale.search_by_customer_name": "Search by customer name",
        "sale.salesman": "Salesman",
        "sale.search_by_salesman": "Search by salesman",
        "sale.card_commission": "Card commission",
        "sale.sale_info": "Sale Info",
        "sale.customer": "Customer",
        "sale.print_chalan": "Print chalan",
        "sale.cancel_sale_invoice_warning": "Are you sure you want to cancel this sale invoice",
        "sale.sale_history_details": "Sale history details",
        "sale.sale_details": "Sale Details",
        "sale.sale_quantity": "Sale Quantity",
        "sale.multiple_payment": "Multiple Payment",
        "sale.cash_received": "Cash Received",
        "sale.checked_by_cashier": "Checked By Cashier",
        "sale.returned": "Returned",
        "sale.profit": "Profit",
        "sale.cash_received_amount": "Cash Received Amount",
        "sale.sale_record_edit": "Sale Record Edit",
        "sale.sale_num": "Sale Num",
        "sale.total_num_sales": "Total Number of Sales",

        // Cash
        "cash.receive_cash": "Receive Cash",
        "cash.sale_item_list_not_received": "Sale item list which cash has not received",
        "cash.checked_all": "Checked All",
        "cash.sale_id": "Sale id",
        "cash.sale_by": "Sale by",
        "cash.adjust_from_advance": "Adjust form advance",
        "cash.due_payment": "Due payment",
        "cash.advance_payment": "Advance payment",
        "cash.returned_amount": "Returned amount",
        "cash.collected": "Cash Collected",
        "cash.invalid_cash_received": "Invalid cash received",
        "cash.print_chalan": "Print Chalan",
        "cash.delivery_note_chalan": "DELIVERY NOTE / CHALLAN",
        "cash.customer_address": "Customer Address",
        "cash.chalan_no": "Chalan No.",
        "cash.sales_no": "Sales No.",
        "cash.vat": "Vat",
        "cash.prepared_by": "Prepared By",
        "cash.checked_by": "Checked By",
        "cash.authorized_sign": "Authorized Signature",
        "cash.received_by": "Received By",

        // Transaction
        "trans.all_trans_account": "All Transaction Accounts",
        "trans.create_account": "Create Account",
        "trans.search_trans_account": "Search Transaction Account",
        "trans.accounts": "Transaction Accounts",
        "trans.account": "Transaction Account",
        "trans.account_type": "Accounts type",
        "trans.loan": "Loan",
        "trans.capital": "Capital",
        "trans.user_phone_required": "User's phone number is required!",
        "trans.choose_account_type": "Please choose account type!",
    }
}
