import axios from "axios";
import * as URL from "../helpers/Constants";

export default class PosSubscriptionService {
    params
    static getAllInstallationChargeByShopId(id) {
        return axios.get(`${URL.GET_ALL_INSTALLATION_CHARGE}/${id}`, {
            ...PosSubscriptionService.getAuthHeader()
        })
    }

    static createBkashPayment(shopId, data) {
        return axios.post(`${URL.BKASH_PAYMENT}/${shopId}`, data, PosSubscriptionService.getAuthHeader());
    }

    static getAllMonthlySubscriptionByShopId(shopId, params) {
        return axios.get(`${URL.GET_ALL_MONTHLY_SUBSCRIPTION}/${shopId}`, {
            ...PosSubscriptionService.getAuthHeader(),
            params
        })
    }

    static getPaymentHistory(shopId) {
        return axios.get(`${URL.GET_ALL_PAYMENT_HISTORY}/${shopId}`, PosSubscriptionService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        return {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        };
    }
}