import axios from "axios";
import * as URL from "../../helpers/Constants";

export default class TransactionService {

    static getOnlinePaymentHistory() {
        return axios.get(URL.ONLINE_PAYMENT_HISTORY, TransactionService.getAuthHeader());
    }

    static paymentRefund(id, shopId, params) {
        console.log('rrrrrrr', params)
        return axios.post(`${URL.REFUND_PAYMENT}/${id}/${shopId}`, {}, {
            ...TransactionService.getAuthHeader(),
            params
        });
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }


}