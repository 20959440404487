import React, {createContext, useState} from "react";
import POSCustomerPaymentServices from "../services/POSCustomerPaymentServices";
import Notification from "../components/common/Notification";

export const POSCustomerPaymentContext = createContext("POSCustomerPaymentContext");

const POSCustomerPaymentContextProvider = ({children}) => {
    const [loading, setLoading] = useState(false);
    const [loadingCancelCustomerPayment, setLoadingCancelCustomerPayment] = useState(false);
    const [loadingCreateCustomerPayment, setLoadingCreateCustomerPayment] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [totalElements, setTotalElements] = useState(0);
    const [payments, setPayments] = useState([]);

    const [amount, setAmount] = useState(0);

    const getPayments = async (id, params) => {
        try {

            setLoading(true);
            setErrorMsg("");

            const res = await POSCustomerPaymentServices.getPayments(id, params);

            setAmount(res.headers.paid);

            setPayments(res.data.content);
            // setTotalElements(res.data.totalElements);
            setTotalElements(res.headers?.count);

            setLoading(false);

            return false;

        } catch (error) {
            setLoading(false)
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const createPayment = async (data) => {
        try {
            setLoadingCreateCustomerPayment(true)
            setErrorMsg("")
            await POSCustomerPaymentServices.createPayment(data)
            Notification("success", "Success", "Payment successful.");
            setLoadingCreateCustomerPayment(false)
            return true
        } catch (error) {
            setLoadingCreateCustomerPayment(false)
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false
        }
    }

    const cancelPayment = async (customerId, search, id) => {
        try {
            setLoadingCancelCustomerPayment(true)
            setErrorMsg("")
            await POSCustomerPaymentServices.cancelPayment(id)
            Notification("success", "Canceled", "Payment successfully canceled");
            setLoadingCancelCustomerPayment(false)
            return true;
        } catch (error) {
            setLoadingCancelCustomerPayment(false)
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false
        }
    }

    return (
        <POSCustomerPaymentContext.Provider
            value={{
                loading,
                errorMsg,
                payments,
                totalElements,
                loadingCreateCustomerPayment,
                amount,
                getPayments,
                createPayment,
                cancelPayment,
                loadingCancelCustomerPayment,
            }}
        >
            {children}
        </POSCustomerPaymentContext.Provider>
    );
};

export default POSCustomerPaymentContextProvider;
