import axios from "axios";
import * as URL from "../helpers/Constants";

export default class POSCompanyServices {
    static getAllShopCompanyByShopId(id, params) {
        return axios.get(`${URL.GET_ALL_SHOP_COMPANY_BY_SHOP_ID}/${id}`, {
            ...POSCompanyServices.getAuthHeader(),
            params
        })
    }

    static createShopCompanyByShopId(id, data) {

        return axios.post(`${URL.CREATE_SHOP_COMPANY_BY_SHOP_ID}/${id}`, data, POSCompanyServices.getAuthHeader())
    }

    static getShopComapnyByShopIdById(shopId, id) {
        return axios.get(`${URL.POS_GET_SHOP_COMPANY_BY_SHOP_ID_BY_ID}/${id}/${shopId}`, POSCompanyServices.getAuthHeader())
    }

    static updateShopCompanyByIdByShopId(id, shopId, data) {
        return axios.put(`${URL.POS_UPDATE_SHOP_COMPANY_BY_ID_SHOP_ID}/${id}/${shopId}`, data, POSCompanyServices.getAuthHeader())
    }

    static deleteShopCompanyById(id, shopId) {
        return axios.delete(`${URL.POS_DELETE_SHOP_COMPANY_BY_ID}/${id}/${shopId}`, POSCompanyServices.getAuthHeader())
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        return {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        };
    }
}