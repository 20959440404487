import React from 'react';
import {Button, Result} from "antd";
import './SuccessPayment.scss'
import {DASHBOARD_PATH} from "../../../../routes/Slugs";
import {Link} from "react-router-dom";

const SuccessPayment = () => {
    return (
        <div className="payment-success-page">
            <Result
                status="success"
                title="Payment Successful"
                subTitle="Your payment has been successfully processed."
                extra={[
                    <Link to={DASHBOARD_PATH}>
                        <Button type="primary" key="home">
                            Go Home
                        </Button>
                    </Link>

                ]}
            />
        </div>
    );
};

export default SuccessPayment;