import * as URL from "../../helpers/Constants";
import axios from "axios";

export default class SubscriptionPlanService {

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    static getAllSubscriptionPlans() {
        return axios.get(URL.GET_ALL_SUBSCRIPTION_PLANS, SubscriptionPlanService.getAuthHeader());
    }

    static addSubscriptionPlan(data) {
        return axios.post(URL.POST_SUBSCRIPTION_PLAN, data, SubscriptionPlanService.getAuthHeader());
    }

    static updateSubscriptionPlan(data, id) {
        return axios.put(`${URL.UPDATE_SUBSCRIPTION_PLAN}/${id}`, data, SubscriptionPlanService.getAuthHeader());
    }

    static deleteSubscriptionPlan(id) {
        return axios.delete(`${URL.DELETE_SUBSCRIPTION_PLAN}/${id}`, SubscriptionPlanService.getAuthHeader());
    }

    static getSubscriptionPlanById(id) {
        return axios.get(`${URL.GET_SUBSCRIPTION_PLAN_BY_ID}/${id}`, SubscriptionPlanService.getAuthHeader());
    }
}