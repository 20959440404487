import React, {useState, createContext} from "react"
import Notification from "../components/common/Notification";
import POSCompanyServices from "../services/POSCompanyServices";

export const POSCompanyContext = createContext("POSCompanyContext");
const POSCompanyContextProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [totalElements, setTotalElements] = useState(0);
    const [companies, setCompanies] = useState(null);
    const [company, setCompany] = useState(null);

    const getCompanyListByShopId = async (id, params) => {

        try {
            setLoading(true);
            setErrorMsg("");

            const res = await POSCompanyServices.getAllShopCompanyByShopId(id, params);

            setCompanies(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const createCompanyByShopId = async (id, data) => {

        try {
            setLoading(true);
            setErrorMsg("");

            await POSCompanyServices.createShopCompanyByShopId(id, data);

            Notification("success", "Created", "Company created successfully!");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getCompanyByShopIdById = async (shopId, id) => {
        try {
            setLoading(true);
            setErrorMsg("");
            const res = await POSCompanyServices.getShopComapnyByShopIdById(shopId, id);
            setCompany(res.data);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const updateCompanyByIdByShopId = async (id, shopId, data) => {

        try {
            setLoading(true);
            setErrorMsg("");

            await POSCompanyServices.updateShopCompanyByIdByShopId(id, shopId, data);

            Notification("success", "Updated", "Company updated successfully!");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }


    const deleteCompanyById = async (id, shopId) => {

        try {
            setLoading(true);
            setErrorMsg("");

            await POSCompanyServices.deleteShopCompanyById(id, shopId);

            setCompanies(companies.filter(category => category.id !== id));

            Notification("success", "Deleted", "Company has been deleted successfully!");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }



    return (
        <POSCompanyContext.Provider
            value={{
                loading,
                errorMsg,
                totalElements,
                companies,
                company,
                deleteCompanyById,
                getCompanyListByShopId,
                createCompanyByShopId,
                getCompanyByShopIdById,
                updateCompanyByIdByShopId,
            }}
        >
            {children}
        </POSCompanyContext.Provider>
    )
}

export default POSCompanyContextProvider;