import React, {createContext, useState} from 'react';
import Notification from "../../components/common/Notification";
import POSOfflineConfigService from "../../services/auth/POSOfflineConfigService";

export const POSOfflineConfigContext = createContext("POSOfflineConfigContext");

const POSOfflineConfigContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);
    const [configs, setConfigs] = useState([]);
    const [config, setConfig] = useState(null);

    const getAllPOSOfflineConfigs = async params => {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await POSOfflineConfigService.getAllPOSOfflineConfigs(params);
            setConfigs(res.data.content)
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const getPOSOfflineConfigById = async id => {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await POSOfflineConfigService.getPOSOfflineConfigById(id);
            setConfig(res.data);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const generatePOSOConfigToken = async pharmacyId => {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await POSOfflineConfigService.generatePOSOConfigToken(pharmacyId);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const createPOSOfflineConfig = async data => {
        try {
            setErrorMsg('');
            setLoading(true);
            await POSOfflineConfigService.createPOSOfflineConfig(data);
            Notification("success", "CREATED", "POS offline config created successfully");
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const updatePOSOfflineConfig = async (data, id) => {
        try {
            setUpdateLoading(true);
            setErrorMsg('');
            await POSOfflineConfigService.updatePOSOfflineConfig(data, id);
            Notification("success", "UPDATED", "POS offline config updated successfully");
            setUpdateLoading(false);
            return true;
        } catch (error) {
            setUpdateLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    return (
        <POSOfflineConfigContext.Provider
            value={{
                errorMsg,
                loading,
                updateLoading,
                totalElements,
                configs,
                config,
                getPOSOfflineConfigById,
                createPOSOfflineConfig,
                getAllPOSOfflineConfigs,
                updatePOSOfflineConfig,
                generatePOSOConfigToken,
            }}
        >
            {children}
        </POSOfflineConfigContext.Provider>
    );
}

export default POSOfflineConfigContextProvider;
