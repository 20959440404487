import * as URL from "../helpers/Constants";
import axios from "axios";

export default class POSCustomerServices {

    static getAllCustomersByShopId(id, params) {
        return axios.get(`${URL.POS_GET_ALL_CUSTOMER_BY_SHOP_ID}/${id}`, {
            ...POSCustomerServices.getAuthHeader(),
            params
        })
    }

    static getAllLiteCustomersByShopId(page, size, id) {
        return axios.get(`${URL.POS_GET_ALL_LITE_CUSTOMER_BY_SHOP_ID}/${id}`, {
            ...POSCustomerServices.getAuthHeader(),
            params: {page, size}
        })
    }

    static getCustomerByShopIdById(shopId, id) {
        return axios.get(`${URL.POS_GET_CUSTOMER_BY_SHOP_ID_BY_ID}/${shopId}/${id}`, POSCustomerServices.getAuthHeader())
    }

    static getCustomerReceiptByShopIdLocalId(shopId, id) {
        return axios.get(`${URL.POS_GET_CUSTOMER_RECEIPT_BY_SHOP_ID_BY_LOCAL_ID}/${shopId}/${id}`, POSCustomerServices.getAuthHeader())
    }

    static getCustomerByShopIdByLocalId(shopId, localId) {
        return axios.get(`${URL.POS_GET_CUSTOMER_BY_SHOP_ID_BY_LOCAL_ID}/${shopId}/${localId}`, POSCustomerServices.getAuthHeader())
    }

    static createCustomerByShopId(id, data) {
        return axios.post(`${URL.POS_CREATE_CUSTOMER_BY_SHOP_ID}/${id}`, data, POSCustomerServices.getAuthHeader())
    }

    static updateCustomerByIdByShopId(id, shopId, data) {
        return axios.put(`${URL.POS_UPDATE_CUSTOMER_BY_ID_SHOP_ID}/${id}/${shopId}`, data, POSCustomerServices.getAuthHeader())
    }

    static deleteCustomerById(id, shopId) {
        return axios.delete(`${URL.POS_DELETE_CUSTOMER_BY_ID}/${id}/${shopId}`, POSCustomerServices.getAuthHeader())
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        return {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        };
    }

}
