import React, {createContext, useState} from "react";
import Notification from "../components/common/Notification";
import moment from "moment";
import {
    calculateDiscount,
    generateLocalId,
    generateLocalStorageKey,
    getFracDigit,
    getRoundValue,
    reverseQuantityByUnitType
} from "../helpers/Utils";
import POSSaleService from "../services/POSSaleService";
import POSCustomerServices from "../services/POSCustomerServices";
import {GREEN, RED} from "../helpers/Constants";

export const POSSaleContext = createContext("POSSaleContext");

const POSSaleContextProvider = ({children}) => {

    const initSale = {
        saleDetailsList: [],
        paidAmount: 0,
        dueAmount: 0,
        totalPurchasePrice: 0,
        totalPrice: 0,
        customer: null,
        paymentType: "CASH",
        cashReceivedAmount: 0,
        cashReceived: false,
        discountAmount: 0,
        discountPercent: 0,
        vat: 0,
        note: "",
        nettingAmount: 0,
        adjustedProfit: 0,
        discountType: null,
        payments: [],
        customerPayment: 0,
        chalanNumber: ""
    }

    const localStoredSale = JSON.parse(localStorage.getItem(generateLocalStorageKey("sale")));
    const _saleParkList = JSON.parse(localStorage.getItem(generateLocalStorageKey("sale_parks")));

    const [saleHistoryList, setSaleHistoryList] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [saleHistoryDetails, setSaleHistoryDetails] = useState({
        saleDetailsList: []
    });
    const [saleHistoryViewDetails, setSaleHistoryViewDetails] = useState({
        saleDetailsList: []
    });

    const [saleParkList, setSaleParkList] = useState(_saleParkList ?? []);

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [sale, setSale] = useState(localStoredSale ? localStoredSale : initSale);
    const [saleReport, setSaleReport] = useState({totalSaleAmount: 0, totalDueAmount: 0, grossProfit: 0});

    const [saleReceiptData, setSaleReceiptData] = useState(null);

    const refreshSale = () => {
        const _data = localStorage.getItem(generateLocalStorageKey("sale"));
        setSale(_data ? JSON.parse(_data) : initSale);
    }

    const getSaleReceipt = async (saleId, shopId) => {

        try {
            setLoading(true);
            setErrorMsg('');

            const res = await POSSaleService.getSaleReceiptService(saleId, shopId);

            setSaleReceiptData(res.data);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const getFinalPrice = (data) => {

        let vat = !data.vat || isNaN(data.vat) ? 0 : parseFloat(data.vat);
        let totalPrice = !data.totalPrice || isNaN(data.totalPrice) ? 0 : parseFloat(data.totalPrice);

        let discountPercent = data.discountPercent ? totalPrice * data.discountPercent / 100 : 0;
        let discountAmount = data.discountAmount ? data.discountAmount : 0;

        let discount = discountPercent ? discountPercent : discountAmount;

        let _totalPrice = totalPrice - discount;

        // return getRoundValue(_totalPrice + ((vat * _totalPrice) / 100));
        return _totalPrice + ((vat * _totalPrice) / 100);
    }

    const getAdjustAdvance = (data) => {

        if (data.customer && data.customer.balance < 0) {

            let advance = Math.abs(data.customer.balance);
            let finalPrice = getFinalPrice(data);

            return Math.min(advance, finalPrice)

        }

        return 0;
    }

    const getRemainingPayment = (data) => {

        const due = getRoundValue(getFinalPrice(data) - data.paidAmount);
        // const due = getFinalPrice(data) - data.paidAmount;

        if (Math.abs(due) === 0) {
            return {
                "title": "",
                "amount": 0,
                "color": "#000000"
            }
        }

        if (due > 0) {
            return {
                "title": "Due",
                "amount": due,
                "color": RED
            }
        }

        return {
            "title": "Advance",
            "amount": due * -1,
            "color": GREEN
        };
    }

    const getCustomerBalance = (data) => {

        // const balance = getRoundValue(data.customer ? data.customer.balance : 0);

        const balance = data.customer ? data.customer.balance : 0;

        if (Math.abs(balance) === 0) {
            return {
                "title": "Current Balance",
                "color": "#000000",
                "amount": 0
            }
        }

        if (balance > 0) {
            return {
                "title": "Current Balance (Due)",
                "color": RED,
                "amount": balance
            }
        }

        return {
            "title": "Current Balance (Advance)",
            "color": GREEN,
            "amount": balance * -1
        };
    }

    const getCustomerNewBalance = (data) => {

        // const balance = getRoundValue(data.customer ? (data.customer.balance + (getFinalPrice(data) - data.paidAmount)) : 0);
        const balance = data.customer ? (data.customer.balance + (getFinalPrice(data) - data.paidAmount)) : 0;

        if (Math.abs(balance) === 0) {
            return {
                "title": "New Balance",
                "color": "#000000",
                "amount": 0
            }
        }

        if (balance > 0) {
            return {
                "title": "New Balance (Due)",
                "color": RED,
                "amount": balance
            }
        }

        return {
            "title": "New Balance (Advance)",
            "color": GREEN,
            "amount": balance * -1
        };
    }

    const getProfit = (data) => {
        const profit = data.totalPrice - data.totalProductPrice - calculateDiscount(data);
        return isNaN(profit) ? "" : profit;
    }

    const getCardCommission = (data) => {

        let discount = calculateDiscount(data);

        let _totalPrice = data.totalPrice - discount;

        return parseFloat(((_totalPrice * data.vat) / 100).toFixed(getFracDigit()));
    }

    //Sale
    const createSale = async (shopId, loggedInUserId, data) => {
        try {
            setLoading(true);

            const localId = generateLocalId(shopId, loggedInUserId);
            data.localId = localId;

            if (data.customer && !data.customer.localId) {
                data.customer.localId = localId;
            }

            const res = await POSSaleService.createSale(shopId, data);
            localStorage.removeItem(generateLocalStorageKey("sale"));
            setSale(initSale)

            Notification("success", "Sold", "Successfully sale_invoice created");

            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return null;
        }
    }

    const addSale = (data) => {

        console.log('data**********', data)

        const index = sale.saleDetailsList.findIndex(v => v.product.id === data.product.id);

        if (index < 0) {
            sale.saleDetailsList.push(data);

            let _totalPrice = 0;
            let _totalProductPrice = 0;

            sale.saleDetailsList.forEach(value => {
                if (value.quantity > 0 && value.salePrice > 0) {
                    _totalPrice += value.totalSalePrice;
                    _totalProductPrice += value.totalProductPrice;
                }
            });

            const _sale = {
                ...sale,
                saleDetailsList: [...sale.saleDetailsList],
                // totalPrice: getRoundValue(_totalPrice),
                totalPrice: _totalPrice,
                // totalProductPrice: getRoundValue(_totalProductPrice),
                totalProductPrice: _totalProductPrice,
                discountAmount: 0,
                discountPercent: 0
            };

            localStorage.setItem(generateLocalStorageKey("sale"), JSON.stringify(_sale));

            setSale(_sale);
        } else {
            sale.saleDetailsList[index] = data;

            let _totalPrice = 0;
            let _totalProductPrice = 0;

            sale.saleDetailsList.forEach(value => {
                _totalPrice += value.totalSalePrice;
                _totalProductPrice += value.totalProductPrice;
            });
            const _sale = {
                ...sale,
                saleDetailsList: [...sale.saleDetailsList],
                // totalPrice: getRoundValue(_totalPrice),
                totalPrice: _totalPrice,
                // totalProductPrice: getRoundValue(_totalProductPrice)
                totalProductPrice: _totalProductPrice
            };

            localStorage.setItem(generateLocalStorageKey("sale"), JSON.stringify(_sale));

            setSale(_sale);
        }
    }

    const updateSaleByKey = (key, value, extra) => {

        let _sale = {...sale, [key]: value, ...extra}

        if (key === "discountOperation") {
            sale.discountAmount = 0;
            sale.discountPercent = 0;
            _sale = {...sale}
        }

        if (_sale.discountAmount > 0) {
            _sale.discountType = "FLAT"
        }

        if (_sale.discountPercent > 0) {
            _sale.discountType = "PERCENTAGE"
        }

        if (key === "paidAmount") {
            // _sale.paidAmount = getRoundValue(value)
            _sale.paidAmount = value
        }

        localStorage.setItem(generateLocalStorageKey("sale"), JSON.stringify(_sale));
        setSale({..._sale});
    }

    const deleteSale = (index) => {
        sale.saleDetailsList.splice(index, 1);

        let _totalPrice = 0;
        let _totalProductPrice = 0;

        sale.saleDetailsList.forEach(value => {
            _totalPrice += value.totalSalePrice;
            _totalProductPrice += value.totalProductPrice;
        });

        if (sale.saleDetailsList.length <= 0) {
            sale.vat = 0;
            sale.paidAmount = 0;
        }

        const _sale = {
            ...sale,
            saleDetailsList: [...sale.saleDetailsList],
            // totalPrice: getRoundValue(_totalPrice),
            totalPrice: _totalPrice,
            // totalProductPrice: getRoundValue(_totalProductPrice),
            totalProductPrice: _totalProductPrice,
            discountAmount: 0,
            discountPercent: 0
        };
        localStorage.setItem(generateLocalStorageKey("sale"), JSON.stringify(_sale));
        setSale(_sale);
    }

    const addSaleRecord = (data) => {

        const index = saleHistoryDetails.saleDetailsList.findIndex(v => v.product.id === data.product.id);

        if (index < 0) {
            saleHistoryDetails.saleDetailsList.push(data);

            let _totalPrice = 0;
            let _totalProductPrice = 0;

            saleHistoryDetails.saleDetailsList.forEach(value => {
                if (value.quantity > 0 && value.salePrice > 0) {
                    _totalPrice += value.totalSalePrice;
                    _totalProductPrice += value.totalProductPrice;
                }
            });

            const _sale = {
                ...saleHistoryDetails,
                saleDetailsList: [...saleHistoryDetails.saleDetailsList],
                // totalPrice: getRoundValue(_totalPrice),
                totalPrice: _totalPrice,
                // totalProductPrice: getRoundValue(_totalProductPrice)
                totalProductPrice: _totalProductPrice
            };

            setSaleHistoryDetails(_sale);
        } else {
            let _totalPrice = 0;
            let _totalProductPrice = 0;

            saleHistoryDetails.saleDetailsList[index] = data;

            saleHistoryDetails.saleDetailsList.forEach(value => {
                if (value.quantity > 0 && value.salePrice > 0) {
                    _totalPrice += value.totalSalePrice;
                    _totalProductPrice += value.totalProductPrice;
                }
            });

            const _editedSaleHistory = {
                ...saleHistoryDetails,
                saleDetailsList: [...saleHistoryDetails.saleDetailsList],
                // totalPrice: getRoundValue(_totalPrice),
                totalPrice: _totalPrice,
                // totalProductPrice: getRoundValue(_totalProductPrice)
                totalProductPrice: _totalProductPrice
            };

            setSaleHistoryDetails(_editedSaleHistory);
        }
    }

    const updateSaleHistoryByKey = (key, value, extra) => {

        let _saleHistoryDetails = {...saleHistoryDetails, [key]: value, ...extra}

        if (key === "discountOperation") {
            saleHistoryDetails.discountAmount = 0;
            saleHistoryDetails.discountPercent = 0;
            _saleHistoryDetails = {...saleHistoryDetails}
        }

        if (_saleHistoryDetails.discountAmount > 0) {
            _saleHistoryDetails.discountType = "FLAT"
        }

        if (_saleHistoryDetails.discountPercent > 0) {
            _saleHistoryDetails.discountType = "PERCENTAGE"
        }

        if (key === "paidAmount") {
            // _saleHistoryDetails.paidAmount = getRoundValue(value)
            _saleHistoryDetails.paidAmount = value
        }

        setSaleHistoryDetails(_saleHistoryDetails);
    }

    const deleteSaleHistoryDetails = (index) => {

        saleHistoryDetails.saleDetailsList.splice(index, 1);

        let _totalPrice = 0;
        let _totalProductPrice = 0;

        saleHistoryDetails.saleDetailsList.forEach(value => {
            _totalPrice += value.totalSalePrice;
            _totalProductPrice += value.totalProductPrice;
        });

        if (saleHistoryDetails.saleDetailsList.length <= 0) {
            saleHistoryDetails.vat = 0;
            saleHistoryDetails.paidAmount = 0;
        }

        const _saleHistoryDetails = {
            ...saleHistoryDetails,
            saleDetailsList: [...saleHistoryDetails.saleDetailsList],
            // totalPrice: getRoundValue(_totalPrice),
            totalPrice: _totalPrice,
            // totalProductPrice: getRoundValue(_totalProductPrice)
            totalProductPrice: _totalProductPrice
        };
        setSaleHistoryDetails(_saleHistoryDetails);
    }

    const getSaleHistoryListByShopId = async (id, params) => {

        try {
            setLoading(true);
            setErrorMsg('');

            const res = await POSSaleService.getSaleHistoryListByShopId(id, params);

            setSaleReport({
                totalSaleAmount: res.headers.total,
                totalDueAmount: res.headers.due,
                grossProfit: res.headers.profit
            });
            setSaleHistoryList(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const getSaleById = async (shopId, id) => {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await POSSaleService.getSaleById(shopId, id);

            const _data = res.data;

            _data.paymentType = _data.paymentType.name;

            if (_data.customer) {
                const customer = await POSCustomerServices.getCustomerByShopIdByLocalId(shopId, _data.customer.localId);
                _data.customer = customer.data;
            }

            let sum = 0;

            _data.saleDetailsList.map(details => {

                details.totalSalePrice = details.salePrice * details.quantity;
                sum += details.totalProductPrice;
                details.showQuantity = reverseQuantityByUnitType(details.unitType, details.quantity);
                return details;
            })

            if (_data.payments) {
                _data.payments = _data.payments.map(value => {
                    return {...value, paymentType: value.paymentType.name}
                });
            }

            // _data.totalProductPrice = getRoundValue(sum);
            _data.totalProductPrice = sum;

            setSaleHistoryDetails(_data);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getSaleHistoryViewById = async (shopId, id) => {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await POSSaleService.getSaleById(shopId, id);
            setSaleHistoryViewDetails(res.data);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const editSale = async (id, shopId, data, loggedInUserId) => {

        try {
            setLoading(true);
            setErrorMsg("");

            const localId = generateLocalId(shopId, loggedInUserId);
            data.localId = localId;

            if (data.customer && !data.customer.localId) {
                data.customer.localId = localId;
            }

            await POSSaleService.editSale(id, shopId, data);
            Notification("success", "Sale Updated", "Sale updated successfully");

            setLoading(false);
            return true;
        } catch (error) {

            console.log(error)

            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            Notification("error", "ERROR", `${_error}`)
            return false;
        }

    }

    const cancelSale = async (id, shopId, data) => {

        try {
            setLoading(true);
            setErrorMsg("");

            await POSSaleService.cancelSale(id, shopId, data);

            Notification("success", "Sale Cancelled", "Sale cancelled successfully");

            setLoading(false);
            return true;
        } catch (error) {

            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            Notification("error", "ERROR", `${_error}`)
            return false;
        }

    }

    const removeSaleHistoryCustomer = () => {
        const _saleHistory = {...saleHistoryDetails, customer: null};
        setSaleHistoryDetails(_saleHistory);
    }

    const removeCustomer = () => {
        const _sale = {...sale, customer: null};
        localStorage.setItem(generateLocalStorageKey("sale"), JSON.stringify(_sale));
        setSale(_sale);
    }

    const addToSalePark = () => {

        if (sale.saleDetailsList.length < 1) return;

        const _sale = sale;
        const parkTime = moment();
        const _data = [...saleParkList, {..._sale, parkTime}];

        setSaleParkList(_data);
        localStorage.setItem(generateLocalStorageKey("sale_parks"), JSON.stringify(_data));
        setSale(initSale);
        localStorage.removeItem(generateLocalStorageKey("sale"));
    }

    const parkToSale = index => {

        let parkAbleData = null;
        if (sale.saleDetailsList.length > 0) {
            parkAbleData = sale;
        }

        const _data = saleParkList[index];
        const _list = saleParkList.filter((value, index1) => index1 !== index);
        const parkTime = moment();

        setSaleParkList(parkAbleData ? [..._list, {...parkAbleData, parkTime}] : _list);
        localStorage.setItem(generateLocalStorageKey("sale_parks"), JSON.stringify(_list));
        setSale(_data);
        localStorage.setItem(generateLocalStorageKey("sale"), JSON.stringify(_data));
    }

    const removePark = removeIndex => {
        const parkList = saleParkList.filter((v, index) => index !== removeIndex);
        localStorage.setItem(generateLocalStorageKey("sale_parks"), JSON.stringify(parkList));
        setSaleParkList(parkList);
    }

    const addRequisitionToSale = data => {

        let _totalPrice = 0;
        let _totalProductPrice = 0;

        const _saleDetailsList = data.requisitionDetailsList.map(requisition => {

            requisition.showQuantity = requisition.quantity;
            requisition.productPrice = requisition.product.productPrice;
            requisition.totalSalePrice = requisition.quantity * requisition.product.salePrice;
            requisition.totalProductPrice = requisition.quantity * requisition.product.productPrice;

            requisition.noteList = [];

            _totalPrice += requisition.totalSalePrice;
            _totalProductPrice += requisition.totalProductPrice;

            delete requisition.productName;

            return requisition;
        });


        const _data = {
            saleDetailsList: _saleDetailsList,
            paidAmount: 0,
            dueAmount: 0,
            // totalPrice: getRoundValue(_totalPrice),
            totalPrice: _totalPrice,
            // totalProductPrice: getRoundValue(_totalProductPrice),
            totalProductPrice: _totalProductPrice,
            customer: null,
            paymentType: "CASH",
            cashReceivedAmount: 0,
            cashReceived: false,
            discountAmount: 0,
            discountPercent: 0,
            vat: 0,
            note: "",
            nettingAmount: 0,
            adjustedProfit: 0,
            discountType: null,
            payments: [],
            customerPayment: 0
        }

        setSale(_data);
        localStorage.setItem(generateLocalStorageKey("sale"), JSON.stringify(_data));
    }

    return (
        <POSSaleContext.Provider
            value={{
                loading,
                errorMsg,
                saleHistoryList,
                saleHistoryDetails,
                totalElements,
                sale,
                saleHistoryViewDetails,
                saleReport,
                saleParkList,
                saleReceiptData,
                getSaleReceipt,
                createSale,
                addSale,
                addSaleRecord,
                getSaleHistoryListByShopId,
                getSaleById,
                updateSaleHistoryByKey,
                deleteSaleHistoryDetails,
                removeSaleHistoryCustomer,
                editSale,
                getFinalPrice,
                getAdjustAdvance,
                getRemainingPayment,
                getCustomerBalance,
                getCustomerNewBalance,
                getProfit,
                getCardCommission,
                updateSaleByKey,
                removeCustomer,
                deleteSale,
                cancelSale,
                getSaleHistoryViewById,
                refreshSale,
                addToSalePark,
                parkToSale,
                removePark,
                addRequisitionToSale,
            }}
        >
            {children}
        </POSSaleContext.Provider>
    )
}

export default POSSaleContextProvider;
