import React, {useContext, useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import {POSSaleContext} from "../../../context/POSSaleContextProvider";
import {
    FacebookOutlined,
    LinkedinOutlined,
    PrinterOutlined,
    ShareAltOutlined,
    TwitterOutlined,
    WhatsAppOutlined
} from "@ant-design/icons";
import {Button, Modal, PageHeader} from "antd";
import PageWrapper from "../../common/PageWrapper";
import ShareModal from "../../common/ShareModal";
import PDFTableContent from "../invoice/sale_invoice_a4/PDFTableContent";
import SaleInvoiceA4Print1 from "../invoice/sale_invoice_a4/SaleIvoiceA4Print";

import "./sale_invoice_a4.scss";

const PosSaleHistoryPrintA4 = () => {

    const params = useParams();
    const {id, shopId} = params;

    const posSaleContext = useContext(POSSaleContext);

    const saleHistoryDetailsPrintRef = useRef(null);
    const [saleHistory, setSaleHistory] = useState(null);
    const currentURL = window.location.href;

    const [shareModal, setShareModal] = useState(false);

    const socialIconList = [
        {
            id: 1,
            name: "Facebook",
            path: `fb-messenger://share?link=${currentURL}`,
            icon: <FacebookOutlined style={{fontSize: "50px", color: "#2973EA"}}/>
        },
        {
            id: 4,
            name: "Twitter",
            path: `https://twitter.com/intent/tweet?text=${currentURL}`,
            icon: <TwitterOutlined style={{fontSize: "50px", color: "#3396E8"}}/>
        },
        {
            id: 5,
            name: "Linkedin",
            path: `https://www.linkedin.com/shareArticle?mini=true&url=${currentURL}`,
            icon: <LinkedinOutlined style={{fontSize: "50px", color: "#2573B1"}}/>
        },
        {
            id: 6,
            name: "Whatsapp",
            path: `https://web.whatsapp.com/send?text=${currentURL}`,
            icon: <WhatsAppOutlined style={{fontSize: "50px", color: "#40D03F"}}/>
        },
    ]

    const openShareModal = () => {
        setShareModal(true);
    }

    const closeShareModal = () => {
        setShareModal(false);
    }

    useEffect(() => {
        posSaleContext.getSaleReceipt(id, shopId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPDF = () => {
        setSaleHistory(posSaleContext?.saleReceiptData);
        setTimeout(() => {
            if (saleHistoryDetailsPrintRef) {
                saleHistoryDetailsPrintRef.current.refHandlePrint();
            }
        }, 100);
    }

    const pageHeader = <PageHeader
        title={
            <>
            </>
        }
    />;

    return (
        <PageWrapper pageHeader={pageHeader}>
            <div
                className="sale-invoice-a4"
            >
                <div className='button-section'>
                    <div className='button-child'>
                        <Button
                            className='print-button'
                            size="small"
                            shape="round"
                            icon={<PrinterOutlined/>}
                            onClick={() => getPDF()}
                        >
                            Print
                        </Button>

                        <Button
                            className='print-button'
                            size="small"
                            shape="round"
                            icon={<ShareAltOutlined/>}
                            onClick={openShareModal}
                        >
                            Share
                        </Button>

                        <div>
                        </div>
                    </div>
                </div>

                <PDFTableContent
                    pdfConfig={posSaleContext?.saleReceiptData}
                    isEmptyCell={false}
                />

                {
                    saleHistory ? <SaleInvoiceA4Print1
                        ref={saleHistoryDetailsPrintRef}
                        data={saleHistory}
                        pdfConfig={saleHistory}
                    /> : <></>
                }

                <Modal
                    title={"Share Your Property"}
                    visible={shareModal}
                    onCancel={closeShareModal}
                    width={390}
                    footer={null}
                >
                    <ShareModal
                        socialIconList={socialIconList}
                        copyLink={currentURL}
                    />
                </Modal>
            </div>
        </PageWrapper>
    );
};

export default PosSaleHistoryPrintA4;