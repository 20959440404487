import React from 'react';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Toast} from "./Toast";
import {CopyOutlined} from "@ant-design/icons";
const ShareModal = ({socialIconList, copyLink}) => {
    return (
        <div style={{display: "flex", flexWrap: "wrap", alignItems: "center", gap: "20px"}}>
            {
                socialIconList.map((socialIcon) => <div key={socialIcon.id}
                                                        style={{
                                                            height: "100px",
                                                            width: "100px",
                                                            background: "#ffffff",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }}
                >
                    <a
                        href={socialIcon.path}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {socialIcon.icon}
                    </a>

                </div>)
            }
            <CopyToClipboard
                text={copyLink}
                onCopy={() => Toast("success", "COPIED", "Link has been copied successfully")}
            >
                <div
                    style={{
                        height: "100px",
                        width: "100px",
                        background: "#ffffff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer"
                    }}
                >
                    <CopyOutlined style={{fontSize: "50px", color: "#FFCC00"}}/>
                </div>
            </CopyToClipboard>
        </div>
    );
};

export default ShareModal;