import React, {createContext, useState} from 'react';
import AdminCompanyService from "../../services/auth/AdminCompanyService";
import {Toast} from "../../components/common/Toast";
import {getErrorMessage} from "../../helpers/Utils";

export const AdminCompanyContext = createContext("AdminCompanyContext");


const AdminCompanyContextProvider = ({children}) => {

    const [adminCompanies, setAdminCompanies] = useState([]);
    const [adminCompany, setAdminCompany] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    async function getAllAdminCompany(params) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await AdminCompanyService.getAllAdminCompanyByShopId(params);
            setTotalElements(res.data.totalElements);
            setAdminCompanies(res.data.content);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            const message = getErrorMessage(error);
            setErrorMsg("Can not load Companies. Please check the console.");
            Toast("error", "Error", message);
            return false;
        }

    }


    async function getAdminCompanyById(id) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await AdminCompanyService.getAdminCompanyByShopIdById(id);
            setAdminCompany(res.data);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            const message = getErrorMessage(error);
            setErrorMsg("Can not load Company. Please check the console.");
            Toast("error", "Error", message);
            return false;
        }

    }

    async function addAdminCompany(data) {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await AdminCompanyService.createAdminCompanyByShopId(data)
            setAdminCompanies(adminCompanies ? [...adminCompanies, res.data] : [res.data]);
            setLoading(false);
            Toast("success", "Success", `Company added successfully!`);
            return true;
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            setErrorMsg("Can not add Companies. Please check the console.");
            Toast("error", "Error", message);
            return false;
        }

    }

    async function updateAdminCompany(DATA, id) {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await AdminCompanyService.updateAdminCompanyByIdByShopId(DATA, id);
            setAdminCompany(res.data);
            let updatedCompanies = adminCompanies;
            updatedCompanies = adminCompanies.map(e => {
                if (e.id === id) e = res.data;
                return e;
            })
            Toast("success", "Success", `Company updated successfully!`);

            setAdminCompanies(updatedCompanies);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            const message = getErrorMessage(error);
            setErrorMsg("Can not update Company. Please check the console.");
            Toast("error", "Error", message);
            return false;
        }

    }

    async function deleteAdminCompany(id) {

        try {
            setLoading(true);
            setErrorMsg('');
            await AdminCompanyService.deleteAdminCompanyById(id);
            // setAdminCompanies(adminCompanies.filter(category => category.id !== id));
            getAllAdminCompany();
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            const message = getErrorMessage(error);
            setErrorMsg("Can not delete Companies. Please check the console.");
            Toast("error", "Error", message);
            return false;
        }
    }


    return (
        <AdminCompanyContext.Provider
            value={{
                adminCompanies,
                adminCompany,
                loading,
                errorMsg,
                totalElements,
                getAllAdminCompany,
                updateAdminCompany,
                getAdminCompanyById,
                deleteAdminCompany,
                addAdminCompany
            }}
        >
            {children}
        </AdminCompanyContext.Provider>
    );
}

export default AdminCompanyContextProvider;
