import * as URL from "../helpers/Constants";
import axios from "axios";

export default class ShopOwnerService {
    static getAllShopOwners(page, size, name) {
        return axios.get(URL.GET_ALL_SHOP_OWNERS, {
            ...ShopOwnerService.getAuthHeader(),
            params: {page, size, name}
        });
    }

    static getProductsForShopOwner(page, size) {
        return axios.get(URL.GET_ALL_PRODUCTS_FOR_SHOP_OWNER, {
            ...ShopOwnerService.getAuthHeader(),
            params: {page, size}
        });
    }

    static addShopOwner(data) {
        return axios.post(URL.POST_SHOP_OWNER, data, ShopOwnerService.getAuthHeader());
    }

    static updateShopOwner(data, id) {
        return axios.put(`${URL.UPDATE_SHOP_OWNER}/${id}`, data, ShopOwnerService.getAuthHeader());
    }

    static deleteShopOwner(id) {
        return axios.delete(`${URL.DELETE_SHOP_OWNER}/${id}`, ShopOwnerService.getAuthHeader());
    }

    static getAllShopsForShopOwner(page, size) {
        return axios.get(URL.GET_ALL_SHOP_FOR_SHOP_OWNER, {
            ...ShopOwnerService.getAuthHeader(),
            params: {page, size}
        });
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }
}
