import React, {createContext, useState} from "react";
import Notification from "../components/common/Notification";
import POSVendorPaymentServices from "../services/POSVendorPaymentServices";

export const POSVendorPaymentContext = createContext("POSVendorPaymentContext");

const POSVendorPaymentContextProvider = ({children}) => {
    const [loading, setLoading] = useState(false);
    const [loadingCancelVendorPayment, setLoadingCancelVendorPayment] = useState(
        false
    );
    const [loadingCreateVendorPayment, setLoadingCreateVendorPayment] = useState(
        false
    );
    const [errorMsg, setErrorMsg] = useState("");

    const [totalElements, setTotalElements] = useState(0);
    const [payments, setPayments] = useState([]);

    const [amount, setAmount] = useState(0);

    const getPayments = async (id, params) => {
        try {
            setLoading(true);
            setErrorMsg("");
            const res = await POSVendorPaymentServices.getPayments(id, params);
            console.log('header', res.headers)
            setAmount(res.headers.paid);
            setPayments(res.data.content);
            // setTotalElements(res.data.totalElements);
            setTotalElements(res.headers?.count);
            setLoading(false);
            return false;
        } catch (error) {
            setLoading(false)
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const createPayment = async (data) => {
        try {
            setLoadingCreateVendorPayment(true)
            setErrorMsg("")
            await POSVendorPaymentServices.createPayment(data)
            Notification("success", "Success", "Payment added successfully.");
            setLoadingCreateVendorPayment(false)
            return true
        } catch (error) {
            setLoadingCreateVendorPayment(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false
        }
    }

    const cancelPayment = async (customerId, search, id) => {
        try {
            setLoadingCancelVendorPayment(true)
            setErrorMsg("")
            await POSVendorPaymentServices.cancelPayment(id)
            Notification("success", "Canceled", "Payment successfully canceled");
            setLoadingCancelVendorPayment(false)
            return true;
        } catch (error) {
            setLoadingCancelVendorPayment(false)
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false
        }
    }

    return (
        <POSVendorPaymentContext.Provider
            value={{
                loading,
                errorMsg,
                payments,
                totalElements,
                loadingCreateVendorPayment,
                getPayments,
                createPayment,
                cancelPayment,
                loadingCancelVendorPayment,
                amount
            }}
        >
            {children}
        </POSVendorPaymentContext.Provider>
    );
};

export default POSVendorPaymentContextProvider;
