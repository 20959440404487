import * as URL from "../helpers/Constants";
import axios from "axios";
import {getShopId} from "../helpers/Utils";

export default class POSTransactionAccountServices {
    static getTransactionAccounts(params) {
        return axios.get(
            `${URL.POS_GET_SHOP_TRANSACTION_ACCOUNTS}/${getShopId()}`,
            {
                ...POSTransactionAccountServices.getAuthHeader(),
                params,
            }
        );
    }

    static getTransactionAccountById(id) {
        return axios.get(
            `${URL.POS_GET_SHOP_TRANSACTION_ACCOUNTS}/${id}/${getShopId()}`,
            POSTransactionAccountServices.getAuthHeader()
        );
    }

    static createTransactionAccount(params) {
        return axios.post(
            `${URL.POS_CREATE_SHOP_TRANSACTION_ACCOUNT}/${getShopId()}`,
            params,
            POSTransactionAccountServices.getAuthHeader()
        );
    }

    static updateTransactionAccount(id, data) {
        return axios.put(
            `${URL.POS_UPDATE_SHOP_TRANSACTION_ACCOUNT}/${id}/${getShopId()}`,
            data,
            POSTransactionAccountServices.getAuthHeader()
        );
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        return {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        };
    }
}
