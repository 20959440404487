import React from 'react';
import './CancelPayment.scss'
import {Button, Result} from "antd";
import {DASHBOARD_PATH} from "../../../../routes/Slugs";
import {Link} from "react-router-dom";

const CancelPayment = () => {
    return (
        <div className="payment-cancellation-page">
            <Result
                status="error"
                title="Payment Cancelled"
                subTitle="Your payment has been cancelled."
                extra={[
                    <Link to={DASHBOARD_PATH}>
                        <Button type="primary" key="home">
                            Go Home
                        </Button>
                    </Link>

                ]}
            />
        </div>
    );
};

export default CancelPayment;