import React, {createContext, useState} from 'react';
import Notification from "../../components/common/Notification";
import SalesPersonService from "../../services/auth/SalesPersonService";

export const SalesPersonContext = createContext("SalesPersonContext");

const SalesPersonContextProvider = ({children}) => {

    const [salesPersons, setSalesPersons] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMsg] = useState("");
    const [salesPerson, setSalesPerson] = useState(null);
    const [profileLoading, setProfileLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);

    async function getAllSalesPersons({page, size, id, name, email}) {

        try {
            setLoading(true);
            const res = await SalesPersonService.getAllSalesPersons(page, size, id, name, email);
            setSalesPersons(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    async function getSalesPersonById(id) {

        try {
            setLoading(true);
            const res = await SalesPersonService.getSalesPersonById(id);
            setSalesPerson(res.data);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    async function addSalesPerson(data) {

        try {
            const res = await SalesPersonService.addSalesPersonFromAdmin(data);
            setSalesPerson(res.data);
            Notification("success", "Created", "Sales person added successfully!")
            return res.data;
        } catch (error) {
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return null;
        }

    }

    async function getSalesPersonProfile() {
        try {
            setProfileLoading(true);
            const res = await SalesPersonService.getProfile();
            setSalesPerson(res.data);
            setProfileLoading(false);
            return true;
        } catch (error) {
            setProfileLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    async function updateSalesPerson(data, id) {
        try {
            const res = await SalesPersonService.updateSalesPersonFromAdmin(data, id);
            setSalesPerson(res.data);
            Notification("success", "Updated", "Sales person updated successfully!");
            return res.data;
        } catch (error) {
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    async function deleteSalesPerson(id) {

        if (!window.confirm("Are you sure you want to delete this Sales Person ?")) return;

        try {
            await SalesPersonService.deleteSalesPerson(id);
            let updatedSalesPersons = salesPersons;
            updatedSalesPersons = salesPersons.filter(e => e.id !== id)
            setSalesPersons(updatedSalesPersons);
            Notification("success", "Deleted", "Sales person has been deleted successfully!");
            return true;
        } catch (error) {
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const changeSalesPersonStatus = async (email, status) => {

        try {
            await SalesPersonService.changeSalesPersonStatus(email, status);
            let updatedSalesPersons = salesPersons;
            updatedSalesPersons = salesPersons.map(e => {
                if (e.email === email) e.status = status;
                return e;
            })
            setSalesPersons(updatedSalesPersons);
            Notification("success", "Changed Status", "Sales person's status_payment has been changed!");
            return true;
        } catch (error) {
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }


    return (
        <SalesPersonContext.Provider
            value={{
                loading,
                errorMsg,
                totalElements,
                salesPersons,
                salesPerson,
                getSalesPersonById,
                getAllSalesPersons,
                profileLoading,
                updateSalesPerson,
                deleteSalesPerson,
                addSalesPerson,
                getSalesPersonProfile,
                changeSalesPersonStatus
            }}
        >
            {children}
        </SalesPersonContext.Provider>
    );
}

export default SalesPersonContextProvider;
