import * as URL from "../helpers/Constants";
import axios from "axios";
import {getShopId} from "../helpers/Utils";

export default class POSSalesPersonServices {
    static getAllSalesPersonsByShopId(params) {
        return axios.get(`${URL.POS_GET_ALL_SALES_PERSON}/${getShopId()}`, {
            ...POSSalesPersonServices.getAuthHeader(),
            params,
        });
    }

    static getSalesPersonById(id) {
        return axios.get(
            `${URL.POS_GET_SALES_PERSON_BY_ID}/${id}`,
            POSSalesPersonServices.getAuthHeader()
        );
    }

    static getSalesPersonRole() {
        return axios.get(
            `${URL.POS_GET_ALL_ROLES}/${getShopId()}`,
            POSSalesPersonServices.getAuthHeader()
        );
    }

    static createSalesPersonByShopId(params) {
        console.log(params);
        return axios.post(
            `${URL.POS_CREATE_SALES_PERSON}/${getShopId()}`,
            params,
            POSSalesPersonServices.getAuthHeader()
        );
    }

    static updateSalesPersonBySalesPersonId(id, data) {
        return axios.put(
            `${URL.POS_UPDATE_SALES_PERSON}/${id}`,
            data,
            POSSalesPersonServices.getAuthHeader()
        );
    }

    static deleteSalesPersonById(id) {
        return axios.delete(
            `${URL.POS_DELETE_SALES_PERSON}/${id}`,
            POSSalesPersonServices.getAuthHeader()
        );
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        //console.log("accessToken: ", accessToken)
        return {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        };
    }
}
