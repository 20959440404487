import axios from "axios";
import * as URL from "../helpers/Constants";

export default class POSReportService {

    static getPOSReportByShopId(id, params) {
        return axios.get(`${URL.POS_GET_REPORT_BY_SHOP_ID}/${id}`, {
            params, ...POSReportService.getAuthHeader()
        });
    }

    static generatePOSExcelReportByShopId(shopId, params) {

        return axios({
            url: `${URL.POS_GENERATE_EXCEL_REPORT_BY_SHOP_ID}/${shopId}`,
            params,
            method: 'GET',
            responseType: 'blob', // Important,
            ...POSReportService.getAuthHeaderForFileDownload()
        });
    }

    static generateBarcodeReportByShopId(id, shopId) {

        return axios({
            url: `${URL.GENERATE_PRODUCT_BAR_CODE_PATH}/${id}/${shopId}`,
            method: 'GET',
            responseType: 'blob', // Important,
            ...POSReportService.getAuthHeaderForFileDownload()
        });
    }

    static getPOSAccountBalanceReport(shopId) {
        return axios.get(`${URL.POS_GET_ACCOUNT_BALANCE_REPORT}/${shopId}`, POSReportService.getAuthHeader());
    }

    static getPMSDashboardTopData(shopId) {
        return axios.get(`${URL.GET_PMS_DASHBOARD_TOP_DATA}/${shopId}`, POSReportService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        return {headers: {"Authorization": `Bearer ${accessToken}`}};
    }

    static getAuthHeaderForFileDownload = () => {
        const accessToken = localStorage.getItem("ems_access_token");
        return {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                'Response-Type': 'blob'
            }
        };
    }
}