import axios from "axios";
import * as URL from "../helpers/Constants";

export default class POSProductRequisitionService {

    static getAllRequisition(shopId, params) {
        return axios.get(
            `${URL.POS_GET_ALL_REQUISITION_BY_SHOP_ID}/${shopId}`, {
                params, ...POSProductRequisitionService.getAuthHeader()
            }
        );
    }

    static getRequisition(requisitionId, shopId) {
        return axios.get(
            `${URL.POS_GET_REQUISITION_BY_SHOP_ID_BY_ID}/${requisitionId}/${shopId}`, POSProductRequisitionService.getAuthHeader()
        );
    }

    static createRequisition(shopId, data) {
        return axios.post(`${URL.POS_CREATE_REQUISITION}/${shopId}`, data,
            POSProductRequisitionService.getAuthHeader());
    }

    static updateRequisition(requisitionId, shopId, params, data) {
        return axios.put(`${URL.POS_UPDATE_REQUISITION_BY_SHOP_ID_BY_ID}/${requisitionId}/${shopId}`, data,
            {params, ...POSProductRequisitionService.getAuthHeader()})
    }

    static cancelRequisition(requisitionId, shopId) {
        return axios.put(`${URL.POS_CANCEL_REQUISITION_BY_SHOP_ID_BY_ID}/${requisitionId}/${shopId}`, null, POSProductRequisitionService.getAuthHeader())
    }

    static generateRequisitionPdf(shopId, requisitionId) {

    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        return {headers: {"Authorization": `Bearer ${accessToken}`}};
    }

}