import * as URL from "../helpers/Constants";
import axios from "axios";
import {getShopId} from "../helpers/Utils";

export default class POSStoreConfigService {
    static getPosStoreConfigById() {
        return axios.get(`${URL.GET_POS_ONLINE_STORE_CONFIG}/${getShopId()}`, POSStoreConfigService.getAuthHeader());
    }

    static updateStoreConfig(data) {
        return axios.put(`${URL.UPDATE_POS_ONLINE_STORE_CONFIG}/${getShopId()}`, data, POSStoreConfigService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        return {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        };
    }
}
