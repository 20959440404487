import React, {createContext, useState} from "react";
import {CURRENCY_DIGIT_KEY} from "../helpers/Constants";

export const POSShopContext = createContext("POSShopContext");

const POSShopContextProvider = ({children}) => {

    const p = JSON.parse(localStorage.getItem("selectedShop"));

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [selectedShop, setSelectedShop] = useState(p ? p : null);

    const addSelectedShop = async (data) => {
        try {
            setLoading(true);
            setErrorMsg('');
            localStorage.setItem("selectedShop", JSON.stringify(data));

            localStorage.setItem(CURRENCY_DIGIT_KEY, data.roundValueUpTo && data.roundValueUpTo);

            setSelectedShop(data);
            setLoading(false);

            // return true;
            return data;
        } catch (e) {
            setLoading(false);
            console.log(e);
            return false;
        }
    }

    return (
        <POSShopContext.Provider
            value={{
                loading,
                errorMsg,
                selectedShop,
                addSelectedShop,
                setSelectedShop
            }}
        >
            {children}
        </POSShopContext.Provider>
    )
}

export default POSShopContextProvider;