import axios from "axios";
import * as URL from "../helpers/Constants";

export default class POSReconciliationService {

    static getAllReconciliationsByShopId(shopId, params) {
        return axios.get(`${URL.POS_GET_ALL_RECONCILIATION}/${shopId}`, {
            ...POSReconciliationService.getAuthHeader(),
            params
        });
    }

    static createReconciliation(shopId, data) {
        return axios.post(`${URL.POS_CREATE_RECONCILIATION}/${shopId}`, data, POSReconciliationService.getAuthHeader());
    }

    static cancelReconciliation(id, shopId) {
        return axios.put(`${URL.POS_CANCEL_RECONCILIATION}/${id}/${shopId}`, null, POSReconciliationService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        return {headers: {Authorization: `Bearer ${accessToken}`}};
    }
}
