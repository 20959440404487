import React, {createContext, useState} from 'react';
import Notification from "../../components/common/Notification";
import SubscriptionPlanService from "../../services/auth/SubscriptionPlanService";

export const SubscriptionPlanContext = createContext("SubscriptionPlanContext");

const SubscriptionPlanContextProvider = ({children}) => {

    const [subscriptionPlans, setSubscriptionPlans] = useState(null);
    const [subscriptionPlan, setSubscriptionPlan] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    async function getAllSubscriptionPlans() {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await SubscriptionPlanService.getAllSubscriptionPlans();
            setSubscriptionPlans(res.data.content)
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            setErrorMsg("Can not load SubscriptionPlans. Please check the console.");
            return false;
        }

    }

    async function addSubscriptionPlan(data) {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await SubscriptionPlanService.addSubscriptionPlan(data)
            setSubscriptionPlan(res.data)
            Notification("success", "SUCCESS", `Subscription Plan Id - ${res.data.id} saved successfully.`);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "Can not save Subscription Plan", `${_error}`);
            return false;
        }

    }

    async function updateSubscriptionPlan(DATA, id) {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await SubscriptionPlanService.updateSubscriptionPlan(DATA, id);
            setSubscriptionPlan(res.data);
            Notification('success', "SUCCESS", `Subscription Plan Id - ${res.data.id} updated successfully. `);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "Can not update Subscription Plan", `${_error}`);
            return false;
        }

    }

    async function deleteSubscriptionPlan(id) {

        if (!window.confirm("Are you sure you want to delete this SubscriptionPlan ?")) return;

        try {
            setLoading(true);
            setErrorMsg('');
            await SubscriptionPlanService.deleteSubscriptionPlan(id);
            let updatedSubscriptionPlans = subscriptionPlans;
            updatedSubscriptionPlans = subscriptionPlans.filter(e => e.id !== id);
            setSubscriptionPlans(updatedSubscriptionPlans);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "Can not delete Subscription Plan", `${_error}`);
            return false;
        }
    }


    async function getSubscriptionPlanById(id) {
        try {
            setLoading(true);
            const res = await SubscriptionPlanService.getSubscriptionPlanById(id);
            setSubscriptionPlan(res.data);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false)
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "Can not save Subscription Plan", `${_error}`);
        }
    }


    return (
        <SubscriptionPlanContext.Provider
            value={{
                subscriptionPlans,
                subscriptionPlan,
                loading,
                errorMsg,
                getAllSubscriptionPlans,
                updateSubscriptionPlan,
                deleteSubscriptionPlan,
                getSubscriptionPlanById,
                addSubscriptionPlan
            }}
        >
            {children}
        </SubscriptionPlanContext.Provider>
    );
}

export default SubscriptionPlanContextProvider;