import React, {createContext, useState} from 'react';
import DashboardService from "./DashboardService";
// import {NotificationContext} from "./NotificationContextProvider";

export const DashboardContext = createContext("DashboardContext");

const DashboardContextProvider = ({children}) => {

    // const notificationContext = useContext(NotificationContext);

    const [loading, setLoading] = useState(false);

    const [activeShops, setActiveShops] = useState([]);
    const [activeShopCount, setActiveShopCount] = useState(0);
    const [totalCustomersCount, setTotalCustomersCount] = useState(0);
    const [pendingOrders, setPendingOrders] = useState(0);
    const [activeDeliveryPersons, setActiveDeliveryPersons] = useState(0);

    // async function getAllNotifications(page, size) {
    //
    //     return notificationContext.getAllNotifications(page, size);
    //
    // }

    async function getAllActiveShops(page, size) {

        try {
            setLoading(true);
            const res = await DashboardService.getAllActiveShops(page, size);
            setActiveShops(res.data)
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            return false;
        }

    }

    async function getActiveShopCount() {

        try {
            setLoading(true);
            const res = await DashboardService.getActiveShopCount();
            setActiveShopCount(res.data);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            return false;
        }

    }

    const getAllCustomerCount = async () => {

        try {
            setLoading(true);
            const res = await DashboardService.getAllCustomerCount();
            setTotalCustomersCount(res.data);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            return false;
        }

    }

    const getAllPendingOrdersCount = async () => {
        try {
            setLoading(true);
            const res = await DashboardService.getAllPendingOrdersCount();
            setPendingOrders(res.data);
            return res.data;
        } catch (e) {
            setLoading(false);
            return 0;
        }
    }

    const getAllActiveDeliveryPersonCount = async () => {
        try {
            setLoading(true);
            const res = await DashboardService.getAllActiveDeliveryPersonCount();
            setActiveDeliveryPersons(res.data);
            return res.data;
        } catch (e) {
            setLoading(false);
            return 0;
        }
    }

    return (
        <DashboardContext.Provider
            value={{
                loading,
                activeShops,
                activeShopCount,
                totalCustomersCount,
                pendingOrders,
                activeDeliveryPersons,
                getAllActiveShops,
                getActiveShopCount,
                // getAllNotifications,
                getAllCustomerCount,
                getAllPendingOrdersCount,
                getAllActiveDeliveryPersonCount
            }}
        >
            {children}
        </DashboardContext.Provider>
    );
}

export default DashboardContextProvider;
