import * as URL from "../helpers/Constants";
import axios from "axios";
import {getShopId} from "../helpers/Utils";

export default class POSCustomerPaymentServices {
    static getPayments(id, params) {
        return axios.get(`${URL.POS_GET_ALL_CUSTOMER_PAYMENT_BY_SHOP_ID}/${getShopId()}/${id}`,
            {...POSCustomerPaymentServices.getAuthHeader(), params}
        )
    }

    static createPayment(data) {
        return axios.post(`${URL.POS_CREATE_CUSTOMER_PAYMENT_BY_SHOP_ID}/${getShopId()}`,
            data,
            POSCustomerPaymentServices.getAuthHeader()
        )
    }

    static cancelPayment(id) {
        return axios.put(`${URL.POS_CANCEL_CUSTOMER_PAYMENT_BY_ID_BY_SHOP_ID}/${id}/${getShopId()}`, null,
            POSCustomerPaymentServices.getAuthHeader()
        )
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        return {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        };
    }
}
