import React, {createContext, useState} from "react";
import {generateLocalStorageKey, getErrorMessage} from "../helpers/Utils";
import Notification from "../components/common/Notification";
import {Toast} from "../components/common/Toast";
import POSConfigService from "../services/POSConfigService";
import {CURRENCY_KEY} from "../helpers/Constants";

export const POSConfigurationContext = createContext("POSConfigurationContext");

const POSConfigurationContextProvider = ({children}) => {

    const _isPOSPDF = JSON.parse(localStorage.getItem(generateLocalStorageKey("invoice")));
    const _printingConfig = JSON.parse(localStorage.getItem("printing_config"));


    const [loading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [isPOSPDF, setIsPOSPDF] = useState(_isPOSPDF ?? true);
    const [printingConfig, setPrintingConfig] = useState(_printingConfig ?? null);

    const [updateLoadingShopSetting, setUpdateLoadingShopSetting] = useState(false);
    const [shopSetting, setShopSetting] = useState(null);
    const [orderConfig, setOrderConfig] = useState(null);


    const selectPDFType = data => {
        setLoading(true);
        localStorage.setItem(generateLocalStorageKey("invoice"), JSON.stringify(data.isPOSPDF));
        setIsPOSPDF(data.isPOSPDF);
        if (data.isPOSPDF) {
            Notification("success", "POS PDF", "Now you can print pos invoice");
        } else {
            Notification("success", "A4 PDF", "Now you can print A4 invoice");
        }
        setLoading(false);
    }

    const addPrintingConfig = async (id, data) => {
        try {
            setUpdateLoading(true);
            const res = await POSConfigService.updatePrintingConfig(id, data);
            localStorage.setItem("printing_config", JSON.stringify(data));
            setPrintingConfig(data);
            Notification("success", "Updated", "Printing Config has been updated successfully")
            setUpdateLoading(false);
            return res.data;
            // return true;
        } catch (error) {
            setUpdateLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }
    }

    const getPrintingConfigByShopId = async id => {
        try {
            setLoading(true);
            const res = await POSConfigService.getPrintingConfigByShopId(id);
            localStorage.setItem("printing_config", JSON.stringify(res.data));
            localStorage.setItem(CURRENCY_KEY, res.data.currency ? res.data.currency : "");
            // localStorage.setItem(CURRENCY_DIGIT_KEY, res.data.roundValueUpTo ? res.data.roundValueUpTo.value : 2);
            setPrintingConfig(res.data);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }
    }

    const addShopSetting = async (id, data) => {
        try {
            setUpdateLoadingShopSetting(true);
            await POSConfigService.updateShopSetting(id, data);
            // setShopSetting(data);
            Notification("success", "Updated", "Shop setting has been updated successfully")
            setUpdateLoadingShopSetting(false);
            return true;
        } catch (error) {
            setUpdateLoadingShopSetting(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }
    }

    const getShopSettingById = async id => {
        try {
            setUpdateLoadingShopSetting(true);
            const res = await POSConfigService.getShopSettingByShopId(id);
            setShopSetting(res.data);
            setUpdateLoadingShopSetting(false);
            return true;
        } catch (error) {
            setUpdateLoadingShopSetting(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }
    }

    const getOrderConfigById = async id => {
        try {
            setUpdateLoadingShopSetting(true);
            const res = await POSConfigService.getOrderConfigById(id);
            setOrderConfig(res.data);
            setUpdateLoadingShopSetting(false);
            return true;
        } catch (error) {
            setUpdateLoadingShopSetting(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }
    }

    const addOrderSetting = async (id, data) => {
        try {
            setUpdateLoadingShopSetting(true);
            await POSConfigService.updateOrderSetting(id, data);
            // setShopSetting(data);
            Notification("success", "Updated", "Order Setting has been updated successfully")
            setUpdateLoadingShopSetting(false);
            return true;
        } catch (error) {
            setUpdateLoadingShopSetting(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }
    }

    return (
        <POSConfigurationContext.Provider
            value={{
                loading,
                updateLoading,
                isPOSPDF,
                selectPDFType,
                printingConfig,
                getPrintingConfigByShopId,
                addPrintingConfig,
                updateLoadingShopSetting,
                shopSetting,
                addShopSetting,
                getShopSettingById,
                addOrderSetting,
                orderConfig,
                getOrderConfigById
            }}
        >
            {children}
        </POSConfigurationContext.Provider>
    )
}

export default POSConfigurationContextProvider;