import axios from "axios";
import * as URL from "../helpers/Constants";

export default class POSExpenseServices {


    static getAllExpensesByShopId(params) {
        return axios.get(`${URL.POS_GET_ALL_EXPENSE_BY_SHOP_ID}/${params.id}`, {
            params, ...POSExpenseServices.getAuthHeader()
        })
    }

    static getExpenseByShopIdById(shopId, id) {
        return axios.get(`${URL.POS_GET_EXPENSE_BY_SHOP_ID_BY_ID}/${shopId}/${id}`, POSExpenseServices.getAuthHeader())
    }

    static createExpenseByShopId(id, data) {
        return axios.post(`${URL.POS_CREATE_EXPENSE_BY_SHOP_ID}/${id}`, data, POSExpenseServices.getAuthHeader())
    }

    static updateExpenseByIdByShopId(id, shopId, data) {
        return axios.put(`${URL.POS_UPDATE_EXPENSE_BY_ID_SHOP_ID}/${id}/${shopId}`, data, POSExpenseServices.getAuthHeader())
    }


    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        return {headers: {"Authorization": `Bearer ${accessToken}`}};
    }

}