import axios from "axios";
import * as URL from "../../helpers/Constants";

export default class ProductTypeService {
    static getAllProductType(params) {
        return axios.get(`${URL.GET_ALL_PRODUCT_TYPE_URL}`, {
            ...ProductTypeService.getAuthHeader(),
            params
        })
    }

    static createProductType(data) {
        return axios.post(`${URL.ADD_PRODUCT_TYPE_URL_URL}`, data, ProductTypeService.getAuthHeader())
    }

    static getProductTypeById(id) {
        return axios.get(`${URL.GET_PRODUCT_TYPE_BY_ID_URL}/${id}`, ProductTypeService.getAuthHeader())
    }

    static UpdateProductTypeById(id, data) {
        return axios.put(`${URL.UPDATE_PRODUCT_TYPE_URL}/${id}`, data, ProductTypeService.getAuthHeader())
    }

    static deleteProductTypeById(id) {
        return axios.delete(`${URL.DELETE_PRODUCT_TYPE_URL}/${id}`, ProductTypeService.getAuthHeader())
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        return {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        };
    }
}