import React, {createContext, useState} from 'react';
import Notification from "../../components/common/Notification";
import GlobalConfigService from "../../services/auth/GlobalConfigService";

export const GlobalConfigContext = createContext("GlobalConfigContext");

const GlobalConfigContextProvider = ({children}) => {

    const [globalConfigs, setGlobalConfigs] = useState(null);
    const [globalConfig, setGlobalConfig] = useState();
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState('');

    async function getAllGlobalConfigs({page, size, id}) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await GlobalConfigService.getAllGlobalConfigs(page, size, id);
            setGlobalConfigs(res.data.content)
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    async function getAllActiveGlobalConfigs() {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await GlobalConfigService.getAllActiveGlobalConfigs();
            setGlobalConfigs(res.data.content)
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    async function getGlobalConfigById(id) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await GlobalConfigService.getGlobalConfigById(id);
            setGlobalConfig(res.data);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    async function addGlobalConfig(data) {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await GlobalConfigService.addGlobalConfig(data)
            setGlobalConfig(res.data);
            setLoading(false);
            Notification("success", "CREATED", "GlobalConfig saved successfully.");
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    async function updateGlobalConfig(data, id) {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await GlobalConfigService.updateGlobalConfig(data, id);

            setGlobalConfig(res.data);
            Notification("success", "UPDATED", "GlobalConfig updated successfully.");
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    async function deleteGlobalConfig(id) {

        if (!window.confirm("Are you sure you want to delete this GlobalConfig ?")) return;

        try {
            setLoading(true);
            setErrorMsg('');
            await GlobalConfigService.deleteGlobalConfig(id);
            let updatedGlobalConfigs = globalConfigs;
            updatedGlobalConfigs = globalConfigs.filter(e => e.id !== id);
            setGlobalConfigs(updatedGlobalConfigs);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }


    return (
        <GlobalConfigContext.Provider
            value={{
                globalConfigs,
                globalConfig,
                getGlobalConfigById,
                loading,
                errorMsg,
                totalElements,
                getAllGlobalConfigs,
                getAllActiveGlobalConfigs,
                updateGlobalConfig,
                deleteGlobalConfig,
                addGlobalConfig
            }}
        >
            {children}
        </GlobalConfigContext.Provider>
    );
}

export default GlobalConfigContextProvider;