import axios from "axios";
import * as URL from "../../helpers/Constants";


export default class AdminCompanyService {

    static getAllAdminCompanyByShopId(params) {
        return axios.get(`${URL.GET_ALL_ADMIN_COMPANIES}`, {
            ...AdminCompanyService.getAuthHeader(),
            params
        })
    }

    static createAdminCompanyByShopId(data) {

        return axios.post(`${URL.POST_ADMIN_COMPANY}`, data, AdminCompanyService.getAuthHeader())
    }

    static getAdminCompanyByShopIdById(id) {
        return axios.get(`${URL.GET_ADMIN_COMPANY_BY_ID}/${id}`, AdminCompanyService.getAuthHeader())
    }

    static updateAdminCompanyByIdByShopId(id, data) {
        return axios.put(`${URL.UPDATE_ADMIN_COMPANY}/${id}`, data, AdminCompanyService.getAuthHeader())
    }

    static deleteAdminCompanyById(id) {
        return axios.delete(`${URL.DELETE_ADMIN_COMPANY}/${id}`, AdminCompanyService.getAuthHeader())
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        return {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        };
    }
}