import React, {createContext, useState} from 'react';
import Notification from "../../components/common/Notification";
import TransactionService from "../../services/auth/TransactionService";
import {getErrorMessage} from "../../helpers/Utils";
import {Toast} from "../../components/common/Toast";

export const TransactionContext = createContext("TransactionContext");

const OnlinePaymentHistoryContextProvider = ({children}) => {
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [totalElements, setTotalElements] = useState(0);

    // Transaction charge
    const [paymentHistories, setOnlinePaymentHistory] = useState([]);

    const paymentRefund = async (id, shopId, params) => {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await TransactionService.paymentRefund(id, shopId, params)
            console.log('res', res.data)
            setLoading(false);
            Toast("success", "Success", `Successfully refund payment!`);
            return true;
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            setErrorMsg("Can not refund payment");
            Toast("error", "Error", message);
            return false;
        }
    }

    const getOnlinePaymentHistory = async () => {

        try {
            setLoading(true);
            setErrorMsg("");

            const res = await TransactionService.getOnlinePaymentHistory();
            setOnlinePaymentHistory(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    return (
        <TransactionContext.Provider
            value={{
                loading,
                errorMsg,
                totalElements,
                paymentHistories,
                getOnlinePaymentHistory,
                paymentRefund
            }}
        >
            {children}
        </TransactionContext.Provider>
    )
}

export default OnlinePaymentHistoryContextProvider;