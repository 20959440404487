import * as URL from "../helpers/Constants";
import axios from "axios";

export default class OrderService {
    static getOrderList(params, shopId) {
        return axios.get(`${URL.GET_ORDERS_BY_SHOP_BY_ID}/${shopId}`, {...OrderService.getAuthHeader(), params});
    }

    static getOrderById(shopId, id) {
        return axios.get(`${URL.GET_ORDERS_BY_ID}/${id}/${shopId}`, OrderService.getAuthHeader());
    }

    static updateOrderStatus(params, shopId) {
        return axios.put(`${URL.UPDATE_ORDER_STATUS}/${params.id}/${shopId}?status=${params.status}`, {}, OrderService.getAuthHeader());

    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }
}