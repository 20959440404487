import React, {createContext, useState} from 'react';
import Notification from "../components/common/Notification";
import POSCategoryServices from "../services/POSCategoryServices";

export const POSCategoryContext = createContext("POSCategoryContext");
const PosCategoryContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [totalElements, setTotalElements] = useState(0);
    const [categories, setCategories] = useState(null);
    const [category, setCategory] = useState(null);

    const getCategoryListByShopId = async (id, params) => {

        try {
            setLoading(true);
            setErrorMsg("");

            const res = await POSCategoryServices.getAllCategoryByShopId(id, params);


            setCategories(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const createCategoryByShopId = async (id, data) => {

        try {
            setLoading(true);
            setErrorMsg("");

            await POSCategoryServices.createCategoryByShopId(id, data);

            Notification("success", "Created", "Category created successfully!");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getCategoryByShopIdById = async (shopId, id) => {
        try {
            setLoading(true);
            setErrorMsg("");
            const res = await POSCategoryServices.getCategoryByShopIdById(shopId, id);
            setCategory(res.data);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const updateCategoryByIdByShopId = async (id, shopId, data) => {

        try {
            setLoading(true);
            setErrorMsg("");

            await POSCategoryServices.updateCategoryByIdByShopId(id, shopId, data);

            Notification("success", "Updated", "Category updated successfully!");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }


    const deleteCategoryById = async (id, shopId) => {

        try {
            setLoading(true);
            setErrorMsg("");

            await POSCategoryServices.deleteCategoryById(id, shopId);

            setCategories(categories.filter(category => category.id !== id));

            Notification("success", "Deleted", "Category has been deleted successfully!");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }



    return (
        <POSCategoryContext.Provider
            value={{
                loading,
                errorMsg,
                totalElements,
                categories,
                category,
                deleteCategoryById,
                getCategoryListByShopId,
                createCategoryByShopId,
                getCategoryByShopIdById,
                updateCategoryByIdByShopId,
            }}
        >
            {children}
        </POSCategoryContext.Provider>
    )
}

export default PosCategoryContextProvider;