import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../../helpers/Utils";
import {Toast} from "../../components/common/Toast";
import OrderService from "../../services/OrderService";

export const POSOrderContext = createContext("POSOrderContext");
const PosOrderContextProvider = ({children}) => {


    const [loading, setLoading] = useState(false);
    const [orderList, setOrderList] = useState(null);
    const [order, setOrder] = useState(null);
    const [totalElements, setTotalElements] = useState(0);


    const getOrderList = async (data, id) => {
        try {
            setLoading(true);
            const res = await OrderService.getOrderList(data, id);
            setOrderList(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }
    }

    const getOrderById = async (shopId, id) => {
        try {
            setLoading(true);
            const res = await OrderService.getOrderById(shopId, id);
            setOrder(res.data);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }
    }

    const updatePaymentStatus = async (data, shopId) => {
        try {
            setLoading(true);
            const res = await OrderService.updateOrderStatus(data, shopId);
            if (res) {
                Toast("success", "Status Changed", "Order Status has been changed successfully");
            }
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }
    }


    return (
        <POSOrderContext.Provider
            value={{
                getOrderList,
                loading,
                orderList,
                order,
                getOrderById,
                totalElements,
                updatePaymentStatus
            }}
        >
            {children}
        </POSOrderContext.Provider>
    )
}

export default PosOrderContextProvider;