import * as URL from "../helpers/Constants";
import axios from "axios";

export default class POSVendorServices {

    static getAllVendorsByShopId(id, params) {
        return axios.get(`${URL.POS_GET_ALL_VENDOR_BY_SHOP_ID}/${id}`, {
            ...POSVendorServices.getAuthHeader(),
            params
        })
    }

    static getAllLiteVendorsByShopId(page, size, id) {
        return axios.get(`${URL.POS_GET_ALL_LITE_VENDOR_BY_SHOP_ID}/${id}`, {
            ...POSVendorServices.getAuthHeader(),
            params: {page, size}
        })
    }

    static getVendorByShopIdById(shopId, id) {
        return axios.get(`${URL.POS_GET_VENDOR_BY_SHOP_ID_BY_ID}/${shopId}/${id}`, POSVendorServices.getAuthHeader())
    }

    static getVendorByShopIdByLocalId(shopId, localId) {
        return axios.get(`${URL.POS_GET_VENDOR_BY_SHOP_ID_BY_LOCAL_ID}/${shopId}/${localId}`, POSVendorServices.getAuthHeader())
    }

    static createVendorByShopId(id, data) {
        return axios.post(`${URL.POS_CREATE_VENDOR_BY_SHOP_ID}/${id}`, data, POSVendorServices.getAuthHeader())
    }

    static updateVendorByIdByShopId(id, shopId, data) {
        return axios.put(`${URL.POS_UPDATE_VENDOR_BY_ID_SHOP_ID}/${id}/${shopId}`, data, POSVendorServices.getAuthHeader())
    }

    static deleteVendorById(id) {
        return axios.delete(`${URL.POS_DELETE_VENDOR_BY_ID}/${id}`, POSVendorServices.getAuthHeader())
    }


    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        return {headers: {"Authorization": `Bearer ${accessToken}`}};
    }

}