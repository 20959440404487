import * as URL from "../helpers/Constants";
import axios from "axios";
import {getShopId} from "../helpers/Utils";

export default class POSExpiredItemsServices {
    static getExpiredItemsByShopId(params) {
        return axios.get(`${URL.POS_GET_EXPIRED_ITEMS}/${getShopId()}`, {
            ...POSExpiredItemsServices.getAuthHeader(),
            params,
        });
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        //console.log("accessToken: ", accessToken)
        return {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        };
    }
}
