import React, {createContext, useState} from 'react';
import {Locales} from "../i18n";
import {LANGUAGE} from "../helpers/Constants";

export const GlobalContext = createContext("GlobalContext");

const lang = localStorage.getItem(LANGUAGE);

const GlobalContextProvider = ({children}) => {

    const [screenWidth, setScreenWidth] = useState(0);
    const [locale, setLocale] = useState(lang ? lang : Locales.english);

    const changeLocale = locale => {
        setLocale(Locales[locale]);
        localStorage.setItem(LANGUAGE, Locales[locale]);

        window.location.reload();
    }

    return (
        <GlobalContext.Provider
            value={{
                screenWidth,
                setScreenWidth,
                locale,
                changeLocale,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
}

export default GlobalContextProvider;
