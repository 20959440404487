import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {GlobalContext} from "../../context/GlobalContextProvider";

const PageWrapper = ({pageHeader, children, title}) => {

    const globalContext = useContext(GlobalContext);

    return (
        <>
            <div className="page-header">
                {pageHeader}
            </div>
            <div
                className="page-content"
                style={globalContext.screenWidth < 768 ? {padding: "15px"} : {}}
            >
                {children}
            </div>
        </>
    );
}


PageWrapper.propTypes = {
    children: PropTypes.element.isRequired
}

export default PageWrapper;
