import React, {createContext, useState} from "react";
import Notification from "../components/common/Notification";
import POSCustomerServices from "../services/POSCustomerServices";

export const POSCustomerContext = createContext("POSCustomerContext");

const POSCustomerContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [totalElements, setTotalElements] = useState(0);
    const [customers, setCustomers] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [customerReceipts, setCustomerReceipts] = useState(null);
    const [localId, setLocalId] = useState(null)
    const [customerDueReport, setCustomerDueReport] = useState({
        totalDueCustomer: 0,
        totalDue: 0
    });

    const getAllCustomersByShopId = async (id, params) => {

        try {
            setLoading(true);
            setErrorMsg("");

            const res = await POSCustomerServices.getAllCustomersByShopId(id, params);

            setCustomerDueReport({
                totalDueCustomer: res.headers.count,
                totalDue: res.headers.due
            });

            setCustomers(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getAllLiteCustomersByShopId = async ({page, size, id}) => {

        try {
            setLoading(true);
            setErrorMsg("");

            const res = await POSCustomerServices.getAllLiteCustomersByShopId(page, size, id);

            setCustomers(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getCustomerByShopIdById = async (shopId, id) => {
        try {
            setLoading(true);
            setErrorMsg("");

            const res = await POSCustomerServices.getCustomerByShopIdById(shopId, id);
            // if (!res.data.phoneNumber.startsWith("+88")) {
            //     res.data.phoneNumber = `+88${res.data.phoneNumber}`
            // }
            setLocalId(res.data.localId);

            setCustomer(res.data);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getCustomerReceiptByShopIdByLocalId = async (shopId, id) => {
        try {
            setLoading(true);
            setErrorMsg("");

            const res = await POSCustomerServices.getCustomerReceiptByShopIdLocalId(shopId, id);

            setCustomerReceipts(res.data);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }

            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const createCustomerByShopId = async (id, data) => {

        try {
            setLoading(true);
            setErrorMsg("");

            await POSCustomerServices.createCustomerByShopId(id, data);

            Notification("success", "Created", "Customer created successfully!");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const updateCustomerByIdByShopId = async (id, shopId, data) => {

        try {
            setLoading(true);
            setErrorMsg("");

            await POSCustomerServices.updateCustomerByIdByShopId(id, shopId, data);

            Notification("success", "Updated", "Customer updated successfully!");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const deleteCustomerById = async (id) => {

        try {
            setLoading(true);
            setErrorMsg("");

            await POSCustomerServices.deleteCustomerById(id);

            setCustomers(customers.filter(customer => customer.id !== id));

            Notification("success", "Deleted", "Customer has been deleted successfully!");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    return (
        <POSCustomerContext.Provider
            value={{
                loading,
                errorMsg,
                totalElements,
                customers,
                customer,
                customerDueReport,
                localId,
                getAllCustomersByShopId,
                getAllLiteCustomersByShopId,
                getCustomerByShopIdById,
                createCustomerByShopId,
                updateCustomerByIdByShopId,
                deleteCustomerById,
                getCustomerReceiptByShopIdByLocalId,
                customerReceipts
            }}
        >
            {children}
        </POSCustomerContext.Provider>
    )
}

export default POSCustomerContextProvider;