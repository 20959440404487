import React, {createContext, useState} from "react";
import Notification from "../components/common/Notification";
import {generateLocalStorageKey, reverseQuantityByUnitType} from "../helpers/Utils";
import POSProductRequisitionService from "../services/POSProductRequisitionService";

export const POSRequisitionContext = createContext("ProductRequisitionContext");

const POSRequisitionsContextProvider = ({children}) => {

    const initRequisition = {
        status: "PENDING",
        requisitionDetailsList: [],
        totalPrice: 0
    }

    const localStoredRequisition = JSON.parse(localStorage.getItem(generateLocalStorageKey("requisition")));

    const [requisition, setRequisition] = useState(
        localStoredRequisition
            ? localStoredRequisition
            : initRequisition
    );

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [totalElements, setTotalElements] = useState(0);
    const [requisitionList, setRequisitionList] = useState([]);
    const [editedRequisition, setEditedRequisition] = useState({
        requisitionDetailsList: []
    });
    const [requisitionReport, setRequisitionReport] = useState({totalPurchasePrice: 0, totalRequisition: 0});

    const refreshRequisition = () => {
        const _data = localStorage.getItem(generateLocalStorageKey("requisition"));
        setRequisition(_data ? JSON.parse(_data) : initRequisition);
    }

    const getAllRequisition = async (id, params) => {

        try {

            setLoading(true);
            setErrorMsg("");

            const res = await POSProductRequisitionService.getAllRequisition(id, params);

            setRequisitionReport({totalPurchasePrice: res.headers.total, totalRequisition: res.headers.count});
            setRequisitionList(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);

            return res.data.content;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const getRequisition = async (requisitionId, shopId) => {

        try {

            setLoading(true);
            setErrorMsg("");

            const res = await POSProductRequisitionService.getRequisition(requisitionId, shopId);

            const _data = res.data;

            _data.requisitionDetailsList.map(v => {
                v.showQuantity = reverseQuantityByUnitType(v.unitType, v.quantity);
                return v;
            });

            setEditedRequisition(_data);
            setLoading(false);

            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const createRequisition = async (shopId, data) => {
        try {
            setLoading(true);

            await POSProductRequisitionService.createRequisition(shopId, data);
            localStorage.removeItem(generateLocalStorageKey("requisition"));
            setRequisition({
                status: "",
                requisitionDetailsList: [],
                _totalPrice: 0
            })

            Notification("success", "Requisition Created", "Requisition created successfully");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const addRequisition = data => {

        const index = requisition.requisitionDetailsList.findIndex(v => v.product.id === data.product.id);

        if (index < 0) {
            requisition.requisitionDetailsList.push(data);

            let sum = 0;
            requisition.requisitionDetailsList.forEach(value => sum += (value.product.productPrice * value.quantity));

            requisition.totalPrice = Math.round(sum);

            const _requisition = {...requisition}

            localStorage.setItem(generateLocalStorageKey("requisition"), JSON.stringify(_requisition));
            setRequisition(_requisition);
        } else {
            requisition.requisitionDetailsList[index] = data;

            let sum = 0;
            requisition.requisitionDetailsList.forEach(value => sum += (value.product.productPrice * value.quantity));

            requisition.totalPrice = Math.round(sum);

            const _requisition = {...requisition};

            localStorage.setItem(generateLocalStorageKey("requisition"), JSON.stringify(_requisition));
            setRequisition(_requisition);
        }
    }

    const updateRequisition = (data, index) => {

        requisition.requisitionDetailsList[index] = data;

        let sum = 0;
        requisition.requisitionDetailsList.forEach(value => sum += (value.productPrice * value.quantity));

        requisition.totalPrice = Math.round(sum);

        const _requisition = {...requisition};

        localStorage.setItem(generateLocalStorageKey("requisition"), JSON.stringify(_requisition));
        setRequisition(_requisition);

    }

    const updateRequisitionByKey = (key, value, operation) => {

        if (operation) {
            editedRequisition[key] = value;
            setEditedRequisition({...editedRequisition});
        } else {
            requisition[key] = value;
            localStorage.setItem(generateLocalStorageKey("requisition"), JSON.stringify(requisition));
            setRequisition({...requisition});
        }
    }

    const deleteRequisition = (index) => {

        requisition.requisitionDetailsList.splice(index, 1);

        let sum = 0;
        requisition.requisitionDetailsList.forEach(value => sum += (value.productPrice * value.quantity));

        const _requisition = {...requisition, totalPrice: sum};

        if (requisition.requisitionDetailsList.length > 0) {
            localStorage.setItem(generateLocalStorageKey("requisition"), JSON.stringify(_requisition));
            setRequisition(_requisition);
        } else {
            localStorage.removeItem(generateLocalStorageKey("requisition"));
            setRequisition({...initRequisition});
        }
    }

    const cancelRequisition = async (requisitionId, shopId) => {
        try {
            setLoading(true);

            await POSProductRequisitionService.cancelRequisition(requisitionId, shopId);

            Notification("success", "CANCELED", "Requisition canceled successfully");
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    // Requisition Record
    const editRequisitionRecord = async (requisitionId, shopId, params, data) => {
        try {
            setLoading(true);
            await POSProductRequisitionService.updateRequisition(requisitionId, shopId, params, data);

            Notification("success", "Requisition Updated", "Requisition updated successfully");

            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const addRequisitionRecord = data => {

        const index = editedRequisition.requisitionDetailsList.findIndex(v => v.product.id === data.product.id);

        if (index < 0) {
            editedRequisition.requisitionDetailsList.push(data);

            let sum = 0;
            editedRequisition.requisitionDetailsList.forEach(value => sum += (value.productPrice * value.quantity));

            editedRequisition.totalPrice = Math.round(sum);

            setEditedRequisition(editedRequisition);
        } else {
            editedRequisition.requisitionDetailsList[index] = data;

            let sum = 0;
            editedRequisition.requisitionDetailsList.forEach(value => sum += (value.productPrice * value.quantity));

            editedRequisition.totalPrice = Math.round(sum);

            const _requisition = {...editedRequisition, totalPrice: editedRequisition.totalPrice};

            setEditedRequisition(_requisition);
        }
    }

    const updateRequisitionRecord = (data, index) => {

        editedRequisition.requisitionDetailsList[index] = data;

        let sum = 0;
        editedRequisition.requisitionDetailsList.forEach(value => sum += (value.productPrice * value.quantity));

        editedRequisition.totalPrice = Math.round(sum);

        const _requisition = {
            ...editedRequisition,
            requisitionDetailsList: [...editedRequisition.requisitionDetailsList],
            totalPrice: editedRequisition.totalPrice
        };

        setEditedRequisition(_requisition);

    }

    const deleteRequisitionRecord = (index) => {

        editedRequisition.requisitionDetailsList.splice(index, 1);

        let sum = 0;
        editedRequisition.requisitionDetailsList.forEach(value => sum += (value.productPrice * value.quantity));

        editedRequisition.totalPrice = Math.round(sum);

        if (!editedRequisition.requisitionDetailsList) {
            setEditedRequisition({});
        }

        const _editedRequisition = {...editedRequisition}

        setEditedRequisition(_editedRequisition);
    }

    const removeVendorByKey = (key, value, operation) => {

        if (operation) {
            if (key === "vendor") {
                editedRequisition[key] = null;
            } else {
                editedRequisition[key] = value;
            }
            setEditedRequisition({...editedRequisition});
        } else {
            if (key === "vendor") {
                requisition[key] = null;
            } else {
                requisition[key] = value;
            }
            localStorage.setItem(localStoredRequisition, JSON.stringify(requisition));
            setRequisition({...requisition});
        }
    }

    const addToRequisitionCart = (data) => {
        data.requisitionDetailsList.map(requisition => {
            requisition.showQuantity = requisition.quantity;
           return requisition;
        });
        localStorage.setItem(generateLocalStorageKey("requisition"), JSON.stringify(data));
        setRequisition({...data});
    }

    return (
        <POSRequisitionContext.Provider
            value={{
                loading,
                requisition,
                totalElements,
                requisitionList,
                editedRequisition,
                requisitionReport,
                getAllRequisition,
                addRequisition,
                updateRequisition,
                updateRequisitionByKey,
                deleteRequisition,
                removeVendorByKey,
                createRequisition,
                getRequisition,
                addRequisitionRecord,
                updateRequisitionRecord,
                deleteRequisitionRecord,
                editRequisitionRecord,
                cancelRequisition,
                addToRequisitionCart,
                errorMsg,
                refreshRequisition
            }}
        >
            {children}
        </POSRequisitionContext.Provider>
    )
}

export default POSRequisitionsContextProvider;
