import React, {createContext, useState} from 'react';
import UserService from "../../services/UserService";
import {Toast} from "../../components/common/Toast";
import {getErrorMessage} from "../../helpers/Utils";

export const UserContext = createContext("UserContext");

const UserContextProvider = ({children}) => {

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [anotherUser, setAnotherUser] = useState(null);
    const [profileLoading, setProfileLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);

    const getAllUsers = async (params) => {

        try {
            setLoading(true);
            const res = await UserService.getAllUsers(params);
            setUsers(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            setLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }

    }


    const addUser = async data => {

        try {
            const res = await UserService.addUser(data)
            setAnotherUser(res.data);
            Toast("success", "Success", `User Id - ${res.data.id} save successfully.`);
            return res.data;
        } catch (err) {
            const message = getErrorMessage(err);
            Toast("error", "Error", message);
            return null;
        }

    }

    const getUserById = async id => {

        try {
            setLoading(true);
            const res = await UserService.getUserById(id);
            setAnotherUser(res.data);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }

    }

    const getUserProfile = async () => {
        try {
            setProfileLoading(true);
            const res = await UserService.getProfile();
            setUser(res.data);
            setProfileLoading(false);
            return 200;
        } catch (error) {
            setProfileLoading(false);
            return error.response ? error.response.status : 500;
        }
    }

    const updateUser = async (data, id) => {
        try {
            const res = await UserService.updateUser(data, id)
            Toast("success", "Success", `User Id - ${res.data.id} save successfully.`);
            return res.data;
        } catch (error) {
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return null;
        }

    }

    const updateProfile = async data => {
        try {
            await UserService.updateProfile(data);
            Toast("success", "Profile Updated", "Profile updated successfully");
            return true;
        } catch (error) {
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }
    }

    const deleteUser = async id => {

        if (!window.confirm("Are you sure you want to delete this User ?")) return;

        try {
            await UserService.deleteUser(id);
            let updatedUsers = users;
            updatedUsers = users.filter(e => e.id !== id)
            setUsers(updatedUsers);
            return true;
        } catch {
            return false;
        }

    }

    const changeUserStatus = async (email, status) => {

        if (!window.confirm(`Are you sure you want to change this User status to ${status} ?`)) return;

        try {
            await UserService.changeUserStatus(email, status);
            let updatedUsers = users;
            updatedUsers = users.map(e => {
                if (e.email === email) e.status = status;
                return e;
            })
            setUsers(updatedUsers);
            return true;
        } catch {
            return false;
        }

    }

    const userImageUpload = async (data) => {
        try {
            setLoading(true);
            await UserService.addUserImages(data);
            setLoading(false);
            return true;
        } catch (err) {
            const message = getErrorMessage(err);
            Toast("error", "Error", message);
            return false;
        }
    }

    const changeLoggedUserPassword = async (data) => {
        try {
            await UserService.changeLoggedUserPassword(data);
            Toast("success", "Password Changed", "Password changed successfully");
            return true;
        } catch (error) {
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }

    }

    const changeAnotherUserPasswordById = async (data, id) => {
        try {
            await UserService.changeAnotherUserPasswordById(data, id);
            Toast("success", "Password Changed", "Password changed successfully");
            return true;
        } catch (error) {
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }

    }


    return (
        <UserContext.Provider
            value={{
                loading,
                users,
                user,
                anotherUser,
                userImageUpload,
                getAllUsers,
                getUserById,
                profileLoading,
                updateUser,
                deleteUser,
                addUser,
                getUserProfile,
                changeUserStatus,
                totalElements,
                changeLoggedUserPassword,
                changeAnotherUserPasswordById,
                updateProfile
            }}
        >
            {children}
        </UserContext.Provider>
    );
}

export default UserContextProvider;
