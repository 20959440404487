import * as URL from "../helpers/Constants";
import axios from "axios";
import {getShopId} from "../helpers/Utils";

export default class POSTransactionServices {
    static getTransactions(accId, params) {
        return axios.get(
            `${URL.POS_GET_SHOP_TRANSACTIONS}/${accId}/${getShopId()}`,
            {
                ...POSTransactionServices.getAuthHeader(),
                params,
            }
        );
    }

    static getTransactionById(id) {
        return axios.get(
            `${URL.POS_GET_SHOP_TRANSACTIONS}/${id}/${getShopId()}`,
            POSTransactionServices.getAuthHeader()
        );
    }

    static createTransaction(data) {
        return axios.post(
            `${URL.POS_CREATE_SHOP_TRANSACTION}/${getShopId()}`,
            data,
            POSTransactionServices.getAuthHeader()
        );
    }

    static cancelTransaction(id) {
        return axios.post(
            `${URL.POS_CANCEL_SHOP_TRANSACTION}/${id}/${getShopId()}`,
            null,
            POSTransactionServices.getAuthHeader()
        );
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        return {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        };
    }
}
