import * as URL from "../helpers/Constants";
import axios from "axios";

export default class userService {
    static getAllUsers(params) {
        return axios.get(URL.GET_ALL_USERS, {
            ...userService.getAuthHeader(),
            params
        });
    }

    static getUserById(id) {
        return axios.get(`${URL.GET_USER_BY_ID}/${id}`, userService.getAuthHeader());
    }

    static getProfile() {
        return axios.get(URL.GET_USER_PROFILE, userService.getAuthHeader());
    }

    static addUser(data) {
        return axios.post(URL.POST_USER, data, userService.getAuthHeader());
    }

    static updateUser(data, id) {
        return axios.put(`${URL.UPDATE_USER}/${id}`, data, userService.getAuthHeader());
    }

    static updateProfile(data) {
        return axios.put(`${URL.UPDATE_PROFILE}`, data, userService.getAuthHeader());
    }

    static deleteUser(id) {
        return axios.delete(`${URL.DELETE_USER}/${id}`, userService.getAuthHeader());
    }

    static addUserImages(data) {
        return axios.post(URL.USER_IMAGE_UPLOAD, data, userService.getAuthHeader());
    }

    static changeLoggedUserPassword(data) {
        return axios.put(`${URL.CHANGE_LOGGED_USER_PASSWORD}`, data, userService.getAuthHeader());
    }

    static changeAnotherUserPasswordById(data, id) {
        return axios.put(`${URL.CHANGE_ANOTHER_USER_PASSWORD_BY_ID}/${id}`, data, userService.getAuthHeader());
    }


    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static changeUserStatus(email, status_payment) {
    //     return axios.post(`${URL.UPDATE_USER_STATUS}/${email}?status_payment=${status_payment}`)
    // }
}
