import {Locales} from "../Locales";

export const bn_BD = {
    [Locales.bengali]: {

        // aside left
        "aside_left.dashboard": "ড্যাশবোর্ড",

        // nav header
        "nav_header.english": "English",
        "nav_header.bengali": "Bengali",
    }
}
