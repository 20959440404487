import * as URL from "../helpers/Constants";
import axios from "axios";

export default class companyService {
    static getAllCompanies(page, size, id, companyName) {
        return axios.get(URL.GET_ALL_COMPANIES, {
            ...companyService.getAuthHeader(),
            params: {page, size, id, companyName}
        });
    }

    static getCompanyById(id) {
        return axios.get(`${URL.GET_COMPANY_BY_ID}/${id}`, companyService.getAuthHeader());
    }

    static addCompany(data) {
        return axios.post(URL.POST_COMPANY, data, companyService.getAuthHeader());
    }

    static updateCompany(data, id) {
        return axios.put(`${URL.UPDATE_COMPANY}/${id}`, data, companyService.getAuthHeader());
    }

    static deleteCompany(id) {
        return axios.delete(`${URL.DELETE_COMPANY}/${id}`, companyService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static changeCompanyStatus(email, status_payment) {
    //     return axios.post(`${URL.UPDATE_COMPANY_STATUS}/${email}?status_payment=${status_payment}`)
    // }
}
